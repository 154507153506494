import { useDebouncedValue, useDisclosure } from '@mantine/hooks';
import { intersectionBy, isEmpty } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntersectionObserver } from 'usehooks-ts';
import { MemberIdTypeEnum } from '../../../openapi/yada';
import { AgentResponse } from '../../../openapi/yenta';
import { useInfiniteSearchAgents } from '../../../query/agent/useAgent';
import { useFetchMultipleOfficeVisibleGroupsByIds } from '../../../query/office/useOffice';
import { useCreateConversation } from '../../../query/roar/useRoar';
import { RootState } from '../../../types';
import { getConversationLink } from '../../../utils/ConversationUtils';
import { isBrokerTeam } from '../../../utils/OfficeUtils';
import DefaultLoader from '../../DefaultLoader';
import GeminiAvatar from '../../Gemini/GeminiAvatar';
import ResourceContainer from '../../ResourceContainer';
import ZenSearchBar from '../../Zen/ZenSearchBar';
import ConversationSelectOfficeModal from './ConversationSelectOfficeModal';

type CreateConversationSidebarBrokerViewProps = {
  onClose(): void;
  onCreate(id: string): void;
};

const CreateConversationSidebarBrokerView: React.FC<CreateConversationSidebarBrokerViewProps> = ({
  onClose,
  onCreate,
}) => {
  const history = useHistory();

  const [opened, modalActions] = useDisclosure(false);
  const [agent, setAgent] = useState<AgentResponse>();
  const officeIdRef = useRef<string>('');

  const [search, setSearch] = useState('');

  const userDetails = useSelector((state: RootState) => state.auth.userDetail);

  const offices = useMemo(() => {
    const common = intersectionBy(
      userDetails?.offices ?? [],
      agent?.offices ?? [],
      (el) => el.id,
    );
    if (common?.length) {
      return common;
    }
    return userDetails?.offices ?? [];
  }, [agent?.offices, userDetails?.offices]);

  const officeIds = useMemo(() => offices?.map((office) => office?.id!) ?? [], [
    offices,
  ]);

  const {
    isLoading: isLoadingOfficeGroups,
    data: officeGroupsById,
  } = useFetchMultipleOfficeVisibleGroupsByIds(officeIds);

  const [searchDebounced] = useDebouncedValue(search, 500);

  const {
    data,
    isInitialLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteSearchAgents({
    pageNumber: 0,
    searchText: searchDebounced,
    officeId: officeIds,
    sortDirection: 'ASC',
    sortBy: ['FIRST_NAME', 'LAST_NAME'],
  });

  const { isLoading: isCreatingConversation, mutate } = useCreateConversation();

  const createConversation = () => {
    const _groupId = officeGroupsById?.[officeIdRef.current]?.groups?.find(
      isBrokerTeam,
    )?.id!;

    mutate(
      {
        members: [
          { value: agent?.id!, type: MemberIdTypeEnum.User },
          { value: _groupId, type: MemberIdTypeEnum.Group },
        ],
      },
      {
        onSuccess: (data) => {
          modalActions.close();
          onClose();
          onCreate(data.id);
          history.push(getConversationLink(data.id));
        },
      },
    );
  };

  const loadMore = () => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  };

  const { ref } = useIntersectionObserver({
    rootMargin: '200px',
    threshold: 0.5,
    freezeOnceVisible: false, // Keep observing the sentinel element
    onChange: (isIntersecting) => {
      if (isIntersecting) {
        loadMore();
      }
    },
  });

  const scrollableDiv = useRef<HTMLDivElement>(null);

  return (
    <div>
      <ZenSearchBar
        value={search}
        onChange={setSearch}
        placeholder='Search by name'
      />
      <div
        // id='scrollableDiv'
        ref={scrollableDiv}
        className='mt-5'
      >
        <ResourceContainer
          loading={isInitialLoading || isLoadingOfficeGroups}
          isEmpty={isEmpty(data)}
          resourceName='agent'
        >
          <div className='-mx-4 space-y-5 px-4 overflow-auto scrollbar h-[calc(100vh-145px)]'>
            {data.map((agent) => {
              return (
                <button
                  key={agent?.id}
                  className='w-full flex items-center space-x-3'
                  onClick={() => {
                    setAgent(agent);
                    modalActions.open();
                  }}
                >
                  <GeminiAvatar
                    name={agent?.fullName!}
                    imageUrl={agent?.avatar!}
                  />
                  <p className='flex-1 text-start line-clamp-1'>
                    {agent?.fullName}
                  </p>
                </button>
              );
            })}
            <div>
              {/* Sentinel element for triggering more data to load */}
              <div ref={ref} style={{ height: '1px' }} />
              <div>{isFetchingNextPage ? <DefaultLoader /> : null}</div>
            </div>
          </div>
        </ResourceContainer>
        <ConversationSelectOfficeModal
          opened={opened}
          onClose={modalActions.close}
          offices={offices}
          isLoading={isCreatingConversation || isLoadingOfficeGroups}
          onChange={(id) => {
            officeIdRef.current = id;
          }}
          onConfirm={createConversation}
        />
      </div>
    </div>
  );
};

export default CreateConversationSidebarBrokerView;
