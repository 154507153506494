import { faClipboardList } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import RealIcon from '../../../assets/img/Real_icon.svg';
import { EscrowResponseEscrowTypeEnum } from '../../../openapi/arrakis';
import { useFetchTransactionById } from '../../../query/transaction/useTransaction';
import { CardItem } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { displayFormattedAmountWithCurrency } from '../../../utils/CurrencyUtils';
import {
  getTransactionOtherParticipants,
  getTransactionPriceLabel,
} from '../../../utils/TransactionHelper';
import PagedPrint from '../../PagedPrint';
import ResourceContainer from '../../ResourceContainer';
import PhoneNumberCell from '../../table/Cells/PhoneNumberCell';
import ZenTransactionDealTypeItem from '../../Zen/Transaction/ZenTransactionDealTypeItem';
import ZenRoute from '../../Zen/ZenRoute';
import AdditionalFeesAndRebatesComponentSummary from './AdditionalFeesAndRebatesComponentSummary';
import ConfirmedTrustDepositsSummary from './ConfirmedTrustDepositsSummary';
import TransactionCommissionPayerSummary from './TransactionCommissionPayerSummary';
import TransactionOtherParticipantsSummary from './TransactionOtherParticipantsSummary';
import TransactionPaymentParticipantsSummary from './TransactionPaymentParticipantsSummary';
import ZenTransactionSummaryCard from './ZenTransactionSummaryCard';

interface Match {
  id: string;
}

const CardRowItem = ({ item, isLast }: { item: CardItem; isLast: boolean }) => {
  return (
    <div
      className={cn(
        'mx-2 px-2 py-3 flex justify-between flex-row items-center space-x-3 border-b',
        isLast && 'border-none',
      )}
    >
      <span className='font-zen-body  leading-5 text-zen-dark-7  text-xs'>
        {item.name}
      </span>
      <span className='text-right font-zen-body leading-4 text-zen-dark-8 text-xs font-medium '>
        {item.value}
      </span>
    </div>
  );
};

const TransactionSummaryPdfRoute: React.FC = () => {
  const params = useParams() as Match;

  const { isLoading, data } = useFetchTransactionById(params.id, {
    logErrorMessage: 'Failed to fetch transaction for transaction summary pdf',
    toastErrorMessage: 'Failed to fetch transaction',
  });

  const leftColumnItems: CardItem[] = [
    {
      name: 'Transaction Owner',
      value: data?.transactionOwner?.name,
    },
    {
      name: 'Email',
      value: data?.transactionOwner?.emailAddress,
    },
    {
      name: 'Office',
      value: data?.office?.address || 'N/A',
    },
    {
      name: getTransactionPriceLabel(data?.transactionType!),
      value: displayFormattedAmountWithCurrency(data?.price),
    },
    {
      name: 'Check',
      value: data?.office?.splitCheck ? 'Split Check' : 'Single Check',
    },
    {
      name: 'MLS Number',
      value: data?.mlsNum || 'N/A',
    },
    {
      name: 'Personal Deal',
      value: data?.paymentParticipants?.some((p) => p.personalDeal)
        ? 'Yes'
        : 'No',
    },
  ];

  if (data?.buySideRepresentation) {
    leftColumnItems.splice(4, 0, {
      name: 'Sale Commission',
      value: displayFormattedAmountWithCurrency(data?.saleCommissionAmount),
    });
  }

  if (data?.listingSideRepresentation) {
    leftColumnItems.splice(4, 0, {
      name: 'Listing Commission',
      value: displayFormattedAmountWithCurrency(data?.listingCommissionAmount),
    });
  }

  const rightColumnItems = [
    {
      name: 'Transaction Code',
      value: data?.code || 'N/A',
    },
    {
      name: 'Representation',
      value: data?.transactionOwner?.represents || 'N/A',
    },
    {
      name: 'Property Type',
      value: <ZenTransactionDealTypeItem dealType={data?.transactionType!} />,
    },
    {
      name: 'Property Address',
      value: data?.address?.oneLine || 'N/A',
    },
    {
      name: 'Acceptance Date',
      value: data?.contractAcceptanceDate
        ? DateTime.fromISO(data?.contractAcceptanceDate).toFormat('LL/dd/yyyy')
        : 'N/A',
    },
    {
      name: 'Estimated Closing Date',
      value: data?.skySlopeEscrowClosingDate
        ? DateTime.fromISO(data?.skySlopeEscrowClosingDate).toFormat(
            'LL/dd/yyyy',
          )
        : 'N/A',
    },
    {
      name: 'Firm Date',
      value: data?.firmDate
        ? DateTime.fromISO(data?.firmDate).toFormat('LL/dd/yyyy')
        : 'N/A',
    },
    {
      name: 'Actual Closing Date',
      value: data?.skySlopeActualClosingDate
        ? DateTime.fromISO(data?.skySlopeActualClosingDate).toFormat(
            'LL/dd/yyyy',
          )
        : 'N/A',
    },
    {
      name: 'Transaction Processed Date',
      value: data?.rezenClosedAt
        ? DateTime.fromMillis(data?.rezenClosedAt).toFormat('LL/dd/yyyy')
        : 'N/A',
    },
  ];

  let trustDeposit = data?.escrows?.find(
    (escrow) => escrow.escrowType === EscrowResponseEscrowTypeEnum.TrustDeposit,
  );

  let commissionDeposit = data?.escrows?.find(
    (escrow) => escrow.escrowType === EscrowResponseEscrowTypeEnum.Commission,
  );

  return (
    <ZenRoute title='Transaction Summary'>
      <ResourceContainer
        loading={isLoading}
        isEmpty={isEmpty(data)}
        resourceName='Transaction Summary'
      >
        <PagedPrint>
          <div className='break-inside-avoid'>
            <header
              className='w-full h-2 fixed'
              style={{
                background:
                  'linear-gradient(62.84deg, #3B82F6 -8.97%, #13B2F8 83.07%, #05C3F9 115.35%)',
              }}
            />
            <div className='mt-4 pt-4 px-10 flex justify-between font-zen-body text-xs'>
              <div className='flex gap-4 items-center'>
                <img src={RealIcon} width={32} height={32} alt='Logo' />
                <div>
                  <p className='font-semibold line-height: 1rem text-zen-dark-9'>
                    Transaction Summary for
                  </p>
                  <p className='font-normal line-height: 1rem text-zen-dark-9'>
                    {data?.address?.oneLine}
                  </p>
                </div>
              </div>
              <div className='text-right'>
                <p className='font-medium line-height: 1rem text-zen-dark-13'>
                  {data?.transactionOwner?.name}
                </p>
                <p className='font-normal line-height: 1rem !text-grey-400'>
                  <PhoneNumberCell phoneNumber={data?.office?.phoneNumber} />
                </p>
                <p className='font-normal line-height: 1rem text-grey-400'>
                  {data?.transactionOwner?.emailAddress}
                </p>
              </div>
            </div>
            <div className='py-4 px-4 space-y-3'>
              <div className='space-y-3 break-inside-avoid'>
                <ZenTransactionSummaryCard
                  titleIconPlacement='left'
                  titleAdjacentComponent={
                    <FontAwesomeIcon
                      icon={faClipboardList}
                      className='mr-2 mb-1 h-3'
                    />
                  }
                  title='Transaction Details'
                >
                  <div className='flex md:flex-col lg:flex-row'>
                    <div className='w-full border-r border-grey-200'>
                      {leftColumnItems.map((item, index) => (
                        <CardRowItem
                          key={item.name}
                          item={item}
                          isLast={leftColumnItems.length - 1 === index}
                        />
                      ))}
                    </div>
                    <div className='w-full'>
                      {rightColumnItems.map((item, index) => (
                        <CardRowItem
                          key={item.name}
                          item={item}
                          isLast={rightColumnItems.length - 1 === index}
                        />
                      ))}
                    </div>
                  </div>
                </ZenTransactionSummaryCard>
                {!!data?.cdPayer && (
                  <TransactionCommissionPayerSummary
                    cdPayer={[data?.cdPayer]}
                  />
                )}
              </div>
            </div>
          </div>
          <div className='py-4 px-4 space-y-3'>
            {!!data?.paymentParticipants && (
              <TransactionPaymentParticipantsSummary
                paymentParticipants={data?.paymentParticipants}
              />
            )}
            {!!data?.otherParticipants && (
              <TransactionOtherParticipantsSummary
                otherParticipants={getTransactionOtherParticipants(data)}
              />
            )}
            {!!data?.attachedFees && (
              <AdditionalFeesAndRebatesComponentSummary
                attachedFees={data?.attachedFees}
                transaction={data}
              />
            )}
            {!!data?.escrows && (
              <ConfirmedTrustDepositsSummary
                trustDeposit={trustDeposit}
                commission={commissionDeposit}
              />
            )}
          </div>
        </PagedPrint>
      </ResourceContainer>
    </ZenRoute>
  );
};

export default TransactionSummaryPdfRoute;
