/* tslint:disable */
/* eslint-disable */
/**
 * Dropbox API
 * Dropbox Main Application
 *
 * The version of the OpenAPI document: 4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AllBatchCreateDropboxResponses
 */
export interface AllBatchCreateDropboxResponses {
    /**
     * 
     * @type {Array<BatchCreateDropboxResponse>}
     * @memberof AllBatchCreateDropboxResponses
     */
    'responses'?: Array<BatchCreateDropboxResponse>;
}
/**
 * 
 * @export
 * @interface BaseMessage
 */
export interface BaseMessage {
    /**
     * 
     * @type {string}
     * @memberof BaseMessage
     */
    'eventId'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseMessage
     */
    'createdAt'?: number;
}
/**
 * 
 * @export
 * @interface BatchCreateDropboxRequest
 */
export interface BatchCreateDropboxRequest {
    /**
     * 
     * @type {Array<CreateDropboxRequest>}
     * @memberof BatchCreateDropboxRequest
     */
    'requests'?: Array<CreateDropboxRequest>;
}
/**
 * 
 * @export
 * @interface BatchCreateDropboxResponse
 */
export interface BatchCreateDropboxResponse {
    /**
     * 
     * @type {string}
     * @memberof BatchCreateDropboxResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof BatchCreateDropboxResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {CreateDropboxRequest}
     * @memberof BatchCreateDropboxResponse
     */
    'createRequest'?: CreateDropboxRequest;
    /**
     * 
     * @type {DropboxResponse}
     * @memberof BatchCreateDropboxResponse
     */
    'createResponse'?: DropboxResponse;
    /**
     * 
     * @type {string}
     * @memberof BatchCreateDropboxResponse
     */
    'errorMsg'?: string;
}
/**
 * 
 * @export
 * @interface CreateDropboxRequest
 */
export interface CreateDropboxRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDropboxRequest
     */
    'name': string;
    /**
     * 
     * @type {RezenObject}
     * @memberof CreateDropboxRequest
     */
    'owner': RezenObject;
    /**
     * 
     * @type {string}
     * @memberof CreateDropboxRequest
     */
    'emailHint': string;
}
/**
 * 
 * @export
 * @interface CreateFolderRequest
 */
export interface CreateFolderRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateFolderRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFolderRequest
     */
    'parentId'?: string;
}
/**
 * 
 * @export
 * @interface DropboxFolderResponse
 */
export interface DropboxFolderResponse {
    /**
     * 
     * @type {string}
     * @memberof DropboxFolderResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DropboxFolderResponse
     */
    'name'?: string;
    /**
     * 
     * @type {Array<Folder>}
     * @memberof DropboxFolderResponse
     */
    'folders'?: Array<Folder>;
    /**
     * 
     * @type {Array<Folder>}
     * @memberof DropboxFolderResponse
     */
    'trashFolders'?: Array<Folder>;
    /**
     * 
     * @type {RezenObject}
     * @memberof DropboxFolderResponse
     */
    'owner'?: RezenObject;
}
/**
 * 
 * @export
 * @interface DropboxResponse
 */
export interface DropboxResponse {
    /**
     * 
     * @type {string}
     * @memberof DropboxResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DropboxResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof DropboxResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DropboxResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {RezenObject}
     * @memberof DropboxResponse
     */
    'owner'?: RezenObject;
    /**
     * 
     * @type {boolean}
     * @memberof DropboxResponse
     */
    'locked'?: boolean;
    /**
     * 
     * @type {Array<FileResponse>}
     * @memberof DropboxResponse
     */
    'files'?: Array<FileResponse>;
    /**
     * 
     * @type {Array<FileResponse>}
     * @memberof DropboxResponse
     */
    'trashFiles'?: Array<FileResponse>;
}
/**
 * 
 * @export
 * @interface FailedMessage
 */
export interface FailedMessage {
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'clientType': FailedMessageClientTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'status': FailedMessageStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'reprocessingAttempts'?: number;
    /**
     * 
     * @type {KafkaMessageLocation}
     * @memberof FailedMessage
     */
    'kafkaMessageLocation': KafkaMessageLocation;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'eventId': string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'key'?: string;
    /**
     * 
     * @type {WrapperBaseMessage}
     * @memberof FailedMessage
     */
    'payload'?: WrapperBaseMessage;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'unprocessed'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FailedMessage
     */
    'processed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'shortenedId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum FailedMessageClientTypeEnum {
    Producer = 'PRODUCER',
    Consumer = 'CONSUMER'
}
/**
    * @export
    * @enum {string}
    */
export enum FailedMessageStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface FailedMessageRetryRequest
 */
export interface FailedMessageRetryRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessageRetryRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface FailedMessageUpdateStatusRequest
 */
export interface FailedMessageUpdateStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessageUpdateStatusRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessageUpdateStatusRequest
     */
    'status'?: FailedMessageUpdateStatusRequestStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FailedMessageUpdateStatusRequestStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface FailedMessagesCriteriaRequest
 */
export interface FailedMessagesCriteriaRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'topic'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'createdAtFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'createdAtTo'?: string;
}
/**
 * 
 * @export
 * @interface FileMetadataDto
 */
export interface FileMetadataDto {
    /**
     * 
     * @type {string}
     * @memberof FileMetadataDto
     */
    'originType'?: FileMetadataDtoOriginTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FileMetadataDto
     */
    'originId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum FileMetadataDtoOriginTypeEnum {
    Ui = 'UI',
    EmailAttachment = 'EMAIL_ATTACHMENT',
    EmailContent = 'EMAIL_CONTENT',
    SignatureS3 = 'SIGNATURE_S3',
    VoiceS3 = 'VOICE_S3',
    CommentMediaS3 = 'COMMENT_MEDIA_S3'
}

/**
 * 
 * @export
 * @interface FileResponse
 */
export interface FileResponse {
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'uploadedBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileResponse
     */
    'length'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FileResponse
     */
    'trash'?: boolean;
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof FileResponse
     */
    'metadata'?: FileMetadataDto;
    /**
     * 
     * @type {Array<FileVersionDto>}
     * @memberof FileResponse
     */
    'fileVersions'?: Array<FileVersionDto>;
    /**
     * 
     * @type {FileVersionDto}
     * @memberof FileResponse
     */
    'currentVersion'?: FileVersionDto;
}
/**
 * 
 * @export
 * @interface FileVersionDto
 */
export interface FileVersionDto {
    /**
     * 
     * @type {string}
     * @memberof FileVersionDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileVersionDto
     */
    'createdAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileVersionDto
     */
    'filename'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileVersionDto
     */
    'length'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileVersionDto
     */
    'uploadedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileVersionDto
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileVersionDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileVersionDto
     */
    'version'?: number;
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof FileVersionDto
     */
    'metadata'?: FileMetadataDto;
}
/**
 * 
 * @export
 * @interface Folder
 */
export interface Folder {
    /**
     * 
     * @type {string}
     * @memberof Folder
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Folder
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Folder
     */
    'parentFolderId'?: string;
    /**
     * 
     * @type {number}
     * @memberof Folder
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof Folder
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof Folder
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Folder
     */
    'trash'?: boolean;
}
/**
 * 
 * @export
 * @interface FolderResponse
 */
export interface FolderResponse {
    /**
     * 
     * @type {string}
     * @memberof FolderResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FolderResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof FolderResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof FolderResponse
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof FolderResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FolderResponse
     */
    'parentFolderId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FolderResponse
     */
    'trash'?: boolean;
    /**
     * 
     * @type {Array<FileResponse>}
     * @memberof FolderResponse
     */
    'files'?: Array<FileResponse>;
    /**
     * 
     * @type {Array<FileResponse>}
     * @memberof FolderResponse
     */
    'trashFiles'?: Array<FileResponse>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseFailedMessage
 */
export interface GenericSearchResponseFailedMessage {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseFailedMessage
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenericSearchResponseFailedMessage
     */
    'sortBy'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenericSearchResponseFailedMessage
     */
    'sortDirection'?: GenericSearchResponseFailedMessageSortDirectionEnum;
    /**
     * 
     * @type {Array<FailedMessage>}
     * @memberof GenericSearchResponseFailedMessage
     */
    'results'?: Array<FailedMessage>;
}

/**
    * @export
    * @enum {string}
    */
export enum GenericSearchResponseFailedMessageSortDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface KafkaMessageLocation
 */
export interface KafkaMessageLocation {
    /**
     * 
     * @type {string}
     * @memberof KafkaMessageLocation
     */
    'topic': string;
    /**
     * 
     * @type {number}
     * @memberof KafkaMessageLocation
     */
    'partition'?: number;
    /**
     * 
     * @type {number}
     * @memberof KafkaMessageLocation
     */
    'offset'?: number;
}
/**
 * 
 * @export
 * @interface MoveFileRequest
 */
export interface MoveFileRequest {
    /**
     * 
     * @type {string}
     * @memberof MoveFileRequest
     */
    'targetFolderId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MoveFileRequest
     */
    'fileIds': Array<string>;
}
/**
 * 
 * @export
 * @interface RezenObject
 */
export interface RezenObject {
    /**
     * 
     * @type {string}
     * @memberof RezenObject
     */
    'type': RezenObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RezenObject
     */
    'id': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RezenObject
     */
    'metadata'?: { [key: string]: string; };
}

/**
    * @export
    * @enum {string}
    */
export enum RezenObjectTypeEnum {
    Transaction = 'TRANSACTION',
    Referral = 'REFERRAL',
    InternalReferral = 'INTERNAL_REFERRAL',
    Listing = 'LISTING',
    ChecklistItem = 'CHECKLIST_ITEM',
    Dropbox = 'DROPBOX',
    Real = 'REAL',
    Office = 'OFFICE',
    Loan = 'LOAN',
    Agent = 'AGENT',
    Borrower = 'BORROWER',
    MortgageLead = 'MORTGAGE_LEAD',
    Application = 'APPLICATION',
    Checklist = 'CHECKLIST',
    Email = 'EMAIL',
    Comment = 'COMMENT',
    Team = 'TEAM',
    OutgoingPayment = 'OUTGOING_PAYMENT',
    Wallet = 'WALLET',
    Voice = 'VOICE',
    Mls = 'MLS',
    Board = 'BOARD',
    User = 'USER',
    Conversation = 'CONVERSATION',
    ReferralCentral = 'REFERRAL_CENTRAL',
    SignatureDocument = 'SIGNATURE_DOCUMENT',
    SignaturePacket = 'SIGNATURE_PACKET',
    UserAgreement = 'USER_AGREEMENT'
}

/**
 * 
 * @export
 * @interface UpdateDropboxRequest
 */
export interface UpdateDropboxRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDropboxRequest
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDropboxRequest
     */
    'locked'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateFailedMessageStatusBatch
 */
export interface UpdateFailedMessageStatusBatch {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateFailedMessageStatusBatch
     */
    'ids': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailedMessageStatusBatch
     */
    'status': UpdateFailedMessageStatusBatchStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateFailedMessageStatusBatchStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface UpdateFileRequest
 */
export interface UpdateFileRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateFileRequest
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFileRequest
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface UpdateFolderRequest
 */
export interface UpdateFolderRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateFolderRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFolderRequest
     */
    'parentFolderId'?: string;
}
/**
 * 
 * @export
 * @interface UploadFileFromS3Request
 */
export interface UploadFileFromS3Request {
    /**
     * 
     * @type {string}
     * @memberof UploadFileFromS3Request
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileFromS3Request
     */
    'sourceS3BucketName': string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileFromS3Request
     */
    'sourceS3Path': string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileFromS3Request
     */
    'origin': UploadFileFromS3RequestOriginEnum;
    /**
     * 
     * @type {string}
     * @memberof UploadFileFromS3Request
     */
    'originId': string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileFromS3Request
     */
    'senderEmailAddress'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UploadFileFromS3RequestOriginEnum {
    Ui = 'UI',
    EmailAttachment = 'EMAIL_ATTACHMENT',
    EmailContent = 'EMAIL_CONTENT',
    SignatureS3 = 'SIGNATURE_S3',
    VoiceS3 = 'VOICE_S3',
    CommentMediaS3 = 'COMMENT_MEDIA_S3'
}

/**
 * 
 * @export
 * @interface WrapperBaseMessage
 */
export interface WrapperBaseMessage {
    /**
     * 
     * @type {BaseMessage}
     * @memberof WrapperBaseMessage
     */
    'message'?: BaseMessage;
    /**
     * 
     * @type {string}
     * @memberof WrapperBaseMessage
     */
    '_class'?: string;
}

/**
 * DropboxApi - axios parameter creator
 * @export
 */
export const DropboxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive or unarchive a dropBox
         * @param {string} dropboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveDropbox: async (dropboxId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dropboxId' is not null or undefined
            assertParamExists('archiveDropbox', 'dropboxId', dropboxId)
            const localVarPath = `/api/v1/dropboxes/{dropboxId}/archive`
                .replace(`{${"dropboxId"}}`, encodeURIComponent(String(dropboxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create many dropboxes
         * @param {BatchCreateDropboxRequest} batchCreateDropboxRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchCreateDropbox: async (batchCreateDropboxRequest: BatchCreateDropboxRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchCreateDropboxRequest' is not null or undefined
            assertParamExists('batchCreateDropbox', 'batchCreateDropboxRequest', batchCreateDropboxRequest)
            const localVarPath = `/api/v1/dropboxes/batch-create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchCreateDropboxRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a dropbox
         * @param {CreateDropboxRequest} createDropboxRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDropbox: async (createDropboxRequest: CreateDropboxRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDropboxRequest' is not null or undefined
            assertParamExists('createDropbox', 'createDropboxRequest', createDropboxRequest)
            const localVarPath = `/api/v1/dropboxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDropboxRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a dropbox
         * @param {string} dropboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDropboxById: async (dropboxId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dropboxId' is not null or undefined
            assertParamExists('getDropboxById', 'dropboxId', dropboxId)
            const localVarPath = `/api/v1/dropboxes/{dropboxId}`
                .replace(`{${"dropboxId"}}`, encodeURIComponent(String(dropboxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get dropbox folders
         * @param {string} dropboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDropboxFolders: async (dropboxId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dropboxId' is not null or undefined
            assertParamExists('getDropboxFolders', 'dropboxId', dropboxId)
            const localVarPath = `/api/v1/dropboxes/{dropboxId}/folders`
                .replace(`{${"dropboxId"}}`, encodeURIComponent(String(dropboxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all dropboxes, or specific dropbox(es) given a set of criteria
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE' | 'MLS' | 'BOARD' | 'USER' | 'CONVERSATION' | 'REFERRAL_CENTRAL' | 'SIGNATURE_DOCUMENT' | 'SIGNATURE_PACKET' | 'USER_AGREEMENT'} [ownerType] 
         * @param {string} [ownerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDropboxes: async (name?: string, email?: string, ownerType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE' | 'MLS' | 'BOARD' | 'USER' | 'CONVERSATION' | 'REFERRAL_CENTRAL' | 'SIGNATURE_DOCUMENT' | 'SIGNATURE_PACKET' | 'USER_AGREEMENT', ownerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dropboxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (ownerType !== undefined) {
                localVarQueryParameter['ownerType'] = ownerType;
            }

            if (ownerId !== undefined) {
                localVarQueryParameter['ownerId'] = ownerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a dropbox
         * @param {string} dropboxId 
         * @param {UpdateDropboxRequest} updateDropboxRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDropbox: async (dropboxId: string, updateDropboxRequest: UpdateDropboxRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dropboxId' is not null or undefined
            assertParamExists('updateDropbox', 'dropboxId', dropboxId)
            // verify required parameter 'updateDropboxRequest' is not null or undefined
            assertParamExists('updateDropbox', 'updateDropboxRequest', updateDropboxRequest)
            const localVarPath = `/api/v1/dropboxes/{dropboxId}`
                .replace(`{${"dropboxId"}}`, encodeURIComponent(String(dropboxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDropboxRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add the file to a dropbox
         * @param {string} dropboxId 
         * @param {File} file 
         * @param {string} [uploadedBy] 
         * @param {string} [filename] 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (dropboxId: string, file: File, uploadedBy?: string, filename?: string, description?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dropboxId' is not null or undefined
            assertParamExists('uploadFile', 'dropboxId', dropboxId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadFile', 'file', file)
            const localVarPath = `/api/v1/dropboxes/{dropboxId}/files`
                .replace(`{${"dropboxId"}}`, encodeURIComponent(String(dropboxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (uploadedBy !== undefined) { 
                localVarFormParams.append('uploadedBy', uploadedBy as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (filename !== undefined) { 
                localVarFormParams.append('filename', filename as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add file from s3 to a dropbox
         * @param {string} dropboxId 
         * @param {UploadFileFromS3Request} uploadFileFromS3Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileFromS3: async (dropboxId: string, uploadFileFromS3Request: UploadFileFromS3Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dropboxId' is not null or undefined
            assertParamExists('uploadFileFromS3', 'dropboxId', dropboxId)
            // verify required parameter 'uploadFileFromS3Request' is not null or undefined
            assertParamExists('uploadFileFromS3', 'uploadFileFromS3Request', uploadFileFromS3Request)
            const localVarPath = `/api/v1/dropboxes/{dropboxId}/files/from-s3`
                .replace(`{${"dropboxId"}}`, encodeURIComponent(String(dropboxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadFileFromS3Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DropboxApi - functional programming interface
 * @export
 */
export const DropboxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DropboxApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive or unarchive a dropBox
         * @param {string} dropboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveDropbox(dropboxId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveDropbox(dropboxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create many dropboxes
         * @param {BatchCreateDropboxRequest} batchCreateDropboxRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchCreateDropbox(batchCreateDropboxRequest: BatchCreateDropboxRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllBatchCreateDropboxResponses>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchCreateDropbox(batchCreateDropboxRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a dropbox
         * @param {CreateDropboxRequest} createDropboxRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDropbox(createDropboxRequest: CreateDropboxRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DropboxResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDropbox(createDropboxRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a dropbox
         * @param {string} dropboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDropboxById(dropboxId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DropboxResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDropboxById(dropboxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get dropbox folders
         * @param {string} dropboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDropboxFolders(dropboxId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DropboxFolderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDropboxFolders(dropboxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all dropboxes, or specific dropbox(es) given a set of criteria
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE' | 'MLS' | 'BOARD' | 'USER' | 'CONVERSATION' | 'REFERRAL_CENTRAL' | 'SIGNATURE_DOCUMENT' | 'SIGNATURE_PACKET' | 'USER_AGREEMENT'} [ownerType] 
         * @param {string} [ownerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDropboxes(name?: string, email?: string, ownerType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE' | 'MLS' | 'BOARD' | 'USER' | 'CONVERSATION' | 'REFERRAL_CENTRAL' | 'SIGNATURE_DOCUMENT' | 'SIGNATURE_PACKET' | 'USER_AGREEMENT', ownerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DropboxResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDropboxes(name, email, ownerType, ownerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a dropbox
         * @param {string} dropboxId 
         * @param {UpdateDropboxRequest} updateDropboxRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDropbox(dropboxId: string, updateDropboxRequest: UpdateDropboxRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDropbox(dropboxId, updateDropboxRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add the file to a dropbox
         * @param {string} dropboxId 
         * @param {File} file 
         * @param {string} [uploadedBy] 
         * @param {string} [filename] 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(dropboxId: string, file: File, uploadedBy?: string, filename?: string, description?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(dropboxId, file, uploadedBy, filename, description, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add file from s3 to a dropbox
         * @param {string} dropboxId 
         * @param {UploadFileFromS3Request} uploadFileFromS3Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFileFromS3(dropboxId: string, uploadFileFromS3Request: UploadFileFromS3Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFileFromS3(dropboxId, uploadFileFromS3Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DropboxApi - factory interface
 * @export
 */
export const DropboxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DropboxApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive or unarchive a dropBox
         * @param {string} dropboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveDropbox(dropboxId: string, options?: any): AxiosPromise<void> {
            return localVarFp.archiveDropbox(dropboxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create many dropboxes
         * @param {BatchCreateDropboxRequest} batchCreateDropboxRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchCreateDropbox(batchCreateDropboxRequest: BatchCreateDropboxRequest, options?: any): AxiosPromise<AllBatchCreateDropboxResponses> {
            return localVarFp.batchCreateDropbox(batchCreateDropboxRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a dropbox
         * @param {CreateDropboxRequest} createDropboxRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDropbox(createDropboxRequest: CreateDropboxRequest, options?: any): AxiosPromise<DropboxResponse> {
            return localVarFp.createDropbox(createDropboxRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a dropbox
         * @param {string} dropboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDropboxById(dropboxId: string, options?: any): AxiosPromise<DropboxResponse> {
            return localVarFp.getDropboxById(dropboxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get dropbox folders
         * @param {string} dropboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDropboxFolders(dropboxId: string, options?: any): AxiosPromise<DropboxFolderResponse> {
            return localVarFp.getDropboxFolders(dropboxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all dropboxes, or specific dropbox(es) given a set of criteria
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE' | 'MLS' | 'BOARD' | 'USER' | 'CONVERSATION' | 'REFERRAL_CENTRAL' | 'SIGNATURE_DOCUMENT' | 'SIGNATURE_PACKET' | 'USER_AGREEMENT'} [ownerType] 
         * @param {string} [ownerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDropboxes(name?: string, email?: string, ownerType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE' | 'MLS' | 'BOARD' | 'USER' | 'CONVERSATION' | 'REFERRAL_CENTRAL' | 'SIGNATURE_DOCUMENT' | 'SIGNATURE_PACKET' | 'USER_AGREEMENT', ownerId?: string, options?: any): AxiosPromise<Array<DropboxResponse>> {
            return localVarFp.getDropboxes(name, email, ownerType, ownerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a dropbox
         * @param {string} dropboxId 
         * @param {UpdateDropboxRequest} updateDropboxRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDropbox(dropboxId: string, updateDropboxRequest: UpdateDropboxRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateDropbox(dropboxId, updateDropboxRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add the file to a dropbox
         * @param {string} dropboxId 
         * @param {File} file 
         * @param {string} [uploadedBy] 
         * @param {string} [filename] 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(dropboxId: string, file: File, uploadedBy?: string, filename?: string, description?: string, options?: any): AxiosPromise<FileResponse> {
            return localVarFp.uploadFile(dropboxId, file, uploadedBy, filename, description, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add file from s3 to a dropbox
         * @param {string} dropboxId 
         * @param {UploadFileFromS3Request} uploadFileFromS3Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileFromS3(dropboxId: string, uploadFileFromS3Request: UploadFileFromS3Request, options?: any): AxiosPromise<FileResponse> {
            return localVarFp.uploadFileFromS3(dropboxId, uploadFileFromS3Request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DropboxApi - object-oriented interface
 * @export
 * @class DropboxApi
 * @extends {BaseAPI}
 */
export class DropboxApi extends BaseAPI {
    /**
     * 
     * @summary Archive or unarchive a dropBox
     * @param {string} dropboxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DropboxApi
     */
    public archiveDropbox(dropboxId: string, options?: AxiosRequestConfig) {
        return DropboxApiFp(this.configuration).archiveDropbox(dropboxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create many dropboxes
     * @param {BatchCreateDropboxRequest} batchCreateDropboxRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DropboxApi
     */
    public batchCreateDropbox(batchCreateDropboxRequest: BatchCreateDropboxRequest, options?: AxiosRequestConfig) {
        return DropboxApiFp(this.configuration).batchCreateDropbox(batchCreateDropboxRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a dropbox
     * @param {CreateDropboxRequest} createDropboxRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DropboxApi
     */
    public createDropbox(createDropboxRequest: CreateDropboxRequest, options?: AxiosRequestConfig) {
        return DropboxApiFp(this.configuration).createDropbox(createDropboxRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a dropbox
     * @param {string} dropboxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DropboxApi
     */
    public getDropboxById(dropboxId: string, options?: AxiosRequestConfig) {
        return DropboxApiFp(this.configuration).getDropboxById(dropboxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get dropbox folders
     * @param {string} dropboxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DropboxApi
     */
    public getDropboxFolders(dropboxId: string, options?: AxiosRequestConfig) {
        return DropboxApiFp(this.configuration).getDropboxFolders(dropboxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all dropboxes, or specific dropbox(es) given a set of criteria
     * @param {string} [name] 
     * @param {string} [email] 
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE' | 'MLS' | 'BOARD' | 'USER' | 'CONVERSATION' | 'REFERRAL_CENTRAL' | 'SIGNATURE_DOCUMENT' | 'SIGNATURE_PACKET' | 'USER_AGREEMENT'} [ownerType] 
     * @param {string} [ownerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DropboxApi
     */
    public getDropboxes(name?: string, email?: string, ownerType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE' | 'MLS' | 'BOARD' | 'USER' | 'CONVERSATION' | 'REFERRAL_CENTRAL' | 'SIGNATURE_DOCUMENT' | 'SIGNATURE_PACKET' | 'USER_AGREEMENT', ownerId?: string, options?: AxiosRequestConfig) {
        return DropboxApiFp(this.configuration).getDropboxes(name, email, ownerType, ownerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a dropbox
     * @param {string} dropboxId 
     * @param {UpdateDropboxRequest} updateDropboxRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DropboxApi
     */
    public updateDropbox(dropboxId: string, updateDropboxRequest: UpdateDropboxRequest, options?: AxiosRequestConfig) {
        return DropboxApiFp(this.configuration).updateDropbox(dropboxId, updateDropboxRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add the file to a dropbox
     * @param {string} dropboxId 
     * @param {File} file 
     * @param {string} [uploadedBy] 
     * @param {string} [filename] 
     * @param {string} [description] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DropboxApi
     */
    public uploadFile(dropboxId: string, file: File, uploadedBy?: string, filename?: string, description?: string, options?: AxiosRequestConfig) {
        return DropboxApiFp(this.configuration).uploadFile(dropboxId, file, uploadedBy, filename, description, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add file from s3 to a dropbox
     * @param {string} dropboxId 
     * @param {UploadFileFromS3Request} uploadFileFromS3Request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DropboxApi
     */
    public uploadFileFromS3(dropboxId: string, uploadFileFromS3Request: UploadFileFromS3Request, options?: AxiosRequestConfig) {
        return DropboxApiFp(this.configuration).uploadFileFromS3(dropboxId, uploadFileFromS3Request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a new version for the specified file
         * @param {string} fileId 
         * @param {File} file 
         * @param {string} [uploadedBy] 
         * @param {string} [filename] 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFileVersion: async (fileId: string, file: File, uploadedBy?: string, filename?: string, description?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('addFileVersion', 'fileId', fileId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('addFileVersion', 'file', file)
            const localVarPath = `/api/v1/files/{fileId}/versions`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (uploadedBy !== undefined) { 
                localVarFormParams.append('uploadedBy', uploadedBy as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (filename !== undefined) { 
                localVarFormParams.append('filename', filename as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a file permanently
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('deleteFile', 'fileId', fileId)
            const localVarPath = `/api/v1/files/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the URL to the most recent version of the specified file
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileUrl: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getFileUrl', 'fileId', fileId)
            const localVarPath = `/api/v1/files/{fileId}/url`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the URL to a specific file version
         * @param {string} fileId 
         * @param {string} fileVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileVersionUrl: async (fileId: string, fileVersionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getFileVersionUrl', 'fileId', fileId)
            // verify required parameter 'fileVersionId' is not null or undefined
            assertParamExists('getFileVersionUrl', 'fileVersionId', fileVersionId)
            const localVarPath = `/api/v1/files/{fileId}/versions/{fileVersionId}/url`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)))
                .replace(`{${"fileVersionId"}}`, encodeURIComponent(String(fileVersionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Move a file to the associated dropbox\'s trash folder
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveFileToTrash: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('moveFileToTrash', 'fileId', fileId)
            const localVarPath = `/api/v1/files/{fileId}/move-to-trash`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restore a file from the associated dropbox\'s trash folder
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreFileFromTrash: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('restoreFileFromTrash', 'fileId', fileId)
            const localVarPath = `/api/v1/files/{fileId}/restore-from-trash`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a given file
         * @param {string} fileId 
         * @param {UpdateFileRequest} updateFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFile: async (fileId: string, updateFileRequest: UpdateFileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('updateFile', 'fileId', fileId)
            // verify required parameter 'updateFileRequest' is not null or undefined
            assertParamExists('updateFile', 'updateFileRequest', updateFileRequest)
            const localVarPath = `/api/v1/files/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a new version for the specified file
         * @param {string} fileId 
         * @param {File} file 
         * @param {string} [uploadedBy] 
         * @param {string} [filename] 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFileVersion(fileId: string, file: File, uploadedBy?: string, filename?: string, description?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFileVersion(fileId, file, uploadedBy, filename, description, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a file permanently
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFile(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFile(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the URL to the most recent version of the specified file
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileUrl(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileUrl(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the URL to a specific file version
         * @param {string} fileId 
         * @param {string} fileVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileVersionUrl(fileId: string, fileVersionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileVersionUrl(fileId, fileVersionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Move a file to the associated dropbox\'s trash folder
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveFileToTrash(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveFileToTrash(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Restore a file from the associated dropbox\'s trash folder
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreFileFromTrash(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreFileFromTrash(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a given file
         * @param {string} fileId 
         * @param {UpdateFileRequest} updateFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFile(fileId: string, updateFileRequest: UpdateFileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFile(fileId, updateFileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a new version for the specified file
         * @param {string} fileId 
         * @param {File} file 
         * @param {string} [uploadedBy] 
         * @param {string} [filename] 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFileVersion(fileId: string, file: File, uploadedBy?: string, filename?: string, description?: string, options?: any): AxiosPromise<FileResponse> {
            return localVarFp.addFileVersion(fileId, file, uploadedBy, filename, description, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a file permanently
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(fileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFile(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the URL to the most recent version of the specified file
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileUrl(fileId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getFileUrl(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the URL to a specific file version
         * @param {string} fileId 
         * @param {string} fileVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileVersionUrl(fileId: string, fileVersionId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getFileVersionUrl(fileId, fileVersionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Move a file to the associated dropbox\'s trash folder
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveFileToTrash(fileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.moveFileToTrash(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restore a file from the associated dropbox\'s trash folder
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreFileFromTrash(fileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.restoreFileFromTrash(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a given file
         * @param {string} fileId 
         * @param {UpdateFileRequest} updateFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFile(fileId: string, updateFileRequest: UpdateFileRequest, options?: any): AxiosPromise<FileResponse> {
            return localVarFp.updateFile(fileId, updateFileRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @summary Add a new version for the specified file
     * @param {string} fileId 
     * @param {File} file 
     * @param {string} [uploadedBy] 
     * @param {string} [filename] 
     * @param {string} [description] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public addFileVersion(fileId: string, file: File, uploadedBy?: string, filename?: string, description?: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).addFileVersion(fileId, file, uploadedBy, filename, description, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a file permanently
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public deleteFile(fileId: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).deleteFile(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the URL to the most recent version of the specified file
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getFileUrl(fileId: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).getFileUrl(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the URL to a specific file version
     * @param {string} fileId 
     * @param {string} fileVersionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getFileVersionUrl(fileId: string, fileVersionId: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).getFileVersionUrl(fileId, fileVersionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Move a file to the associated dropbox\'s trash folder
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public moveFileToTrash(fileId: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).moveFileToTrash(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restore a file from the associated dropbox\'s trash folder
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public restoreFileFromTrash(fileId: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).restoreFileFromTrash(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a given file
     * @param {string} fileId 
     * @param {UpdateFileRequest} updateFileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public updateFile(fileId: string, updateFileRequest: UpdateFileRequest, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).updateFile(fileId, updateFileRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FolderApi - axios parameter creator
 * @export
 */
export const FolderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a folder
         * @param {string} dropboxId 
         * @param {CreateFolderRequest} createFolderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFolder: async (dropboxId: string, createFolderRequest: CreateFolderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dropboxId' is not null or undefined
            assertParamExists('createFolder', 'dropboxId', dropboxId)
            // verify required parameter 'createFolderRequest' is not null or undefined
            assertParamExists('createFolder', 'createFolderRequest', createFolderRequest)
            const localVarPath = `/api/v1/dropboxes/{dropboxId}/folders`
                .replace(`{${"dropboxId"}}`, encodeURIComponent(String(dropboxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFolderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a folder and its contents permanently
         * @param {string} dropboxId 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolder: async (dropboxId: string, folderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dropboxId' is not null or undefined
            assertParamExists('deleteFolder', 'dropboxId', dropboxId)
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('deleteFolder', 'folderId', folderId)
            const localVarPath = `/api/v1/dropboxes/{dropboxId}/folders/{folderId}`
                .replace(`{${"dropboxId"}}`, encodeURIComponent(String(dropboxId)))
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get dropbox folders
         * @param {string} dropboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDropboxFolders: async (dropboxId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dropboxId' is not null or undefined
            assertParamExists('getDropboxFolders', 'dropboxId', dropboxId)
            const localVarPath = `/api/v1/dropboxes/{dropboxId}/folders`
                .replace(`{${"dropboxId"}}`, encodeURIComponent(String(dropboxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get folder contents
         * @param {string} dropboxId 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderContents: async (dropboxId: string, folderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dropboxId' is not null or undefined
            assertParamExists('getFolderContents', 'dropboxId', dropboxId)
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('getFolderContents', 'folderId', folderId)
            const localVarPath = `/api/v1/dropboxes/{dropboxId}/folders/{folderId}`
                .replace(`{${"dropboxId"}}`, encodeURIComponent(String(dropboxId)))
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Move files(max 20) to a folder
         * @param {string} dropboxId 
         * @param {MoveFileRequest} moveFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveFileToFolder: async (dropboxId: string, moveFileRequest: MoveFileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dropboxId' is not null or undefined
            assertParamExists('moveFileToFolder', 'dropboxId', dropboxId)
            // verify required parameter 'moveFileRequest' is not null or undefined
            assertParamExists('moveFileToFolder', 'moveFileRequest', moveFileRequest)
            const localVarPath = `/api/v1/dropboxes/{dropboxId}/files/move`
                .replace(`{${"dropboxId"}}`, encodeURIComponent(String(dropboxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveFileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Move the folder and its contents to the trash
         * @param {string} dropboxId 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveFolderToTrash: async (dropboxId: string, folderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dropboxId' is not null or undefined
            assertParamExists('moveFolderToTrash', 'dropboxId', dropboxId)
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('moveFolderToTrash', 'folderId', folderId)
            const localVarPath = `/api/v1/dropboxes/{dropboxId}/folders/{folderId}/move-to-trash`
                .replace(`{${"dropboxId"}}`, encodeURIComponent(String(dropboxId)))
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restore the folder and its contents from the trash
         * @param {string} dropboxId 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreFolderFromTrash: async (dropboxId: string, folderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dropboxId' is not null or undefined
            assertParamExists('restoreFolderFromTrash', 'dropboxId', dropboxId)
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('restoreFolderFromTrash', 'folderId', folderId)
            const localVarPath = `/api/v1/dropboxes/{dropboxId}/folders/{folderId}/restore-from-trash`
                .replace(`{${"dropboxId"}}`, encodeURIComponent(String(dropboxId)))
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a folder
         * @param {string} dropboxId 
         * @param {string} folderId 
         * @param {UpdateFolderRequest} updateFolderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFolder: async (dropboxId: string, folderId: string, updateFolderRequest: UpdateFolderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dropboxId' is not null or undefined
            assertParamExists('updateFolder', 'dropboxId', dropboxId)
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('updateFolder', 'folderId', folderId)
            // verify required parameter 'updateFolderRequest' is not null or undefined
            assertParamExists('updateFolder', 'updateFolderRequest', updateFolderRequest)
            const localVarPath = `/api/v1/dropboxes/{dropboxId}/folders/{folderId}`
                .replace(`{${"dropboxId"}}`, encodeURIComponent(String(dropboxId)))
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFolderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FolderApi - functional programming interface
 * @export
 */
export const FolderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FolderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a folder
         * @param {string} dropboxId 
         * @param {CreateFolderRequest} createFolderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFolder(dropboxId: string, createFolderRequest: CreateFolderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFolder(dropboxId, createFolderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a folder and its contents permanently
         * @param {string} dropboxId 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFolder(dropboxId: string, folderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFolder(dropboxId, folderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get dropbox folders
         * @param {string} dropboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDropboxFolders(dropboxId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DropboxFolderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDropboxFolders(dropboxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get folder contents
         * @param {string} dropboxId 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFolderContents(dropboxId: string, folderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFolderContents(dropboxId, folderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Move files(max 20) to a folder
         * @param {string} dropboxId 
         * @param {MoveFileRequest} moveFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveFileToFolder(dropboxId: string, moveFileRequest: MoveFileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveFileToFolder(dropboxId, moveFileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Move the folder and its contents to the trash
         * @param {string} dropboxId 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveFolderToTrash(dropboxId: string, folderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveFolderToTrash(dropboxId, folderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Restore the folder and its contents from the trash
         * @param {string} dropboxId 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreFolderFromTrash(dropboxId: string, folderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreFolderFromTrash(dropboxId, folderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a folder
         * @param {string} dropboxId 
         * @param {string} folderId 
         * @param {UpdateFolderRequest} updateFolderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFolder(dropboxId: string, folderId: string, updateFolderRequest: UpdateFolderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFolder(dropboxId, folderId, updateFolderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FolderApi - factory interface
 * @export
 */
export const FolderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FolderApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a folder
         * @param {string} dropboxId 
         * @param {CreateFolderRequest} createFolderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFolder(dropboxId: string, createFolderRequest: CreateFolderRequest, options?: any): AxiosPromise<FolderResponse> {
            return localVarFp.createFolder(dropboxId, createFolderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a folder and its contents permanently
         * @param {string} dropboxId 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolder(dropboxId: string, folderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFolder(dropboxId, folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get dropbox folders
         * @param {string} dropboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDropboxFolders(dropboxId: string, options?: any): AxiosPromise<DropboxFolderResponse> {
            return localVarFp.getDropboxFolders(dropboxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get folder contents
         * @param {string} dropboxId 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderContents(dropboxId: string, folderId: string, options?: any): AxiosPromise<FolderResponse> {
            return localVarFp.getFolderContents(dropboxId, folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Move files(max 20) to a folder
         * @param {string} dropboxId 
         * @param {MoveFileRequest} moveFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveFileToFolder(dropboxId: string, moveFileRequest: MoveFileRequest, options?: any): AxiosPromise<void> {
            return localVarFp.moveFileToFolder(dropboxId, moveFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Move the folder and its contents to the trash
         * @param {string} dropboxId 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveFolderToTrash(dropboxId: string, folderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.moveFolderToTrash(dropboxId, folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restore the folder and its contents from the trash
         * @param {string} dropboxId 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreFolderFromTrash(dropboxId: string, folderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.restoreFolderFromTrash(dropboxId, folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a folder
         * @param {string} dropboxId 
         * @param {string} folderId 
         * @param {UpdateFolderRequest} updateFolderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFolder(dropboxId: string, folderId: string, updateFolderRequest: UpdateFolderRequest, options?: any): AxiosPromise<FolderResponse> {
            return localVarFp.updateFolder(dropboxId, folderId, updateFolderRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FolderApi - object-oriented interface
 * @export
 * @class FolderApi
 * @extends {BaseAPI}
 */
export class FolderApi extends BaseAPI {
    /**
     * 
     * @summary Create a folder
     * @param {string} dropboxId 
     * @param {CreateFolderRequest} createFolderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public createFolder(dropboxId: string, createFolderRequest: CreateFolderRequest, options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).createFolder(dropboxId, createFolderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a folder and its contents permanently
     * @param {string} dropboxId 
     * @param {string} folderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public deleteFolder(dropboxId: string, folderId: string, options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).deleteFolder(dropboxId, folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get dropbox folders
     * @param {string} dropboxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public getDropboxFolders(dropboxId: string, options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).getDropboxFolders(dropboxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get folder contents
     * @param {string} dropboxId 
     * @param {string} folderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public getFolderContents(dropboxId: string, folderId: string, options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).getFolderContents(dropboxId, folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Move files(max 20) to a folder
     * @param {string} dropboxId 
     * @param {MoveFileRequest} moveFileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public moveFileToFolder(dropboxId: string, moveFileRequest: MoveFileRequest, options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).moveFileToFolder(dropboxId, moveFileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Move the folder and its contents to the trash
     * @param {string} dropboxId 
     * @param {string} folderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public moveFolderToTrash(dropboxId: string, folderId: string, options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).moveFolderToTrash(dropboxId, folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restore the folder and its contents from the trash
     * @param {string} dropboxId 
     * @param {string} folderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public restoreFolderFromTrash(dropboxId: string, folderId: string, options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).restoreFolderFromTrash(dropboxId, folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a folder
     * @param {string} dropboxId 
     * @param {string} folderId 
     * @param {UpdateFolderRequest} updateFolderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public updateFolder(dropboxId: string, folderId: string, updateFolderRequest: UpdateFolderRequest, options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).updateFolder(dropboxId, folderId, updateFolderRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GlobalDropboxApi - axios parameter creator
 * @export
 */
export const GlobalDropboxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the global dropbox
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalDropbox: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dropboxes/global`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalDropboxApi - functional programming interface
 * @export
 */
export const GlobalDropboxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalDropboxApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the global dropbox
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGlobalDropbox(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DropboxResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGlobalDropbox(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GlobalDropboxApi - factory interface
 * @export
 */
export const GlobalDropboxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalDropboxApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the global dropbox
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalDropbox(options?: any): AxiosPromise<DropboxResponse> {
            return localVarFp.getGlobalDropbox(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GlobalDropboxApi - object-oriented interface
 * @export
 * @class GlobalDropboxApi
 * @extends {BaseAPI}
 */
export class GlobalDropboxApi extends BaseAPI {
    /**
     * 
     * @summary Get the global dropbox
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalDropboxApi
     */
    public getGlobalDropbox(options?: AxiosRequestConfig) {
        return GlobalDropboxApiFp(this.configuration).getGlobalDropbox(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KafkaRetryProducerControllerApi - axios parameter creator
 * @export
 */
export const KafkaRetryProducerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus: async (updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateFailedMessageStatusBatch' is not null or undefined
            assertParamExists('batchUpdateFailedMessageStatus', 'updateFailedMessageStatusBatch', updateFailedMessageStatusBatch)
            const localVarPath = `/api/v1/kafka-retry/producer/status-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFailedMessageStatusBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/kafka-retry/producer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (createdAtFrom !== undefined) {
                localVarQueryParameter['createdAtFrom'] = createdAtFrom;
            }

            if (createdAtTo !== undefined) {
                localVarQueryParameter['createdAtTo'] = createdAtTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage: async (failedMessageRetryRequest: FailedMessageRetryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageRetryRequest' is not null or undefined
            assertParamExists('retryFailedMessage', 'failedMessageRetryRequest', failedMessageRetryRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/retry-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageRetryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages: async (failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessagesCriteriaRequest' is not null or undefined
            assertParamExists('retryFailedMessages', 'failedMessagesCriteriaRequest', failedMessagesCriteriaRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/retry-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessagesCriteriaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus: async (failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageUpdateStatusRequest' is not null or undefined
            assertParamExists('updateFailedMessageStatus', 'failedMessageUpdateStatusRequest', failedMessageUpdateStatusRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageUpdateStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KafkaRetryProducerControllerApi - functional programming interface
 * @export
 */
export const KafkaRetryProducerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KafkaRetryProducerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseFailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessage(failedMessageRetryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessages(failedMessagesCriteriaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFailedMessageStatus(failedMessageUpdateStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KafkaRetryProducerControllerApi - factory interface
 * @export
 */
export const KafkaRetryProducerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KafkaRetryProducerControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: any): AxiosPromise<number> {
            return localVarFp.batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: any): AxiosPromise<GenericSearchResponseFailedMessage> {
            return localVarFp.getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.retryFailedMessage(failedMessageRetryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: any): AxiosPromise<number> {
            return localVarFp.retryFailedMessages(failedMessagesCriteriaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: any): AxiosPromise<FailedMessage> {
            return localVarFp.updateFailedMessageStatus(failedMessageUpdateStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KafkaRetryProducerControllerApi - object-oriented interface
 * @export
 * @class KafkaRetryProducerControllerApi
 * @extends {BaseAPI}
 */
export class KafkaRetryProducerControllerApi extends BaseAPI {
    /**
     * 
     * @summary Update provided failed messages status
     * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of failed messages
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
     * @param {string} [topic] 
     * @param {string} [key] 
     * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
     * @param {number} [createdAtFrom] 
     * @param {number} [createdAtTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry one message by id
     * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).retryFailedMessage(failedMessageRetryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry messages by criteria
     * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).retryFailedMessages(failedMessagesCriteriaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update failed event status
     * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).updateFailedMessageStatus(failedMessageUpdateStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


