import { faLink, faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import config from '../../config';
import { ContextualPrompt } from '../../utils/LeoUtils';
import {
  NeoLeoSearchCallItem,
  NeoLeoSearchCategoryEnum,
  NeoLeoSearchItem,
  NeoLeoSearchResult,
} from './Hooks/useNeoLeoKBarActions';
import { NeoLeoRoarModal } from './Chat/NeoLeoRoarModal';

interface NeoLeoKBarActionsProps {
  isOpen: boolean;
  actions: NeoLeoSearchResult[];
  onActionClick: (prompt: ContextualPrompt) => void;
}

const NeoLeoKBarActions: React.FC<NeoLeoKBarActionsProps> = ({
  isOpen,
  actions,
  onActionClick,
}) => {
  const history = useHistory();
  const [selectedItem, setSelectedItem] = useState<NeoLeoSearchItem>();

  const renderActionIcon = (
    category: NeoLeoSearchCategoryEnum,
    item: NeoLeoSearchItem,
  ) => {
    switch (category) {
      case NeoLeoSearchCategoryEnum.Roar:
      case NeoLeoSearchCategoryEnum.PromptSuggestions:
        return (
          <FontAwesomeIcon
            icon={faSparkles}
            className='text-aqua'
            fontSize={14}
          />
        );

      case NeoLeoSearchCategoryEnum.ExternalLinks:
        return (
          <FontAwesomeIcon icon={faLink} className='text-aqua' fontSize={14} />
        );

      case NeoLeoSearchCategoryEnum.Deposits:
      case NeoLeoSearchCategoryEnum.InternalPages:
        return (
          <p className='font-inter-regular text-sm text-white pt-0.5'>
            {item?.title?.charAt(0)}
          </p>
        );
    }
  };

  const handleOnActionClick = (
    category: NeoLeoSearchCategoryEnum,
    item: NeoLeoSearchItem,
  ) => {
    switch (category) {
      case NeoLeoSearchCategoryEnum.PromptSuggestions:
        onActionClick({
          prompt: item.prompt ?? '',
          title: item.title ?? item.prompt ?? '',
          category: '',
        });
        break;

      case NeoLeoSearchCategoryEnum.ExternalLinks:
        window.open(item.url!, '_blank');
        break;

      case NeoLeoSearchCategoryEnum.Deposits:
      case NeoLeoSearchCategoryEnum.InternalPages:
        if (item.url) {
          const path = item.url.replace(config.reactAppHostUrl, '');
          history.push(path);
        }
        break;
      case NeoLeoSearchCategoryEnum.Roar:
        setSelectedItem(item);
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className='absolute bottom-16 w-full bg-regent-900 border border-white/[.16] rounded-lg max-h-[350px] overflow-y-auto p-4 neo-leo-kbar-box-shadow'>
      {actions.map((action) => (
        <div key={action.category} className='pb-4'>
          <p className='font-inter-medium text-regent-600 mb-2'>
            {action.category_title?.toUpperCase()}
          </p>
          {action.items.map((item) => (
            <div
              key={item.title}
              className='flex items-center justify-between cursor-pointer mb-2'
              onClick={() => {
                handleOnActionClick(action.category, item);
              }}
            >
              <div className='flex items-center gap-x-3'>
                <div className='flex justify-center items-center w-8 h-6 bg-white bg-opacity-10 rounded-sm flex-shrink-0'>
                  {renderActionIcon(action.category, item)}
                </div>
                <div>
                  <p className='font-inter-light text-sm text-white pt-0.5 font-inter'>
                    {item.title}
                  </p>
                </div>
                <div />
              </div>
            </div>
          ))}
        </div>
      ))}
      <NeoLeoRoarModal
        opened={!!selectedItem}
        close={() => setSelectedItem(undefined)}
        data={selectedItem as NeoLeoSearchCallItem}
      />
    </div>
  );
};

export default NeoLeoKBarActions;
