import { faCircleInfo, faPen } from '@fortawesome/pro-light-svg-icons';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { CreateVoiceCallRequest } from '../../openapi/yada';
import { useFetchCurrentUser } from '../../query/agent/useAgent';
import {
  useCreateVoiceCall,
  useOptInSms,
  usePatchVoiceCall,
  useSmsDialNumber,
} from '../../query/roar/useRoar';
import { saveUserDetail } from '../../slices/AuthSlice';
import { AppDispatch, RootState } from '../../types';
import { cn } from '../../utils/classUtils';
import { formatPhoneNumber, PHONE_REGEX } from '../../utils/StringUtils';
import BrokerOnly from '../auth/BrokerOnly';
import { Button } from '../commons/Button';
import { HookFormPhoneInput } from '../commons/hookFormInputs/HookFormPhoneInput';
import CallNumberLoader from './CallNumberLoader';
import CopyCallNumber from './CopyCallNumber';
import OptInModal from './OptinModal';

const formSchema = z.object({
  phoneNumber: z.string().refine((value) => PHONE_REGEX.test(value), {
    message: 'Please enter a valid phone number',
  }),
});

type IFormData = z.infer<typeof formSchema>;

type CallModalFormProps = {
  voiceCallPayload: CreateVoiceCallRequest;
  phoneNumber?: string;
};

const CallModalForm: React.FC<CallModalFormProps> = ({
  voiceCallPayload,
  phoneNumber,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isEditing, setIsEditing] = useState(false);
  const [phoneNo, setPhoneNo] = useState(phoneNumber);
  const [optInModalOpened, optInModalActions] = useDisclosure(false);

  const userDetail = useSelector((state: RootState) => state.auth.userDetail);
  const isBroker = useSelector((state: RootState) => state.auth.isBroker);

  const { control, handleSubmit, setValue } = useForm<IFormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      phoneNumber: phoneNumber,
    },
  });

  const authUserId = userDetail?.id!;

  const { data: originalVoiceCall, isFetching: isLoading } = useCreateVoiceCall(
    voiceCallPayload.targetId,
    voiceCallPayload,
  );

  const {
    mutate: smsDialNumber,
    isLoading: sendingSms,
    isSuccess: isSmsSent,
  } = useSmsDialNumber();

  const { mutate: optIntoSms, isLoading: isOptingIntoSms } = useOptInSms(
    authUserId,
  );

  const {
    mutate: patchVoiceCall,
    isLoading: isUpdatingPhoneNumber,
    data: updatedVoiceCall,
  } = usePatchVoiceCall(voiceCallPayload.targetId);

  const {
    isFetching: isFetchingCurrentUser,
    isRefetching: isRefetchingCurrentUser,
    refetch: refetchCurrentUser,
  } = useFetchCurrentUser({
    enabled: false, // trigger manually
  });

  const voiceCallResponse = updatedVoiceCall || originalVoiceCall;
  const voiceCallId = voiceCallResponse?.id!;
  const isSendingSms =
    isFetchingCurrentUser || isRefetchingCurrentUser || sendingSms;

  const handleTextMeThisNumber = async () => {
    const { data } = await refetchCurrentUser();
    if (data?.optedIntoSms) {
      smsDialNumber(voiceCallId);
    } else {
      optInModalActions.open();
    }
  };

  const handleOptIntoSms = () => {
    optIntoSms(authUserId!, {
      onSuccess: (userResponse) => {
        dispatch(
          saveUserDetail({
            ...userDetail,
            optedIntoSms: userResponse.optedIntoSms,
          }),
        );
        optInModalActions.close();
        smsDialNumber(voiceCallId);
      },
    });
  };

  const onSubmit = (values: IFormData) => {
    if (isUpdatingPhoneNumber) {
      return;
    }
    setIsEditing(false);
    patchVoiceCall(
      {
        id: voiceCallId,
        phoneNumber: values.phoneNumber,
      },
      {
        onSuccess: () => {
          setPhoneNo(values.phoneNumber);
        },
      },
    );
  };

  const hasNoMembers = !voiceCallResponse?.targetMembers?.members?.length;

  return (
    <>
      <div className='flex items-center flex-col font-inter'>
        <div className='w-full font-medium bg-regent-100 rounded-lg pb-3.5 pt-2.5 my-[14.5px] relative'>
          {!isLoading ? (
            <div className='flex flex-col md:flex-row md:items-start'>
              <div className='flex-1 flex flex-col items-center'>
                <div className='pt-2 text-lg text-primary-dark'>
                  {voiceCallResponse?.virtualNumber
                    ? formatPhoneNumber(voiceCallResponse.virtualNumber)
                    : 'N/A'}
                </div>
                <div className='pb-2 pt-1 text-lg flex'>
                  <span className='text-grey-500'>Call Code:</span>{' '}
                  <span className='text-primary-dark block ml-1'>
                    {voiceCallResponse?.callCode ?? 'N/A'}
                  </span>
                </div>
              </div>
              {!!voiceCallResponse?.virtualNumber && (
                <div className='md:absolute md:right-3 md:top-1'>
                  <CopyCallNumber
                    label='Copy'
                    value={voiceCallResponse?.dialNumber}
                  />
                </div>
              )}
            </div>
          ) : (
            <CallNumberLoader />
          )}
        </div>
        <Button
          fullWidth
          variant='outline'
          disabled={
            isSendingSms || isSmsSent || isEmpty(voiceCallResponse) || isLoading
          }
          onClick={handleTextMeThisNumber}
          loading={isSendingSms}
          className='font-medium'
        >
          {isSmsSent ? 'Text message sent' : 'Text Me This Number'}
        </Button>
        <div
          className={cn(
            'w-full relative bg-regent-300 rounded-xl my-[23px] flex justify-center space-x-3 p-3',
            isBroker && 'mb-0',
          )}
        >
          <FontAwesomeIcon
            icon={faCircleInfo}
            className='mt-1 text-primary-dark'
          />
          <div className='flex-1 text-primary-dark text-base font-normal'>
            {!isSmsSent ? (
              <p>
                A text will be sent to your business phone number with the
                details above to make the call.
              </p>
            ) : (
              <p>
                This number was texted to your business phone number <br />
                {formatPhoneNumber(userDetail?.phoneNumber)}
              </p>
            )}
          </div>
        </div>
      </div>
      <BrokerOnly>
        <div className='pt-8 pb-2 font-inter'>
          <div className='flex flex-wrap justify-between w-full border-t border-gray-200 pt-4'>
            <p className='mb-1 w-full text-primary-dark font-normal font-inter'>
              Agent&apos;s Phone Number
            </p>
            {isEditing ? (
              <div className='flex-1'>
                <HookFormPhoneInput<IFormData, 'phoneNumber'>
                  control={control}
                  name='phoneNumber'
                  placeholder='+1 (702) 123-4567'
                  shouldUnregister={false}
                  classNames={{
                    root: 'w-full',
                  }}
                  rightSection={
                    <div className='border-l border-regent-300 flex items-center justify-center'>
                      <FontAwesomeIcon
                        onClick={() => {
                          setValue('phoneNumber', phoneNo!);
                          setIsEditing(false);
                        }}
                        icon={faXmark}
                        title='cancel'
                        className={cn(
                          'py-1 px-3 text-error cursor-pointer',
                          isUpdatingPhoneNumber && 'text-grey-300',
                        )}
                        fontSize={14}
                      />
                      <FontAwesomeIcon
                        icon={faCheck}
                        title='submit'
                        onClick={handleSubmit(onSubmit)}
                        className={cn(
                          'py-1 pl-3 text-green-600 cursor-pointer',
                          isUpdatingPhoneNumber && 'text-grey-300',
                        )}
                        fontSize={14}
                      />
                    </div>
                  }
                />
              </div>
            ) : (
              <>
                <div className=' w-6/12 text-grey-600 font-light'>
                  {formatPhoneNumber(phoneNo) || 'N/A'}
                </div>
                {isUpdatingPhoneNumber ? (
                  <Loader size='sm' color='gray' />
                ) : (
                  <button
                    className='cursor-pointer text-rezen-blue-600 disabled:text-grey-300 disabled:cursor-not-allowed'
                    onClick={() => setIsEditing(true)}
                    disabled={!voiceCallId || hasNoMembers}
                  >
                    <FontAwesomeIcon icon={faPen} fontSize={14} /> Edit
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </BrokerOnly>
      <OptInModal
        onClose={optInModalActions.close}
        onOptIn={handleOptIntoSms}
        disabled={isOptingIntoSms}
        opened={optInModalOpened}
      />
    </>
  );
};

export default CallModalForm;
