import { faMoneyBillTransfer } from '@fortawesome/pro-light-svg-icons';
import {
  faChevronDown,
  faChevronUp,
  faCircleCheck,
  faEye,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, min } from 'lodash';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { InternalAccountDtoTypeEnum } from '../../../openapi/wallet';
import { useFetchInternalAccounts } from '../../../query/wallet/useWallet';
import { formatMoneyValue } from '../../../utils/CurrencyUtils';
import { displayDate } from '../../../utils/DateUtils';
import DefaultLoader from '../../DefaultLoader';
import ZenButton from '../../Zen/ZenButton';
import ZenDefaultEmpty from '../../Zen/ZenDefaultEmpty';
import ZenResourceContainer from '../../Zen/ZenResourceContainer';
import { cn } from '../../../utils/classUtils';
import ModalRepaymentModal from './WalletRepaymentModal';
import WalletRequestFundsModal from './WalletRequestFundsModal';

interface WalletMyCreditDetailsCardProps {
  showViewDetails?: boolean;
  onRefresh?: () => void;
}

const WalletMyCreditDetailsCard: React.FC<WalletMyCreditDetailsCardProps> = ({
  showViewDetails = false,
  onRefresh,
}) => {
  const [showSummary, setShowSummary] = useState<boolean>();
  const [showRequestFund, setShowRequestFund] = useState<boolean>();
  const [showRepayment, setShowRepayment] = useState<boolean>();

  const { data: lendingAccounts, isLoading } = useFetchInternalAccounts();

  const account = useMemo(() => {
    return lendingAccounts?.internalAccounts?.find(
      (acc) => acc.type === InternalAccountDtoTypeEnum.RealLending,
    );
  }, [lendingAccounts?.internalAccounts]);

  const lastStatement = account?.lastStatement;

  const percentUtilized = useMemo(() => {
    if (account) {
      const currentBalance = account.currentBalance?.amount ?? 0;
      const offeredAmount = account.offeredAmount?.amount ?? 1; // using 1 as default to avoid division by zero
      return Math.round((currentBalance / offeredAmount) * 100); // rounding to the nearest whole number
    }
    return 0;
  }, [account]);

  return (
    <ZenResourceContainer
      resourceName='credit account'
      isEmpty={isEmpty(account)}
      loading={isLoading}
      emptyMessage='No credit account found'
      LoaderComponent={
        <div className='mb-7 p-6 rounded-xl shadow-pop-over bg-white'>
          <DefaultLoader />
        </div>
      }
      EmptyComponent={
        <div className='mb-7 p-6 rounded-xl shadow-pop-over bg-white'>
          <ZenDefaultEmpty message='No credit account found' />
        </div>
      }
    >
      <div className='mb-7 flex flex-col gap-6 p-6 rounded-xl shadow-pop-over bg-white'>
        {/* Header */}
        <div className='flex justify-between items-center'>
          <p className='font-inter font-medium text-xl'>My Line of Credit</p>
          {showViewDetails && (
            <Link to={`/wallet/dashboard/credit/${account?.id}`}>
              <div className='flex items-center gap-1 py-2.5'>
                <FontAwesomeIcon
                  icon={faEye}
                  className='text-rezen-blue-600 text-sm'
                />
                <p className='font-inter font-medium text-base text-rezen-blue-600'>
                  View Details
                </p>
              </div>
            </Link>
          )}
        </div>

        {/* Available Credit Section */}
        <div className='flex flex-col gap-4'>
          <div className='flex flex-col gap-1'>
            <p className='font-inter font-normal text-base text-grey-600'>
              Available
            </p>
            <div className='flex flex-col xl:flex-row items-center gap-2'>
              <p className='font-poppins font-semibold text-3xl'>
                {formatMoneyValue(account?.availableCredit, 2)}
              </p>
              <p className='text-regent-900 font-inter font-normal text-lg'>
                out of {formatMoneyValue(account?.offeredAmount, 2)}
              </p>
            </div>
          </div>

          <div className='flex flex-col gap-2 w-full'>
            <div className='w-full bg-gray-200 rounded-full h-2.5'>
              <div
                className={cn(
                  'h-2.5 rounded-full',
                  percentUtilized > 90 ? 'bg-red-600' : 'bg-primary-dark',
                )}
                style={{ width: `${min([percentUtilized, 100])}%` }}
              />
            </div>

            <div className='flex justify-between'>
              <p className='font-inter font-normal text-sm text-grey-600'>
                {percentUtilized}% (
                {formatMoneyValue(account?.currentBalance, 2)}) utilized
              </p>
              <p className='font-inter font-normal text-sm text-regent-600'>
                updated today
              </p>
            </div>
          </div>
        </div>

        {/* Credit Utilization Tips */}
        <div className='flex flex-col p-4 border rounded-xl border-grey-300 w-full'>
          <p className='font-inter font-medium text-base mb-4'>
            Utilize your credit more efficiently
          </p>

          <div className='flex items-center gap-2 mb-3'>
            <FontAwesomeIcon icon={faCircleCheck} />
            <p className='font-inter font-normal text-sm'>
              Manage cash flow seamlessly.
            </p>
          </div>

          <div className='flex items-center gap-2 mb-6'>
            <FontAwesomeIcon icon={faCircleCheck} />
            <p className='font-inter font-normal text-sm'>
              Close deals faster.
            </p>
          </div>

          <div className='flex justify-center'>
            <ZenButton
              label='Borrow from LOC'
              className='w-full bg-primary-dark text-white font-inter font-medium text-base'
              variant='primary-borderless'
              onClick={() => setShowRequestFund(true)}
              LeftIconComponent={
                <FontAwesomeIcon
                  icon={faMoneyBillTransfer}
                  className='text-white mr-1 pt-1'
                />
              }
            />
          </div>
        </div>

        {!isEmpty(lastStatement) && (
          <>
            {/* Divider */}
            <div className='border bg-grey-300 self-stretch' />

            {/* Credit Bill Section */}
            <div className='flex flex-col'>
              <p className='mb-5 font-inter font-medium text-lg'>Credit Bill</p>

              <div className='flex justify-between flex-wrap gap-6'>
                <div className='flex flex-col gap-2'>
                  <p className='font-inter font-normal text-base text-grey-600'>
                    Amount due
                  </p>
                  <p className='font-inter font-medium text-lg'>
                    {formatMoneyValue(account?.currentAmountDue, 2)}
                  </p>
                </div>

                <div className='flex flex-col gap-2'>
                  <p className='font-inter font-normal text-base text-grey-600'>
                    Due Date
                  </p>
                  <p className='font-inter font-medium text-lg'>
                    {lastStatement?.dueDate
                      ? displayDate(lastStatement?.dueDate, 'dd MMM yyyy')
                      : 'N/A'}
                  </p>
                </div>

                <div className='flex flex-col gap-2'>
                  <p className='font-inter font-normal text-base text-grey-600'>
                    Last Statement
                  </p>
                  <p className='font-inter font-medium text-lg'>
                    {lastStatement?.closingDate
                      ? displayDate(lastStatement?.closingDate, 'dd MMM yyyy')
                      : 'N/A'}
                  </p>
                </div>
              </div>
            </div>

            {/* Summary Section */}
            <div className='flex flex-col gap-4 p-4 bg-regent-200 rounded-xl'>
              <div className='flex justify-between items-center'>
                <p className='font-inter font-medium text-base'>Summary</p>
                <div
                  className='flex items-center gap-2 cursor-pointer'
                  onClick={() => setShowSummary(!showSummary)}
                >
                  <p className='font-inter font-medium text-base text-rezen-blue-600'>
                    {showSummary ? 'Hide' : 'Show'}
                  </p>
                  <FontAwesomeIcon
                    icon={showSummary ? faChevronUp : faChevronDown}
                    className='text-rezen-blue-600'
                  />
                </div>
              </div>

              {showSummary && (
                <div className='flex flex-col gap-3'>
                  <div className='flex justify-between pb-2 border-b border-regent-400'>
                    <p className='font-inter text-sm text-grey-600'>
                      Statement End Date
                    </p>
                    <p className='font-inter text-sm text-end text-primary-dark'>
                      {lastStatement?.closingDate
                        ? displayDate(lastStatement?.closingDate, 'dd MMM yyyy')
                        : 'N/A'}
                    </p>
                  </div>

                  <div className='flex justify-between pb-2 border-b border-regent-400'>
                    <p className='font-inter text-sm text-grey-600'>
                      Payment Due Date
                    </p>
                    <p className='font-inter text-sm text-end'>
                      {lastStatement?.dueDate
                        ? displayDate(lastStatement?.dueDate, 'dd MMM yyyy')
                        : 'N/A'}
                    </p>
                  </div>

                  {lastStatement?.minimumPaymentDue && (
                    <div className='flex justify-between pb-2 border-b border-regent-400'>
                      <p className='font-inter text-sm text-grey-600'>
                        Minimum Payment Due
                      </p>
                      <p className='font-inter text-sm text-end'>
                        {formatMoneyValue(lastStatement?.minimumPaymentDue, 2)}
                      </p>
                    </div>
                  )}

                  {lastStatement?.interestCharged && (
                    <div className='flex justify-between pb-2 border-b border-regent-400'>
                      <p className='font-inter text-sm text-grey-600'>
                        Interest Charges
                      </p>
                      <p className='font-inter text-sm text-end'>
                        {formatMoneyValue(lastStatement?.interestCharged, 2)}
                      </p>
                    </div>
                  )}

                  {lastStatement?.feesCharged && (
                    <div className='flex justify-between pb-2 border-b border-regent-400'>
                      <p className='font-inter text-sm text-grey-600'>
                        Late Fees
                      </p>
                      <p className='font-inter text-sm text-end'>
                        {formatMoneyValue(lastStatement?.feesCharged, 2)}
                      </p>
                    </div>
                  )}

                  <div className='flex justify-between pb-2'>
                    <p className='font-inter text-sm text-primary-navy'>
                      Last Statement Balance
                    </p>
                    <p className='font-inter text-base font-semibold text-end'>
                      {formatMoneyValue(lastStatement?.statementBalance, 2)}
                    </p>
                  </div>
                </div>
              )}
            </div>

            {/* Pay Bill */}
            <div className='flex justify-center'>
              <ZenButton
                label='Pay Bill'
                variant='primary-outline'
                className='w-full font-inter font-medium text-base'
                onClick={() => setShowRepayment(true)}
                LeftIconComponent={
                  <FontAwesomeIcon
                    icon={faMoneyBillTransfer}
                    className='text-blue-600'
                  />
                }
              />
            </div>
          </>
        )}

        {showRequestFund && (
          <WalletRequestFundsModal
            isOpen={showRequestFund}
            onClose={() => setShowRequestFund(false)}
            availableCredit={account?.availableCredit}
            onRefresh={onRefresh}
          />
        )}
      </div>

      {showRepayment && (
        <ModalRepaymentModal
          isOpen={showRepayment}
          onClose={() => setShowRepayment(false)}
        />
      )}
    </ZenResourceContainer>
  );
};

export default WalletMyCreditDetailsCard;
