import { flatten, omit, values } from 'lodash';
import { DateTime } from 'luxon';
import { FormDataType } from '../components/onboarding/ApplicationForm';
import {
  AddressRequestStateOrProvinceEnum,
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaRequestStateOrProvinceEnum,
  AdministrativeAreaResponseStateOrProvinceEnum,
  AgentResponseAccountCountryEnum,
  ApplicationResponse,
  ApplicationResponseCountryEnum,
  ApplicationResponseNextStatusEnum,
  ApplicationResponseTeamRoleEnum,
  BoardControllerApi,
  LicenseBoardMLSRequest,
  LicenseBoardMLSResponse,
  MlsControllerApi,
  NationalIdentificationValueTypeEnum,
} from '../openapi/yenta';
import {
  AsyncSelectOption,
  CountryMapStateOptions,
  FAQType,
  FormFieldConfig,
  SearchLiteMlsStateType,
  ISelectOption,
  InputFieldType,
  YesNoType,
  onBoardingStepType,
} from '../types';
import { getIdValidations } from '../utils/AgentHelper';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { CANADIAN_STATES, US_STATES } from '../utils/StateUtils';
import {
  PHONE_REGEX,
  capitalizeEnum,
  formatPhoneNumber,
} from '../utils/StringUtils';
import { AGENT_AGE_VALIDATIONS, FILE_VALIDATIONS } from '../utils/Validations';
import { BoardsStateType } from '../utils/BoardUtils';
import ErrorService from '../services/ErrorService';

export const CREATE_ACCOUNT_INDEX = 0;
export const APPLICATION_FORM_INDEX = 1;
export const ICA_INDEX = 2;
export const APPLICATION_FEE_INDEX = 3;
export const TRANSFER_LICENSE_INDEX = 4;
export const JOIN_THE_BOARD_INDEX = 5;
export const COMPLETED_INDEX = 6;

export enum ImageTypeEnum {
  URL = 'URL',
}

export const onboardingSteps: onBoardingStepType[] = [
  {
    name: 'Create Account',
  },
  {
    name: 'Application Form',
  },
  {
    name: 'Independent Contractor Agreement',
  },
  {
    name: 'Application Fee',
  },
  {
    name: 'Transfer License',
  },
  {
    name: 'Join Boards',
  },
];

export const onboardingStepsWithLOI: onBoardingStepType[] = [
  {
    name: 'Create Account',
  },
  {
    name: 'Application Form',
  },
  {
    name: 'Letter Of Intent',
  },
  {
    name: 'Independent Contractor Agreement',
  },
  {
    name: 'Application Fee',
  },
  {
    name: 'Transfer License',
  },
  {
    name: 'Join Boards',
  },
];

export enum ApplicationFormFieldName {
  PHONE_NUMBER = 'phoneNumber',
  BIRTH_DATE = 'birthDate',
  DOES_BUSINESS_IN = 'doesBusinessInExtended',
  BOARD_NAME = 'board',
  MLS_NAME = 'mls',
  LICENSE_NUMBER = 'number',
  LICENSE_ACTIVE = 'active',
  LICENSE_EXPIRES_AT = 'expirationDate',
  LICENSE_ANY_KNOWN_COMPLAINTS = 'knownComplaints',
  SELECTED_PROVINCES = 'selectedProvinces',
  CURRENT_BROKERAGE = 'currentBrokerage',
  ESTIMATED_SALES = 'estimatedSales',
  INDIVIDUAL_AGENT = 'individualAgent',
  TEAM_NAME = 'teamName',
  IS_PREFERRED_TITLE_VENDOR = 'isPreferredTitleVendor',
  PREFERRED_TITLE_VENDOR = 'preferredTitleVendor',
  TEAM_LEADER = 'teamLeader',
  TEAM_LEADER_NAME = 'teamLeaderName',
  PHOTO_UPLOAD = 'photoUpload',
  HAS_PENDING_TRANSACTIONS_WITH_CURRENT_BROKERAGE = 'hasPendingTransactionsWithCurrentBrokerage',
}

export const countryMapState: CountryMapStateOptions = {
  [AdministrativeAreaRequestCountryEnum.UnitedStates]: values(US_STATES)
    .sort((a, z) => a.localeCompare(z))
    .map<ISelectOption>((state) => ({
      value: state,
      label: capitalizeEnum(state),
    })),
  [AdministrativeAreaRequestCountryEnum.Canada]: values(CANADIAN_STATES)
    .sort((a, z) => a.localeCompare(z))
    .map((state) => ({
      value: state,
      label: capitalizeEnum(state),
    })),
};

export const applicationFormQuestions = (
  name: string,
  countrySelected: AgentResponseAccountCountryEnum,
  provincesSelected: ISelectOption[] = [],
  invitationId?: string,
): Array<FormFieldConfig> => {
  const licensesForProvinces: FormFieldConfig[] = [];

  const isAlbertaStateProvinceSelected =
    !!provincesSelected.find(
      (a) => a.value === AddressRequestStateOrProvinceEnum.Alberta,
    ) && countrySelected === AgentResponseAccountCountryEnum.Canada;

  provincesSelected.forEach((option, index) => {
    licensesForProvinces.push({
      name: `${ApplicationFormFieldName.DOES_BUSINESS_IN}[${index}].${ApplicationFormFieldName.MLS_NAME}`,
      label: `What is the name of the MLS you are a member of or will be joining in ${option.label}?`,
      helperText: `Enter the name of the MLS for ${option.label}`,
      type: InputFieldType.ASYNC_CREATABLE_SELECT,
      rules: {
        required: `Please enter the name of the MLS for ${option.label}`,
      },
      isRequired: true,
      naMessage: 'I do not belong to a MLS.',
      defaultValue: undefined,
      fetchData: async (search: string, page?: number) => {
        const mlsState = !!option?.value
          ? ([option?.value] as SearchLiteMlsStateType)
          : [];
        try {
          const { data } = await new MlsControllerApi(
            getYentaConfiguration(),
          ).searchLiteMls(page, 20, 'ASC', search, mlsState);

          const options: AsyncSelectOption[] = (data?.results || [])?.map(
            (resp) => ({
              value: `${resp?.id}`,
              label: `${resp?.name}`,
            }),
          );

          return options;
        } catch (e) {
          ErrorService.notify('Unable to search mls', e, {
            data: {
              search,
              pagination: {
                pageNumber: page,
                pageSize: 20,
                sortDirection: 'ASC',
              },
              state: mlsState,
            },
          });

          return [];
        }
      },
      resolveFieldDisplayValue(selected: ISelectOption | string) {
        return typeof selected === 'object' ? selected.label : selected;
      },
      convertValueBeforeSend(selected: ISelectOption | string) {
        return typeof selected === 'object' ? selected.label : selected;
      },
    });

    licensesForProvinces.push({
      name: `${ApplicationFormFieldName.DOES_BUSINESS_IN}[${index}].${ApplicationFormFieldName.BOARD_NAME}`,
      label: `What is the name of the local Board of Realtors (Association of Realtors) you are a member of or will be joining in ${option.label}?`,
      helperText: `Enter the name of the Board for ${option.label}`,
      type: InputFieldType.ASYNC_CREATABLE_SELECT,
      rules: {
        required: `Please enter the name of the Board for ${option.label}`,
      },
      isRequired: true,
      naMessage: 'I do not belong to a Board.',
      defaultValue: undefined,
      fetchData: async (search: any, page: any) => {
        const boardState = !!option?.value ? [option?.value] : [];
        try {
          const { data } = await new BoardControllerApi(
            getYentaConfiguration(),
          ).searchBoards(
            ['NAME'],
            'ASC',
            page,
            20,
            search,
            undefined,
            undefined,
            undefined,
            boardState as BoardsStateType,
            ['ACTIVE'],
          );
          const options: AsyncSelectOption[] = (data?.results || []).map(
            (resp) => ({
              value: `${resp?.id}`,
              label: `${resp?.name}`,
            }),
          );

          return options;
        } catch (e) {
          ErrorService.notify('Unable to search board', e, {
            data: {
              search,
              pagination: {
                pageNumber: page,
                pageSize: 20,
                sortDirection: 'ASC',
                sortBy: 'NAME',
              },
              state: boardState,
            },
          });
          return [];
        }
      },
      resolveFieldDisplayValue(selected: ISelectOption | string) {
        return typeof selected === 'object' ? selected.label : selected;
      },
      convertValueBeforeSend(selected: ISelectOption | string) {
        return typeof selected === 'object' ? selected.label : selected;
      },
    });

    licensesForProvinces.push({
      name: `${ApplicationFormFieldName.DOES_BUSINESS_IN}[${index}].licenseRequest.${ApplicationFormFieldName.LICENSE_NUMBER}`,
      label: `What is your real estate license number in ${option.label}?`,
      helperText: 'Please enter the license number below',
      placeholder: 'NY4235567',
      type: InputFieldType.TEXT,
      rules: {
        required: 'Please enter your license number',
      },
      isRequired: true,
    });

    licensesForProvinces.push({
      name: `${ApplicationFormFieldName.DOES_BUSINESS_IN}[${index}].licenseRequest.${ApplicationFormFieldName.LICENSE_ACTIVE}`,
      label: `Is your license active in ${option.label}?`,
      helperText: 'Please choose one',
      placeholder: '123456789',
      type: InputFieldType.RADIO,
      rules: {
        required: 'Please choose one',
      },
      isRequired: true,
      options: [
        {
          label: 'Yes',
          value: YesNoType.YES,
        },
        {
          label: 'No',
          value: YesNoType.NO,
        },
      ],
      resolveFieldDisplayValue(status: YesNoType) {
        return capitalizeEnum(status);
      },
      convertValueBeforeSend(status: YesNoType) {
        return status === YesNoType.YES;
      },
    });

    licensesForProvinces.push({
      name: `${ApplicationFormFieldName.DOES_BUSINESS_IN}[${index}].licenseRequest.${ApplicationFormFieldName.LICENSE_EXPIRES_AT}`,
      label: `When does your license expire in ${option.label}?`,
      helperText: 'Please choose a date',
      placeholder: 'YYYY-MM-DD',
      type: InputFieldType.DATE,
      datePickerConfig: {
        minDate: DateTime.local().minus({ years: 15 }).toJSDate(),
        maxDate: DateTime.local().plus({ years: 15 }).toJSDate(),
      },
      resolveFieldDisplayValue(date: string) {
        return DateTime.fromISO(date).toFormat('LL/dd/yyyy');
      },
      rules: {
        required: 'Please choose a date.',
      },
      isRequired: true,
    });

    licensesForProvinces.push({
      name: `${ApplicationFormFieldName.DOES_BUSINESS_IN}[${index}].licenseRequest.${ApplicationFormFieldName.LICENSE_ANY_KNOWN_COMPLAINTS}`,
      label: `Have you had a registered complaint filed against you in ${option.label}?`,
      helperText: 'Please choose one',
      type: InputFieldType.RADIO,
      rules: {
        required: 'Please choose one',
      },
      isRequired: true,
      options: [
        {
          label: 'Yes',
          value: YesNoType.YES,
        },
        {
          label: 'No',
          value: YesNoType.NO,
        },
      ],
      resolveFieldDisplayValue(status: YesNoType) {
        return capitalizeEnum(status);
      },
      convertValueBeforeSend(status: YesNoType) {
        return status === YesNoType.YES;
      },
    });
  });

  const questions = [
    {
      name: ApplicationFormFieldName.PHONE_NUMBER,
      label: `Hi, ${name} 👋`,
      secondaryLabel:
        'Please fill out the following to complete the application form',
      helperText: 'Mobile Number',
      placeholder: '+1 (702) 123-4567',
      type: InputFieldType.PHONE,
      rules: {
        required: 'Please enter your mobile phone number',
        pattern: {
          value: PHONE_REGEX,
          message: 'Please enter a valid phone number',
        },
      },
      isRequired: true,
      selectedCountry: countrySelected,
      reviewLabel: 'What is your mobile phone number?',
      resolveFieldDisplayValue(phoneNumber: string) {
        return formatPhoneNumber(phoneNumber);
      },
    },
    {
      name: ApplicationFormFieldName.BIRTH_DATE,
      label: 'What is your date of birth?',
      helperText: 'Please select a date',
      placeholder: 'MM-DD-YYYY',
      type: InputFieldType.DATE,
      datePickerConfig: {
        minDate: DateTime.local().minus({ years: 100 }).toJSDate(),
        maxDate: DateTime.local().toJSDate(),
      },
      rules: {
        required: 'Please enter your date of birth',
        ...AGENT_AGE_VALIDATIONS,
      },
      isRequired: true,
      resolveFieldDisplayValue(date: string) {
        return DateTime.fromISO(date).toFormat('LL/dd/yyyy');
      },
    },
    {
      name: ApplicationFormFieldName.SELECTED_PROVINCES,
      label: 'What states or provinces are you licensed in?',
      helperText:
        'Please select all of the states/provinces where you are licensed',
      type: InputFieldType.MULTISELECT,
      rules: {
        required: 'Please select a state / province',
        validate: (value?: AdministrativeAreaRequestStateOrProvinceEnum[]) =>
          !value?.length ? 'Please select a state / province' : undefined,
      },
      isRequired: true,
      placeholder: 'Choose state / province',
      options: countryMapState[countrySelected],
      resolveFieldDisplayValue(states: ISelectOption[]) {
        return states.map((state) => state.label).join(', ');
      },
      showConfirmNext: true,
      confirmationVariant: 'info',
      confirmationTitle:
        'Did you select all the states/provinces in which you are licensed?',
      convertValueBeforeSend(states: ISelectOption[]) {
        return states.map((state) => ({
          country: countrySelected,
          stateOrProvince: state.value,
        }));
      },
      getConfirmationModalOpenStatus() {
        return true;
      },
    },

    ...licensesForProvinces,

    {
      name: ApplicationFormFieldName.PHOTO_UPLOAD,
      label: `Please upload a photo showing your face and the front of your driver's license.`,
      helperText:
        'We must confirm your identity before we can process your application.',
      placeholder: 'Select Photo',
      type: InputFieldType.IMAGE_SELECT,
      rules: {
        required: isAlbertaStateProvinceSelected
          ? 'Please select photo.'
          : undefined,
      },
      isRequired: isAlbertaStateProvinceSelected,
    },
    {
      name: ApplicationFormFieldName.CURRENT_BROKERAGE,
      label: 'What is the name of your current brokerage?',
      helperText: 'Please enter the name',
      type: InputFieldType.TEXT,
      rules: {
        required: "Please enter your current brokerage's name",
      },
      isRequired: true,
      naMessage: 'I do not currently belong to a brokerage.',
      extraFields: [
        {
          label:
            'Do you have any pending transactions with your current or previous brokerage?',
          name:
            ApplicationFormFieldName.HAS_PENDING_TRANSACTIONS_WITH_CURRENT_BROKERAGE,
          helperText: 'Please choose one',
          type: InputFieldType.RADIO,
          options: [
            {
              label: 'Yes',
              value: YesNoType.YES,
            },
            {
              label: 'No',
              value: YesNoType.NO,
            },
          ],
          rules: {
            required: 'Please select one.',
          },
          isRequired: true,
          resolveFieldDisplayValue(status: YesNoType) {
            return capitalizeEnum(status);
          },
          convertValueBeforeSend(status: YesNoType) {
            return status === YesNoType.YES;
          },
        },
      ],
    },
    {
      name: ApplicationFormFieldName.ESTIMATED_SALES,
      label: 'Estimated Sales',
      helperText: 'What was your estimated sales volume in the last 12 months?',
      type: InputFieldType.TEXT,
      rules: {
        required: 'Please enter the Estimated Sales Amount.',
      },
      isRequired: true,
    },
  ];

  if (!invitationId) {
    questions.push({
      name: ApplicationFormFieldName.INDIVIDUAL_AGENT,
      label: 'Are you an individual agent or a team agent?',
      helperText: 'Please choose one',
      type: InputFieldType.RADIO,
      options: [
        {
          label: 'Individual Agent',
          value: YesNoType.YES,
        },
        {
          label: 'Team Agent',
          value: YesNoType.NO,
        },
      ],
      rules: {
        required: 'Please choose one.',
      },
      isRequired: true,
      resolveFieldDisplayValue(status: YesNoType) {
        return status === YesNoType.YES ? 'Individual Agent' : 'Team Agent';
      },
      convertValueBeforeSend(status: YesNoType) {
        return status === YesNoType.YES;
      },
      extraFields: [
        {
          name: ApplicationFormFieldName.TEAM_LEADER,
          label: 'Are you a team leader or team member?',
          helperText: 'Please choose one',
          type: InputFieldType.RADIO,
          options: [
            {
              label: 'Team Leader',
              value: YesNoType.YES,
            },
            {
              label: 'Team Member',
              value: YesNoType.NO,
            },
          ],
          rules: {
            required: 'Please choose one.',
          },
          isRequired: true,
          shouldDisplayField(data: FormDataType) {
            return data?.individualAgent === YesNoType.NO;
          },
          resolveFieldDisplayValue(status: YesNoType) {
            return status === YesNoType.YES ? 'Team Leader' : 'Team Member';
          },
          convertValueBeforeSend(status: YesNoType) {
            return status === YesNoType.YES;
          },
        },
        {
          name: ApplicationFormFieldName.TEAM_LEADER_NAME,
          label: "What is your team leader's name?",
          helperText: 'Please choose one',
          rules: {
            required: "Please enter your team leader's name",
          },
          isRequired: true,
          type: InputFieldType.TEXT,
          shouldDisplayField(data: FormDataType) {
            return data?.teamLeader === YesNoType.NO;
          },
          convertValueBeforeSend(teamLeaderName: string, data: FormDataType) {
            return data?.individualAgent === YesNoType.NO &&
              data?.teamLeader === YesNoType.NO
              ? teamLeaderName
              : undefined;
          },
        },
        {
          name: ApplicationFormFieldName.TEAM_NAME,
          label: "What is your team's name?",
          helperText: 'Please choose one',
          rules: {
            required: 'Please enter your team name',
          },
          isRequired: true,
          type: InputFieldType.TEXT,
          shouldDisplayField(data: FormDataType) {
            return (
              data?.teamLeader === YesNoType.NO ||
              data?.teamLeader === YesNoType.YES
            );
          },
          convertValueBeforeSend(teamName: string, data: FormDataType) {
            return data?.individualAgent === YesNoType.NO
              ? teamName
              : undefined;
          },
        },
      ],
    });
  }

  if (countrySelected !== AgentResponseAccountCountryEnum.Canada) {
    questions.push({
      name: ApplicationFormFieldName.IS_PREFERRED_TITLE_VENDOR,
      label:
        'Do you have a preferred title vendor that you currently work with?',
      helperText: 'Please choose one',
      type: InputFieldType.RADIO,
      options: [
        {
          label: 'Yes',
          value: YesNoType.YES,
        },
        {
          label: 'No',
          value: YesNoType.NO,
        },
      ],
      rules: {
        required: 'Please choose one.',
      },
      isRequired: true,
      resolveFieldDisplayValue(status: YesNoType) {
        return capitalizeEnum(status);
      },
      convertValueBeforeSend(status: YesNoType) {
        return status === YesNoType.YES;
      },
      extraFields: [
        {
          reviewLabel: 'Preferred Title Vendor',
          name: ApplicationFormFieldName.PREFERRED_TITLE_VENDOR,
          placeholder: 'Preferred Title Vendor',
          helperText: 'Please choose one',
          type: InputFieldType.TEXT,
          defaultValue: undefined,
          rules: {
            required: 'Please enter your preferred title vendor',
          },
          isRequired: true,
          shouldDisplayField(data: FormDataType) {
            return data.isPreferredTitleVendor === YesNoType.YES;
          },
          convertValueBeforeSend(
            preferredTitleVendor: string,
            data: FormDataType,
          ) {
            return data.isPreferredTitleVendor === YesNoType.YES
              ? preferredTitleVendor
              : undefined;
          },
        },
      ],
    });
  }

  return questions;
};

export const FAQs: FAQType[] = [
  {
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: 'How do you make holy water?',
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: 'What do you call someone with no body and no nose?',
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: 'Why do you never see elephants hiding in trees?',
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: 'Why did the invisible man turn down the job offer?',
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
];

export const getFlattenedObjectWithDotNotation = (values: any, prefix = '') =>
  omit(
    Object.keys(values).reduce((acc: any, k) => {
      const pre = prefix.length ? prefix + '.' : '';
      if (
        k === ApplicationFormFieldName.TEAM_NAME ||
        k === ApplicationFormFieldName.PREFERRED_TITLE_VENDOR ||
        k ===
          ApplicationFormFieldName.HAS_PENDING_TRANSACTIONS_WITH_CURRENT_BROKERAGE ||
        k === ApplicationFormFieldName.TEAM_LEADER ||
        k === ApplicationFormFieldName.TEAM_LEADER_NAME
      ) {
        return acc;
      } else if (
        typeof values[k] === 'object' &&
        !Array.isArray(values[k]) &&
        ![
          ApplicationFormFieldName.BOARD_NAME,
          ApplicationFormFieldName.MLS_NAME,
          ApplicationFormFieldName.PHOTO_UPLOAD,
        ].includes(k as ApplicationFormFieldName)
      )
        Object.assign(
          acc,
          getFlattenedObjectWithDotNotation(values[k], pre + k),
        );
      else acc[pre + k] = values[k];
      return acc;
    }, {}),
    ['doesBusinessInExtended.toUnique'],
  );

export const getNextUrlForApplicationStatus = (
  step: ApplicationResponseNextStatusEnum,
) => {
  switch (step) {
    case ApplicationResponseNextStatusEnum.Started:
      return '/onboarding/application-form';
    case ApplicationResponseNextStatusEnum.SignLetterOfIntent:
      return '/onboarding/application-loi-signing';
    case ApplicationResponseNextStatusEnum.SignIca:
      return '/onboarding/application-ica-signing-v2';
    case ApplicationResponseNextStatusEnum.PayFees:
      return '/onboarding/application-fee';
    case ApplicationResponseNextStatusEnum.TransferLicense:
      return '/onboarding/application-transfer-license';
    case ApplicationResponseNextStatusEnum.JoinBoard:
      return '/onboarding/application-join-board';
    case ApplicationResponseNextStatusEnum.PendingApproval:
      return '/onboarding/application-submitted/success';
    case ApplicationResponseNextStatusEnum.Approved:
      return '/welcome-to-rezen/mobile-app';
    default:
      return '/onboarding/application-form';
  }
};

export const getIndexFromStatus = (
  status: ApplicationResponseNextStatusEnum,
) => {
  switch (status) {
    case ApplicationResponseNextStatusEnum.Started:
      return 1;
    case ApplicationResponseNextStatusEnum.SignIca:
      return 2;
    case ApplicationResponseNextStatusEnum.PayFees:
      return 3;
    case ApplicationResponseNextStatusEnum.TransferLicense:
      return 4;
    case ApplicationResponseNextStatusEnum.JoinBoard:
      return 5;
    case ApplicationResponseNextStatusEnum.PendingApproval:
      return 6;
    case ApplicationResponseNextStatusEnum.Approved:
    case ApplicationResponseNextStatusEnum.Rejected:
      return 7;
    default:
      return 1;
  }
};

export const getIndexFromStatusWithLOI = (
  status: ApplicationResponseNextStatusEnum,
) => {
  switch (status) {
    case ApplicationResponseNextStatusEnum.Started:
      return 1;
    case ApplicationResponseNextStatusEnum.SignLetterOfIntent:
      return 2;
    case ApplicationResponseNextStatusEnum.SignIca:
      return 3;
    case ApplicationResponseNextStatusEnum.PayFees:
      return 4;
    case ApplicationResponseNextStatusEnum.TransferLicense:
      return 5;
    case ApplicationResponseNextStatusEnum.JoinBoard:
      return 6;
    case ApplicationResponseNextStatusEnum.PendingApproval:
      return 7;
    case ApplicationResponseNextStatusEnum.Approved:
    case ApplicationResponseNextStatusEnum.Rejected:
      return 8;
    default:
      return 1;
  }
};

export enum WelcomeOnboardingFormFieldName {
  PROFILEPIC = 'avatar',
  ADDRESS_TYPE = 'type',
  ADDRESS_LINE1 = 'streetAddress1',
  ADDRESS_LINE2 = 'streetAddress2',
  ADDRESS_CITY = 'city',
  ADDRESS_POSTALCODE = 'zipOrPostalCode',
  ADDRESS_STATE = 'stateOrProvince',
  ADDRESS_COUNTRY = 'country',
  ABOUT = 'about',
  TITLE = 'title',
  PERSONAL_PHONE_NUMBER = 'personalPhoneNumber',
  LANGUAGES = 'languages',
  PERSONAL_WEBSITE_URL = 'personalWebsiteURL',
  FACEBOOK_URL = 'facebookURL',
  TWITTER_URL = 'twitterURL',
  INSTAGRAM_URL = 'instagramURL',
  YOUTUBE_URL = 'youtubeURL',
  ZILLOW_URL = 'zillowURL',
  COMPETENCIES = 'competencies',
  SUBSCRIBE_TO_CHIME = 'subscribeToChime',
}

export enum TaxAndNationalIDFormFieldName {
  SIN = 'sin',
  SSN = 'ssn',
  GST_ID = 'gstId',
  PST_ID = 'pstId',
  HST_ID = 'hstId',
  QST_ID = 'qstId',
  name = 'name',
  PAID_ENTITY = 'paidEntity',
  BN = 'bnId',
  EIN = 'ein',
  ENTITY_GST_ID = 'entityGstId',
  ENTITY_PST_ID = 'entityPstId',
  ENTITY_HST_ID = 'entityHstId',
  ENTITY_QST_ID = 'entityQstId',
  DRIVER_LICENSE = 'driverLicense',
}

export const taxAndNationalIdUsaFormQuestions = (
  isDriverLicenseUrlAvailable: boolean,
): Array<FormFieldConfig> => {
  const taxAndNationalIdUsaSteps: Array<FormFieldConfig> = [
    {
      name: TaxAndNationalIDFormFieldName.SSN,
      type: InputFieldType.TEXT,
      label:
        'SSN (Social Security Number) or Individual Taxpayer Identification Number (ITIN)',
      placeholder: 'XXX-XX-XXXX',
      rules: {
        required: 'Please provide a SSN',
        ...getIdValidations(NationalIdentificationValueTypeEnum.Ssn),
      },
    },
    {
      name: TaxAndNationalIDFormFieldName.PAID_ENTITY,
      label: 'Are you being paid as entity?',
      type: InputFieldType.RADIO,
      rules: {
        required: 'Please choose one',
      },
      options: [
        {
          label: 'Yes',
          value: YesNoType.YES,
        },
        {
          label: 'No',
          value: YesNoType.NO,
        },
      ],
    },
    {
      name: TaxAndNationalIDFormFieldName.name,
      label: 'Company Name',
      placeholder: 'ACME. Inc',
      type: InputFieldType.TEXT,
      rules: { required: 'Please provide company name' },
      extraFields: [
        {
          name: TaxAndNationalIDFormFieldName.EIN,
          label: 'EIN',
          type: InputFieldType.TEXT,
          placeholder: 'XX-XXXXXXX',
          rules: {
            required: 'Please provide the EIN',
            ...getIdValidations(NationalIdentificationValueTypeEnum.Ein),
          },
        },
      ],
    },
  ];

  if (!isDriverLicenseUrlAvailable) {
    taxAndNationalIdUsaSteps.splice(1, 0, {
      name: TaxAndNationalIDFormFieldName.DRIVER_LICENSE,
      type: InputFieldType.IMAGE_UPLOAD,
      rules: {
        required: 'Please upload driver license image',
        ...FILE_VALIDATIONS,
      },
      placeholder: '',
      label: `Please upload a photo of your current driver's license.`,
    });
  }

  return taxAndNationalIdUsaSteps;
};

export const taxAndNationalIdCanadaFormQuestions = (
  stateOrProvince: AdministrativeAreaResponseStateOrProvinceEnum | undefined,
  isDriverLicenseUrlAvailable: boolean,
): Array<FormFieldConfig> => {
  const taxAndNationalIdCanadaSteps: Array<FormFieldConfig> = [
    {
      name: TaxAndNationalIDFormFieldName.GST_ID,
      type: InputFieldType.TEXT,
      label: 'GST Number',
      placeholder: 'GST Number',
      rules: {
        required: 'Please provide a GST Number',
        ...getIdValidations(NationalIdentificationValueTypeEnum.GstId),
      },
      extraFields: [
        {
          name: TaxAndNationalIDFormFieldName.SIN,
          type: InputFieldType.TEXT,
          label: '(SIN) Social Identification Number',
          placeholder: 'Social Identification Number',
          rules: {
            required: 'Please provide a Social Identification Number',
            ...getIdValidations(NationalIdentificationValueTypeEnum.Sin),
          },
        },
        {
          name: TaxAndNationalIDFormFieldName.HST_ID,
          type: InputFieldType.TEXT,
          label: 'HST Number (optional)',
          placeholder: 'HST Number',
          rules: {
            ...getIdValidations(NationalIdentificationValueTypeEnum.HstId),
          },
        },
        {
          name: TaxAndNationalIDFormFieldName.PST_ID,
          type: InputFieldType.TEXT,
          label: 'PST Number (optional)',
          placeholder: 'PST Number',
          rules: {
            ...getIdValidations(NationalIdentificationValueTypeEnum.PstId),
          },
        },
        {
          name: TaxAndNationalIDFormFieldName.QST_ID,
          type: InputFieldType.TEXT,
          label: 'QST Number (optional)',
          placeholder: 'QST Number',
          rules: {
            ...getIdValidations(NationalIdentificationValueTypeEnum.QstId),
          },
        },
      ],
    },
    {
      name: TaxAndNationalIDFormFieldName.PAID_ENTITY,
      label: 'Are you being paid as entity?',
      type: InputFieldType.RADIO,
      rules: {
        required: 'Please choose one',
      },
      options: [
        {
          label: 'Yes',
          value: YesNoType.YES,
        },
        {
          label: 'No',
          value: YesNoType.NO,
        },
      ],
    },
    {
      name: TaxAndNationalIDFormFieldName.name,
      label: 'Company Name',
      placeholder: 'ACME. Inc',
      type: InputFieldType.TEXT,
      rules: { required: 'Please provide company name' },
      extraFields: [
        {
          name: TaxAndNationalIDFormFieldName.BN,
          label: 'Company Business Number',
          type: InputFieldType.TEXT,
          placeholder: 'Company Business Number',
          rules: {
            required: 'Please provide the Company Business Number',
            ...getIdValidations(NationalIdentificationValueTypeEnum.Bn),
          },
        },
        {
          name: TaxAndNationalIDFormFieldName.ENTITY_GST_ID,
          label: 'GST Number',
          placeholder: 'GST Number',
          type: InputFieldType.TEXT,
          rules: {
            required: "Please provide the company's GST Number",
            ...getIdValidations(NationalIdentificationValueTypeEnum.GstId),
          },
        },
        {
          name: TaxAndNationalIDFormFieldName.ENTITY_HST_ID,
          type: InputFieldType.TEXT,
          label: 'HST Number (optional)',
          placeholder: 'HST Number',
          rules: {
            ...getIdValidations(NationalIdentificationValueTypeEnum.HstId),
          },
        },
        {
          name: TaxAndNationalIDFormFieldName.ENTITY_PST_ID,
          type: InputFieldType.TEXT,
          label: 'PST Number (optional)',
          placeholder: 'PST Number',
          rules: {
            ...getIdValidations(NationalIdentificationValueTypeEnum.PstId),
          },
        },
        {
          name: TaxAndNationalIDFormFieldName.ENTITY_QST_ID,
          type: InputFieldType.TEXT,
          label: 'QST Number (optional)',
          placeholder: 'QST Number',
          rules: {
            ...getIdValidations(NationalIdentificationValueTypeEnum.QstId),
          },
        },
      ],
    },
  ];

  if (!isDriverLicenseUrlAvailable) {
    taxAndNationalIdCanadaSteps.splice(1, 0, {
      name: TaxAndNationalIDFormFieldName.DRIVER_LICENSE,
      type: InputFieldType.IMAGE_UPLOAD,
      rules: {
        required: 'Please upload driver license image',
        ...FILE_VALIDATIONS,
      },
      label:
        stateOrProvince ===
        AdministrativeAreaResponseStateOrProvinceEnum.Alberta
          ? `Please upload a photo showing your face and the front of your driver's license.
          We must confirm your identity before we can process your application.`
          : `Please upload a photo of your current driver's license.`,
    });
  }

  return taxAndNationalIdCanadaSteps;
};

export const isShowExtraFieldInReviewStep = (
  name: string,
  displayValue: any,
  formData: FormDataType,
): boolean => {
  switch (name) {
    case ApplicationFormFieldName.PREFERRED_TITLE_VENDOR:
      return displayValue === 'Yes';
    case ApplicationFormFieldName.HAS_PENDING_TRANSACTIONS_WITH_CURRENT_BROKERAGE:
      return true;
    case ApplicationFormFieldName.TEAM_NAME:
      return displayValue === 'Team Agent';
    case ApplicationFormFieldName.TEAM_LEADER:
      return displayValue === 'Team Agent';
    case ApplicationFormFieldName.TEAM_LEADER_NAME:
      return (
        displayValue === 'Team Agent' && formData?.teamLeader === YesNoType.NO
      );
    default:
      return false;
  }
};

export const getCurrentApplicationStepData = async (
  application: ApplicationResponse,
  driverLicenseImageUrl?: string,
) => {
  const currentFormData: Partial<FormDataType> = {};
  let currentStep = 0;
  let isReviewingForm = false;

  const incrementStep = () => {
    currentStep = currentStep + 1;
  };

  const allMlsesNames = flatten(
    application.doesBusinessInExtended?.map((item) =>
      item.mlses?.map((mls) => mls),
    ),
  );

  const mlsResponse = await Promise.all(
    allMlsesNames?.map(async (mls) => {
      if (mls === 'I do not belong to a MLS.') {
        return {
          id: 'I do not belong to a MLS.',
          name: 'I do not belong to a MLS.',
        };
      } else {
        const { data: mlsdata } = await new MlsControllerApi(
          getYentaConfiguration(),
        ).searchLiteMls(undefined, undefined, undefined, mls);

        return mlsdata?.results?.[0];
      }
    }),
  );

  const allBoardsNames = flatten(
    application.doesBusinessInExtended?.map((item) =>
      item.boards?.map((board) => board),
    ),
  );

  const boardResponse = await Promise.all(
    allBoardsNames?.map(async (board) => {
      if (board === 'I do not belong to a Board.') {
        return {
          id: 'I do not belong to a Board.',
          name: 'I do not belong to a Board.',
        };
      } else {
        const { data: boarddata } = await new BoardControllerApi(
          getYentaConfiguration(),
        ).searchBoards(undefined, undefined, undefined, undefined, board);

        return boarddata?.results?.[0];
      }
    }),
  );

  // Stage Phone Number
  if (application.phoneNumber) {
    currentFormData.phoneNumber = application.phoneNumber;
    incrementStep();
  } else {
    return { currentStep, currentFormData, isReviewingForm };
  }

  // Stage Birth Date
  if (application.birthDate) {
    currentFormData.birthDate = application.birthDate;
    incrementStep();
  } else {
    return { currentStep, currentFormData, isReviewingForm };
  }

  // Stage State
  if (
    application.doesBusinessInExtended?.[0]?.licenseResponse?.administrativeArea
      ?.stateOrProvince
  ) {
    currentFormData.selectedProvinces = application.doesBusinessInExtended?.map(
      (item) => ({
        label: capitalizeEnum(
          item.licenseResponse?.administrativeArea?.stateOrProvince!,
        ),
        value: item.licenseResponse?.administrativeArea?.stateOrProvince,
      }),
    );
    incrementStep();
  } else {
    return { currentStep, currentFormData, isReviewingForm };
  }

  // Stage MLS
  if (application.doesBusinessInExtended?.[0]?.mlses?.length) {
    currentFormData.doesBusinessInExtended = application.doesBusinessInExtended?.map(
      (item) => {
        const currentMls = mlsResponse?.find(
          (mls) => mls?.name === item.mlses?.[0],
        );
        if (currentMls) {
          incrementStep();
        }
        return {
          ...item,
          mls: !currentMls?.name
            ? undefined
            : currentMls?.name === 'I do not belong to a MLS.'
            ? 'I do not belong to a MLS.'
            : {
                label: currentMls?.name,
                value: currentMls?.id,
              },
        };
      },
    );
  } else {
    return { currentStep, currentFormData, isReviewingForm };
  }

  // Stage Board
  if (application.doesBusinessInExtended?.[0]?.boards?.length) {
    currentFormData.doesBusinessInExtended = currentFormData.doesBusinessInExtended?.map(
      (item: LicenseBoardMLSRequest) => {
        const currentBoard = boardResponse?.find(
          (board) => board?.name === item.boards?.[0],
        );
        if (currentBoard) {
          incrementStep();
        }
        return {
          ...item,
          board: !currentBoard?.name
            ? undefined
            : currentBoard?.name === 'I do not belong to a Board.'
            ? 'I do not belong to a Board.'
            : {
                label: currentBoard?.name,
                value: currentBoard?.id,
              },
        };
      },
    );
  } else {
    return { currentStep, currentFormData, isReviewingForm };
  }

  // Stage License Information
  let isLicenseDetailsFilled = true;
  if (application.doesBusinessInExtended?.[0]?.licenseResponse) {
    currentFormData.doesBusinessInExtended = currentFormData.doesBusinessInExtended?.map(
      (item: LicenseBoardMLSResponse) => {
        if (item.licenseResponse?.number) {
          incrementStep();
        }

        if (
          item.licenseResponse?.active !== undefined &&
          item.licenseResponse?.active !== null
        ) {
          incrementStep();
        }

        if (item.licenseResponse?.expirationDate) {
          incrementStep();
        }

        if (
          item.licenseResponse?.knownComplaints !== undefined &&
          item.licenseResponse?.knownComplaints !== null
        ) {
          incrementStep();
        } else {
          isLicenseDetailsFilled = false;
        }

        return {
          ...item,
          licenseRequest: {
            ...item.licenseResponse,
            active:
              item.licenseResponse?.active === undefined ||
              item.licenseResponse?.active === null
                ? null
                : !!item.licenseResponse?.active
                ? YesNoType.YES
                : YesNoType.NO,
            knownComplaints:
              item.licenseResponse?.knownComplaints === undefined ||
              item.licenseResponse?.knownComplaints === null
                ? null
                : !!item.licenseResponse?.knownComplaints
                ? YesNoType.YES
                : YesNoType.NO,
          },
        };
      },
    );
  }

  if (!isLicenseDetailsFilled) {
    return { currentStep, currentFormData, isReviewingForm };
  }

  const isCanadaUser =
    application.country === ApplicationResponseCountryEnum.Canada;

  // Stage Photo Upload
  if (application.driverLicenseImagePath && driverLicenseImageUrl) {
    currentFormData.photoUpload = driverLicenseImageUrl;
    incrementStep();
  } else if (
    application.currentBrokerage &&
    application.hasPendingTransactionsWithCurrentBrokerage !== undefined &&
    application.hasPendingTransactionsWithCurrentBrokerage !== null
  ) {
    incrementStep();
  } else {
    return { currentStep, currentFormData, isReviewingForm };
  }

  // Stage Current Brokerage
  if (application.currentBrokerage) {
    currentFormData.currentBrokerage = application.currentBrokerage;
  }

  // Stage Has Pending Transactions With Current Brokerage
  if (
    application.hasPendingTransactionsWithCurrentBrokerage !== undefined &&
    application.hasPendingTransactionsWithCurrentBrokerage !== null
  ) {
    currentFormData.hasPendingTransactionsWithCurrentBrokerage = application.hasPendingTransactionsWithCurrentBrokerage
      ? YesNoType.YES
      : YesNoType.NO;
  }
  if (
    application.currentBrokerage &&
    application.hasPendingTransactionsWithCurrentBrokerage !== undefined &&
    application.hasPendingTransactionsWithCurrentBrokerage !== null
  ) {
    incrementStep();
  } else {
    return { currentStep, currentFormData, isReviewingForm };
  }

  // Stage Estimated Sales
  if (application.estimatedSales) {
    currentFormData.estimatedSales = application.estimatedSales;
    incrementStep();
  } else {
    return { currentStep, currentFormData, isReviewingForm };
  }

  // Stage Team Details
  if (application.teamRole) {
    currentFormData.individualAgent = YesNoType.NO;
    currentFormData.teamLeader =
      application.teamRole === ApplicationResponseTeamRoleEnum.Leader
        ? YesNoType.YES
        : YesNoType.NO;

    if (
      (application.teamRole === ApplicationResponseTeamRoleEnum.Leader &&
        application.teamName) ||
      (application.teamRole === ApplicationResponseTeamRoleEnum.Member &&
        application.teamName &&
        application.teamLeaderName)
    ) {
      currentFormData.teamName = application.teamName;
      currentFormData.teamLeaderName = application.teamLeaderName;

      if (isCanadaUser) {
        isReviewingForm = true;
      } else {
        incrementStep();
      }
    }
  } else if (
    application.individualAgentFlag !== undefined &&
    application.individualAgentFlag !== null
  ) {
    currentFormData.individualAgent = application.individualAgentFlag
      ? YesNoType.YES
      : YesNoType.NO;
    if (isCanadaUser) {
      isReviewingForm = true;
    } else {
      incrementStep();
    }
  } else {
    return { currentStep, currentFormData, isReviewingForm };
  }

  // Stage Title Vendor Only for US
  if (
    !isCanadaUser &&
    application.preferredTitleVendorFlag !== undefined &&
    application.preferredTitleVendorFlag !== null
  ) {
    currentFormData.isPreferredTitleVendor = application.preferredTitleVendorFlag
      ? YesNoType.YES
      : YesNoType.NO;
    currentFormData.preferredTitleVendor = application.preferredTitleVendor;
    isReviewingForm = true;
  }

  return { currentStep, currentFormData, isReviewingForm };
};
