import {
  faAddressBook,
  faArrowLeftLong,
  faArrowUpArrowDown,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { ADDRESS_PLACEHOLDER } from '../../../constants/PlaceholderConstants';
import { fetchPdfAndConvertToFile } from '../../../hooks/useFetchPdfAndSetForm';
import {
  CreateParticipantRequest,
  CreateParticipantRequestParticipantRoleEnum,
  NationalIdentificationValueTypeEnum,
  ParticipantValueRoleEnum,
  PaymentParticipantValueRoleEnum,
  PaymentParticipantValueTypeEnum,
  TransactionResponse,
  TransactionResponseCountryEnum,
} from '../../../openapi/arrakis';
import {
  AgentResponseAccountCountryEnum,
  DirectoryEntryResponseTypeEnum,
  DirectoryVendorResponseRoleEnum,
} from '../../../openapi/yenta';
import {
  DirectoryCommonEntityResponse,
  useDirectoryAddress,
  useFetchW9OnDemand,
} from '../../../query/directory/useDirectory';
import ErrorService from '../../../services/ErrorService';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import {
  addCommissionParticipant,
  addOPCityCommissionParticipant,
  fetchTransactionDetails,
  uploadW9Form,
} from '../../../slices/TransactionSlice';
import { fetchUserByIds } from '../../../slices/UserIdsSlice';
import {
  AppDispatch,
  AsyncSelectOptionReactElement,
  ISelectOption,
  ParticipantType,
} from '../../../types';
import { getIdValidations } from '../../../utils/AgentHelper';
import { STATE_OR_PROVINCE_ABBREVIATIONS } from '../../../utils/AnnouncementUtils';
import { canParticipantEnterInternationalPhone } from '../../../utils/ParticipantHelper';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { searchActiveAgents } from '../../../utils/TableUtils';
import {
  RequiredField,
  getValidParticipantRoles,
  isAddParticipantLabelRequired,
  isCanadaTransaction,
  isNotOPCityParticipantType,
  validateFields,
} from '../../../utils/TransactionHelper';
import {
  EMAIL_VALIDATIONS,
  FILE_VALIDATIONS,
  GOOGLE_AUTO_COMPLETE_VALIDATIONS_OPTIONAL,
  PHONE_NUMBER_VALIDATIONS,
} from '../../../utils/Validations';
import AvatarLabelComponent from '../../AgentReports/AvatarLabelComponent';
import AdminOnly from '../../auth/AdminOnly';
import { ExternalAgentConfirmationModal } from '../../Directory/ExternalAgentConfirmationModal';
import {
  getNationalBusinessIds,
  extractNationalBusinessIds,
} from '../../Directory/utils';
import { companyVerify } from '../../Directory/DirectoryUtils';
import ZenContactListSidebar from '../../Directory/ZenContactListSidebar';
import ZenRoleDirectoryForm from '../../Directory/ZenRoleDirectoryForm';
import IconButton from '../../IconButton';
import ZenControlledAsyncSelectInput from '../../Zen/Input/ZenControlledAsyncSelectInput';
import ZenControlledEmailInput from '../../Zen/Input/ZenControlledEmailInput';
import ZenControlledFileUploadInput from '../../Zen/Input/ZenControlledFileUploadInput';
import ZenControlledPhoneNumberInput from '../../Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledRadioInput from '../../Zen/Input/ZenControlledRadioInput';
import ZenControlledSelectInput from '../../Zen/Input/ZenControlledSelectInput';
import ZenControlledTextAreaInput from '../../Zen/Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenControlledToggleInput from '../../Zen/Input/ZenControlledToggleInput';
import ZenButton from '../../Zen/ZenButton';
import ZenControlledGoogleAutocompleteSearchInput, {
  GooglePlaceLocationType,
} from '../Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenSidebarModalActionFooterV2 from '../Modal/ZenSidebarModalActionFooterV2';
import ZenSidebarModal from '../ZenSidebarModal';
import { FieldArrayPrefix } from './CreateTransaction/ZenTransactionBuyerAndSellerStep';
import ZenExternalParticipantWarning from './ZenExternalParticipantWarning';
import parsePhoneNumber from 'libphonenumber-js';

interface ZenAddParticipantsFormProps {
  isOpen: boolean;
  onClose(): void;
  transaction: TransactionResponse;
  agentType?: ParticipantType;
  role?: ParticipantValueRoleEnum;
}

interface FormData {
  type: ParticipantType;
  yentaId: ISelectOption;
  firstName: string;
  lastName: string;
  company: string;
  emailAddress: string;
  phoneNumber: string;
  commissionDocumentRecipient: boolean;
  passThrough: boolean;
  personalDeal: boolean;
  address: string;
  autoAddress?: GooglePlaceLocationType;
  participantRole: ISelectOption;
  w9form: File[];
  Bn: string;
  gstId: string;
  pstId: string;
  hstId: string;
  qstId: string;
  ein: string;
  sin: string;
  notes: string;
}

export const OPCITY_KEY = 'opCity';

const ZenAddParticipantsForm: React.FC<ZenAddParticipantsFormProps> = ({
  isOpen,
  onClose,
  transaction,
  agentType,
  role,
}) => {
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitting },
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const dispatch: AppDispatch = useDispatch();
  const type = watch('type');

  const [isManual, setIsManual] = useState<boolean>(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [roleSibebarOpen, setRoleSidebarOpen] = useState<boolean>(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [pendingSaveData, setPendingSaveData] = useState<
    DirectoryCommonEntityResponse | undefined
  >();

  const [currentFormContact, setCurrentFormContact] = useState<
    DirectoryCommonEntityResponse | undefined
  >();
  const yentaId = watch('yentaId', { label: '', value: '' });
  const isCanada =
    transaction.country === TransactionResponseCountryEnum.Canada;

  const [
    participantRole,
    firstName,
    lastName,
    companyName,
    commissionDocumentRecipient,
    ein,
  ] = watch([
    'participantRole',
    'firstName',
    'lastName',
    'company',
    'commissionDocumentRecipient',
    'ein',
  ]);
  const { getGeocodeAddress, isLoading } = useDirectoryAddress();
  const { mutate: fetchW9 } = useFetchW9OnDemand();

  useEffect(() => {
    if (yentaId?.value) {
      dispatch(fetchUserByIds([yentaId.value]));
    }
  }, [dispatch, yentaId?.value]);

  const watchFields = {
    firstName,
    lastName,
    companyName,
    participantRole,
    ein,
  };

  const participantTypeOptions = [
    {
      label: 'Real Agent',
      value: PaymentParticipantValueTypeEnum.Agent,
    },
    {
      label: 'External Participant',
      value: PaymentParticipantValueTypeEnum.ExternalEntity,
    },
  ];

  if (!transaction.listing && !isCanadaTransaction(transaction)) {
    participantTypeOptions.push({
      label: 'Op City',
      value: OPCITY_KEY as PaymentParticipantValueTypeEnum,
    });
  }

  const onSubmit = async (data: FormData) => {
    if (data.type === OPCITY_KEY) {
      await dispatch(addOPCityCommissionParticipant(transaction.id!));
    } else {
      const nationalIds = [];

      if (isCanada) {
        if (data.Bn) {
          nationalIds?.push({
            id: data.Bn,
            type: NationalIdentificationValueTypeEnum.Bn,
          });
        }
        if (data.gstId) {
          nationalIds?.push({
            id: data.gstId,
            type: NationalIdentificationValueTypeEnum.GstId,
          });
        }
        if (data.pstId) {
          nationalIds?.push({
            id: data.pstId,
            type: NationalIdentificationValueTypeEnum.PstId,
          });
        }
        if (data.hstId) {
          nationalIds?.push({
            id: data.hstId,
            type: NationalIdentificationValueTypeEnum.HstId,
          });
        }
        if (data.qstId) {
          nationalIds?.push({
            id: data.qstId,
            type: NationalIdentificationValueTypeEnum.QstId,
          });
        }
        if (data.sin) {
          nationalIds?.push({
            id: data.sin,
            type: NationalIdentificationValueTypeEnum.Sin,
          });
        }
      } else {
        if (data.ein) {
          nationalIds?.push({
            id: data.ein,
            type: NationalIdentificationValueTypeEnum.Ein,
          });
        }
      }
      const phoneNumber = parsePhoneNumber('+' + data.phoneNumber);
      const finalData: CreateParticipantRequest = {
        emailAddress: data.emailAddress,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: phoneNumber?.formatInternational() ?? data.phoneNumber,
        yentaId: data.yentaId?.value,
        commissionDocumentRecipient: !!data.commissionDocumentRecipient,
        payer: false,
        passThrough: !!data.passThrough,
        paidByReal: false,
        personalDeal: !!data.personalDeal,
        address: isManual ? data.address : data.autoAddress?.formatted_address,
        paymentInstructions: '',
        participantRole: data.participantRole
          ?.value as CreateParticipantRequestParticipantRoleEnum,
        paidViaBusinessEntity: data.company
          ? {
              name: data.company,
              nationalIds,
            }
          : undefined,
        notes: data.notes,
      };
      const participant = await dispatch(
        addCommissionParticipant(transaction.id!, finalData),
      );

      if (participant) {
        if (data.w9form) {
          await dispatch(uploadW9Form(participant.id!, data.w9form[0]));
        }
      }
    }

    await dispatch(fetchTransactionDetails(transaction.id!, false));
    onClose();
  };

  const showAllCountries = canParticipantEnterInternationalPhone(
    participantRole?.value as PaymentParticipantValueRoleEnum,
  );

  const handleSidebarToggle = (_index?: number) => () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const importFromDirectory = async (
    contact: DirectoryCommonEntityResponse,
  ) => {
    const vendorId =
      contact.type === DirectoryEntryResponseTypeEnum.Person
        ? contact.linkedId
        : contact.id;
    const vendorName =
      contact.type === DirectoryEntryResponseTypeEnum.Person
        ? contact.linkedName
        : contact.name;

    const {
      nationalId,
      gstNationalId,
      hstNationalId,
      qstNationalId,
      bnNationalId,
    } = extractNationalBusinessIds(contact?.nationalBusinessIdentifications);

    if (vendorId && contact.hasW9) {
      await fetchW9(
        { id: vendorId },
        {
          onSuccess: (signedUrl) => {
            if (signedUrl) {
              fetchPdfAndConvertToFile(signedUrl).then((file) => {
                if (file) {
                  setValue('w9form', [file]);
                }
              });
            }
          },
        },
      );
    }
    setValue('firstName', contact?.firstName!);
    setValue('lastName', contact?.lastName!);
    setValue('company', vendorName || '');
    setValue('emailAddress', contact?.emailAddress!);
    setValue('ein', nationalId);
    setValue('gstId', gstNationalId);
    setValue('Bn', bnNationalId);
    setValue('hstId', hstNationalId);
    setValue('qstId', qstNationalId);
    setValue('phoneNumber', contact?.phoneNumber!);
    setValue('address', contact?.address?.oneLine!);
  };

  const handleSave = async (
    _fieldArrayPrefix?: FieldArrayPrefix,
    contact?: DirectoryCommonEntityResponse,
  ) => {
    if (
      contact?.role === DirectoryVendorResponseRoleEnum.OtherAgent &&
      contact?.nationalBusinessIdentifications &&
      contact?.nationalBusinessIdentifications?.length > 0
    ) {
      setPendingSaveData(contact);
      setConfirmationModalOpen(true);
      return;
    }

    if (contact) {
      setPendingSaveData(contact);
      await importFromDirectory(contact);
    }
  };

  const completeSaveOperation = async (
    _fieldArrayPrefix?: FieldArrayPrefix,
    _contact?: DirectoryCommonEntityResponse,
  ) => {
    if (pendingSaveData) {
      await importFromDirectory(pendingSaveData);
    }
    setPendingSaveData(undefined);
    setConfirmationModalOpen(false);
  };

  const handleRoleSidebarToggle = () => async () => {
    const values = getValues();
    const geoAddress =
      values?.autoAddress ?? (await getGeocodeAddress(values?.address));
    setCurrentFormContact({
      ...values,
      emailAddress: values?.emailAddress,
      address: {
        oneLine: geoAddress?.formatted_address ?? undefined,
      },
      addressComponents: geoAddress?.address_components ?? [],
      placeId: geoAddress?.place_id,
      name: values?.company!,
      role: DirectoryVendorResponseRoleEnum.OtherAgent, // always set to OtherAgent
      nationalBusinessIdentifications: getNationalBusinessIds(values),
    });
    setRoleSidebarOpen((prevState) => !prevState);
  };

  return (
    <ZenSidebarModal
      title='Add Participant'
      subtitle={transaction.address?.oneLine}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        className='flex flex-col justify-between min-h-full'
        title='sidebar-form'
      >
        <div className='p-4 flex-auto mb-20'>
          <div>
            <ZenButton
              variant='secondary-gray-outline'
              label='Back'
              LeftIconComponent={<FontAwesomeIcon icon={faArrowLeftLong} />}
              onClick={onClose}
            />
            <div className='mt-5'>
              <ZenControlledRadioInput<FormData, 'type'>
                label='Participant type'
                options={participantTypeOptions}
                name='type'
                defaultValue={
                  agentType === OPCITY_KEY
                    ? (OPCITY_KEY as ParticipantType)
                    : undefined
                }
                control={control}
                rules={{
                  required: 'Participant type is required',
                }}
                inlineOptions
                isRequired
              />
            </div>
            {type === ParticipantType.EXTERNAL_ENTITY && (
              <>
                <div className='mt-5'>
                  <ZenExternalParticipantWarning />
                </div>
                <div className='flex justify-end mt-2'>
                  <ZenButton
                    label='Directory'
                    variant='primary-link'
                    LeftIconComponent={<FontAwesomeIcon icon={faAddressBook} />}
                    onClick={handleSidebarToggle()}
                    zeroPadding
                    fontstyle
                  />
                </div>
              </>
            )}
            {type !== undefined &&
            isNotOPCityParticipantType(type, agentType) ? (
              <div>
                <div className='mt-4'>
                  <ZenControlledSelectInput<FormData, 'participantRole'>
                    name='participantRole'
                    control={control}
                    label='Role'
                    placeholder='Select Role'
                    rules={{ required: 'Please select a role' }}
                    options={getValidParticipantRoles(
                      transaction.transactionType!,
                      transaction?.transactionOwner?.represents,
                      type,
                    )}
                    defaultValue={
                      role
                        ? {
                            label: capitalizeEnum(role),
                            value: role as string,
                          }
                        : undefined
                    }
                    isRequired
                  />
                </div>
                {((PaymentParticipantValueTypeEnum.Agent as unknown) as ParticipantType) ===
                type ? (
                  <div className='mt-5'>
                    <ZenControlledAsyncSelectInput<FormData, 'yentaId'>
                      control={control}
                      name='yentaId'
                      placeholder='Search'
                      label='Name of Participant'
                      fetchData={async (search, page) => {
                        try {
                          const searchResponse = await searchActiveAgents(
                            page,
                            [
                              (transaction.country as unknown) as AgentResponseAccountCountryEnum,
                            ],
                            50,
                            search,
                            undefined,
                          );
                          const options: AsyncSelectOptionReactElement[] = searchResponse.data?.map(
                            (resp) => ({
                              value: `${resp.id}`,
                              label: (
                                <AvatarLabelComponent
                                  avatar={resp?.avatar!}
                                  firstName={resp?.firstName!}
                                  lastName={resp?.lastName!}
                                  emailAddress={resp?.emailAddress!}
                                  administrativeAreas={resp?.administrativeAreas?.map(
                                    (e) => {
                                      if (!e.stateOrProvince) {
                                        return 'N/A';
                                      }
                                      return STATE_OR_PROVINCE_ABBREVIATIONS[
                                        e.stateOrProvince
                                      ];
                                    },
                                  )}
                                />
                              ),
                            }),
                          );

                          return options;
                        } catch (e) {
                          ErrorService.notify(
                            'Unable to search for registered agents in add participants',
                            e,
                            {
                              search: {
                                term: search,
                                page,
                                country: transaction?.country,
                              },
                            },
                          );
                          dispatch(
                            showErrorToast(
                              'An unexpected error occurred.',
                              'We were unable to search for an agent. Please try again in a few moments or contact support.',
                            ),
                          );
                        }

                        return [];
                      }}
                      rules={{
                        required: 'Participant name is required',
                      }}
                      isRequired
                    />
                  </div>
                ) : (
                  <div>
                    <div className='flex flex-row mt-5 space-x-2'>
                      <ZenControlledTextInput<FormData, 'firstName'>
                        control={control}
                        label='First name'
                        name='firstName'
                        placeholder='First Name'
                        rules={{
                          minLength: {
                            value: 2,
                            message: 'Must be at least 2 characters',
                          },
                          ...validateFields(
                            RequiredField.FIRST_NAME,
                            transaction,
                            watchFields,
                          ),
                        }}
                        isRequired
                      />
                      <ZenControlledTextInput<FormData, 'lastName'>
                        control={control}
                        label='Last name'
                        name='lastName'
                        placeholder='Last Name'
                        rules={{
                          minLength: {
                            value: 2,
                            message: 'Must be at least 2 characters',
                          },
                          ...validateFields(
                            RequiredField.LAST_NAME,
                            transaction,
                            watchFields,
                          ),
                        }}
                        isRequired
                      />
                    </div>
                    <div className='mt-5'>
                      <ZenControlledTextInput<FormData, 'company'>
                        control={control}
                        label='Business name'
                        subLabel='(I.e. Brokerage or company name)'
                        name='company'
                        placeholder='Business Name'
                        rules={{
                          ...validateFields(
                            RequiredField.COMPANY_NAME,
                            transaction,
                            watchFields,
                          ),
                        }}
                        isRequired
                      />
                    </div>
                    {!isCanada ? (
                      <div className='mt-5'>
                        <ZenControlledTextInput<FormData, 'ein'>
                          control={control}
                          label='EIN'
                          name='ein'
                          placeholder='E.g. 12-1234567'
                          rules={{
                            ...getIdValidations(
                              NationalIdentificationValueTypeEnum.Ein,
                            ),
                          }}
                        />
                      </div>
                    ) : (
                      <div className='flex flex-row mt-5 space-x-2'>
                        <ZenControlledTextInput<FormData, 'gstId'>
                          control={control}
                          label='GST'
                          name='gstId'
                          placeholder='E.g. 123456789 RT 0001'
                          rules={{
                            ...getIdValidations(
                              NationalIdentificationValueTypeEnum.GstId,
                            ),
                          }}
                        />
                        <ZenControlledTextInput<FormData, 'Bn'>
                          control={control}
                          label='Business number'
                          name='Bn'
                          placeholder='E.g. 123456789'
                          rules={{
                            ...getIdValidations(
                              NationalIdentificationValueTypeEnum.Bn,
                            ),
                          }}
                        />
                      </div>
                    )}

                    <div className='mt-5'>
                      <ZenControlledEmailInput<FormData, 'emailAddress'>
                        control={control}
                        label='Email'
                        subLabel='(Required if receiving electronic documents)'
                        name='emailAddress'
                        placeholder='E.g. Johndoe@example.com'
                        rules={{
                          required: {
                            value: !!commissionDocumentRecipient,
                            message:
                              'Email is required if receiving electronic documents',
                          },
                          ...EMAIL_VALIDATIONS,
                          ...validateFields(
                            RequiredField.EMAIL,
                            transaction,
                            watchFields,
                          ),
                        }}
                        isRequired={
                          isAddParticipantLabelRequired(
                            transaction,
                            participantRole,
                          ) || !!commissionDocumentRecipient
                        }
                      />
                    </div>
                    <div className='mt-5'>
                      <ZenControlledPhoneNumberInput<FormData, 'phoneNumber'>
                        control={control}
                        label='Phone number'
                        name='phoneNumber'
                        placeholder='E.g. +1 (702) 123-4567'
                        showAllCountries={showAllCountries}
                        rules={{
                          ...PHONE_NUMBER_VALIDATIONS,
                        }}
                      />
                    </div>
                    <div className='relative mt-8'>
                      {isManual ? (
                        <div className=''>
                          <ZenControlledTextInput<FormData, 'address'>
                            placeholder={ADDRESS_PLACEHOLDER}
                            control={control}
                            label='Address'
                            subLabel='(Must match address on W9 Form, if uploaded)'
                            name='address'
                            rules={{
                              ...validateFields(
                                RequiredField.ADDRESS,
                                transaction,
                                watchFields,
                              ),
                            }}
                            isRequired={isAddParticipantLabelRequired(
                              transaction,
                              participantRole,
                            )}
                          />
                        </div>
                      ) : (
                        <div className=''>
                          <ZenControlledGoogleAutocompleteSearchInput<
                            FormData,
                            'autoAddress'
                          >
                            control={control}
                            shouldUnregister={false}
                            name='autoAddress'
                            label='Search Address'
                            subLabel='(Source by Google)'
                            placeholder={ADDRESS_PLACEHOLDER}
                            rules={{
                              ...GOOGLE_AUTO_COMPLETE_VALIDATIONS_OPTIONAL,
                            }}
                            isRequired={isAddParticipantLabelRequired(
                              transaction,
                              participantRole,
                            )}
                          />
                        </div>
                      )}
                      <div className='absolute -top-3 right-0'>
                        <IconButton
                          label={
                            isManual
                              ? 'Search Address'
                              : 'Enter address manually'
                          }
                          variant='none'
                          buttonStyle='text-primary-blue rounded-full border-2 border-primary-blue'
                          leftIcon={
                            <FontAwesomeIcon icon={faArrowUpArrowDown} />
                          }
                          onClick={() => {
                            setIsManual(!isManual);
                          }}
                        />
                      </div>
                    </div>
                    <div className='mt-5'>
                      {!isCanadaTransaction(transaction) && (
                        <ZenControlledFileUploadInput<FormData, 'w9form'>
                          name='w9form'
                          control={control}
                          label='Upload W9 Form'
                          placeholder='Browse File'
                          accept='.pdf'
                          rules={{
                            ...FILE_VALIDATIONS,
                          }}
                        />
                      )}
                    </div>
                    {isCanada && (
                      <div className='mt-5'>
                        <label className='text-base font-primary-medium text-dark'>
                          Other Tax Id. Numbers
                        </label>
                        <div className='mt-2'>
                          <ZenControlledTextInput<FormData, 'sin'>
                            control={control}
                            name='sin'
                            label='Social insurance number (SIN)'
                            placeholder='E.g. 123-123-1234'
                            rules={{
                              ...getIdValidations(
                                NationalIdentificationValueTypeEnum.Sin,
                              ),
                            }}
                          />
                        </div>
                        <div className='mt-5'>
                          <ZenControlledTextInput<FormData, 'hstId'>
                            control={control}
                            name='hstId'
                            label='Harmonized sales tax (HST)'
                            placeholder='E.g. 123456789 RT 00002'
                            rules={{
                              ...getIdValidations(
                                NationalIdentificationValueTypeEnum.HstId,
                              ),
                            }}
                          />
                        </div>
                        <div className='mt-5'>
                          <ZenControlledTextInput<FormData, 'pstId'>
                            control={control}
                            name='pstId'
                            label='Provincial sales tax (PST)'
                            placeholder='E.g. PST-1234-1234'
                            rules={{
                              ...getIdValidations(
                                NationalIdentificationValueTypeEnum.PstId,
                              ),
                            }}
                          />
                        </div>
                        <div className='mt-5'>
                          <ZenControlledTextInput<FormData, 'qstId'>
                            control={control}
                            name='qstId'
                            label='Quebec sales tax (QST)'
                            placeholder='E.g. 123456789 TQ 1234'
                            rules={{
                              ...getIdValidations(
                                NationalIdentificationValueTypeEnum.QstId,
                              ),
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div className='mt-5'>
                  {!transaction.listing && (
                    <div className='border divide-y rounded-[10px] p-2'>
                      <AdminOnly>
                        <div className='p-1.5'>
                          <ZenControlledToggleInput<FormData, 'personalDeal'>
                            name='personalDeal'
                            control={control}
                            defaultValue={false}
                            label='Personal Deal?'
                          />
                        </div>
                      </AdminOnly>
                      <div className='p-1.5'>
                        <ZenControlledToggleInput<
                          FormData,
                          'commissionDocumentRecipient'
                        >
                          name='commissionDocumentRecipient'
                          control={control}
                          defaultValue={false}
                          label='Receives Invoices/Comm. Documents?'
                        />
                      </div>
                      <AdminOnly>
                        <div className='p-1.5'>
                          <ZenControlledToggleInput<FormData, 'passThrough'>
                            name='passThrough'
                            control={control}
                            defaultValue={false}
                            label='Pass Through Deal?'
                          />
                        </div>
                      </AdminOnly>
                    </div>
                  )}
                </div>

                <div className='mt-5 pb-16'>
                  <ZenControlledTextAreaInput<FormData, 'notes'>
                    control={control}
                    label='Notes'
                    name='notes'
                    placeholder={`E.g. Assistant's name and number, etc.`}
                    rows={3}
                  />
                </div>
                {type === ParticipantType.EXTERNAL_ENTITY && (
                  <div className='flex items-end justify-end -mr-4'>
                    <ZenButton
                      label='Add to My Directory'
                      variant='primary-link'
                      LeftIconComponent={
                        <FontAwesomeIcon
                          icon={faPlus}
                          className='text-primary-blue cursor-pointer'
                        />
                      }
                      isDisabled={isLoading}
                      isSubmitting={isLoading}
                      onClick={handleRoleSidebarToggle()}
                      fontstyle
                    />
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <ZenSidebarModalActionFooterV2
            submitButtonText='Save'
            cancelButtonText='Cancel'
            onClose={onClose}
            isSubmitting={isSubmitting}
            isDisabled={isSubmitting}
            buttonType='button'
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </form>
      {isSidebarOpen && (
        <ZenContactListSidebar
          currentFieldArrayPrefixNotRequired
          isOpen={isSidebarOpen}
          onClose={handleSidebarToggle()}
          onSave={handleSave}
          isExternalAgent
        />
      )}
      {roleSibebarOpen && (
        <ZenRoleDirectoryForm
          isOpen={roleSibebarOpen}
          existingVendorOrPerson={currentFormContact}
          mode='populate'
          onClose={handleRoleSidebarToggle()}
          isExternal
        />
      )}
      {pendingSaveData && isConfirmationModalOpen && (
        <ExternalAgentConfirmationModal
          isConfirmationModalOpen={isConfirmationModalOpen}
          setConfirmationModalOpen={setConfirmationModalOpen}
          companyVerify={companyVerify}
          pendingSaveData={pendingSaveData}
          completeSaveOperation={completeSaveOperation}
        />
      )}
    </ZenSidebarModal>
  );
};

export default ZenAddParticipantsForm;
