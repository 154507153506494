import {
  faBars,
  faEnvelopeDot,
  faEnvelopeOpen,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFieldArray, useForm } from 'react-hook-form-v7';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import RealLogoImg from '../../../assets/img/new-rezen-black-logo.svg';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { MessageDto, RezenObjectTypeEnum } from '../../../openapi/yada';
import { useFetchInboxCount } from '../../../query/inbox/useInbox';
import MenuService from '../../../services/MenuService';
import SessionStorageService from '../../../services/SessionStorageService';
import { resetApp } from '../../../slices/actions/authActions';
import {
  getBrokerQueueMessages,
  markBrokerQueueMessageAsRead,
  markBrokerQueueMessageAsUnread,
  markMultipleBrokerQueueMessageRead,
  markMultipleBrokerQueueMessageUnread,
} from '../../../slices/BrokerQueueSlice';
import { fetchBorrowerDetailsForInbox } from '../../../slices/MortgageSlice';
import { AppDispatch, FeatureFlagTypeEnum, RootState } from '../../../types';
import { deleteAuthCookie } from '../../../utils/AuthUtils';
import {
  getBrokerQueuePathName,
  getMortgageQueuePathName,
} from '../../../utils/BrokerQueueUtils';
import { cn } from '../../../utils/classUtils';
import { SegmentedControl } from '../../commons/SegmentedControl';
import DefaultLoader from '../../DefaultLoader';
import FeatureFlagEnabledOnly from '../../FeatureFlagEnabledOnly';
import GeminiSearchBar from '../../Gemini/GeminiSearchBar';
import Hover from '../../Hover';
import ResourceContainer from '../../ResourceContainer';
import ZenCheckbox from '../../Zen/ZenCheckbox';
import BrokerQueueFilterV2, {
  BrokerQueueFilterEnum,
} from './BrokerQueueFilterV2';
import BrokerQueueMenuEmpty from './BrokerQueueMenuEmpty';
import BrokerQueueTransaction from './BrokerQueueTransaction';
import BrokerQueueTransactionOptions from './BrokerQueueTransactionOptions';
import ConversationTab from './ConversationTab';

enum TabsEnum {
  General = 'General',
  Deals = 'Deals',
}

interface MessageSelection {
  message: MessageDto;
  selected: boolean;
}
export interface MessageForm {
  messageSelection: MessageSelection[];
  selectAll: boolean;
}

export enum SELECTION_TYPE_ENUM {
  ALL = 'ALL',
  NONE = 'NONE',
  READ = 'READ',
  UNREAD = 'UNREAD',
  MIXED = 'MIXED',
}

const getEnvelopeIcon = (type: SELECTION_TYPE_ENUM): IconDefinition => {
  switch (type) {
    case SELECTION_TYPE_ENUM.READ:
      return faEnvelopeDot;
    case SELECTION_TYPE_ENUM.NONE:
    case SELECTION_TYPE_ENUM.ALL:
    case SELECTION_TYPE_ENUM.UNREAD:
    case SELECTION_TYPE_ENUM.MIXED:
    default:
      return faEnvelopeOpen;
  }
};

const getEnvelopeTooltipText = (type: SELECTION_TYPE_ENUM): string => {
  switch (type) {
    case SELECTION_TYPE_ENUM.NONE:
    case SELECTION_TYPE_ENUM.ALL:
      return 'Mark all as Read';
    case SELECTION_TYPE_ENUM.READ:
      return 'Mark as Unread';
    case SELECTION_TYPE_ENUM.UNREAD:
    case SELECTION_TYPE_ENUM.MIXED:
    default:
      return 'Mark as Read';
  }
};

const BrokerQueueMenu: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const redirectRef = useRef(true);
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<BrokerQueueFilterEnum>(
    BrokerQueueFilterEnum.ALL,
  );
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [selectionType, setSelectionType] = useState<SELECTION_TYPE_ENUM>(
    SELECTION_TYPE_ENUM.NONE,
  );
  const [selectedMessages, setSelectedMessages] = useState<MessageDto[]>();
  const [disableActions, setDisableActions] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const {
    auth: { keymakerCurrentUser, isMortgageUserRole },
    brokerQueue: { nextPage, messages, isLoading, markingReadUnreadLoading },
  } = useSelector((state: RootState) => state);

  const isRoarFullRelease = useFeatureFlag(
    FeatureFlagTypeEnum.ROAR_FULL_RELEASE_BOLT,
  );

  const {
    data: transactionCountRes,
    refetch: refetchTransactionCount,
  } = useFetchInboxCount(
    keymakerCurrentUser?.id!,
    RezenObjectTypeEnum.Transaction,
    {
      enabled: isRoarFullRelease,
      staleTime: 0,
      refetchOnWindowFocus: 'always',
    },
  );

  const {
    data: conversationCountRes,
    refetch: refetchConversationCount,
  } = useFetchInboxCount(
    keymakerCurrentUser?.id!,
    RezenObjectTypeEnum.Conversation,
    {
      enabled: isRoarFullRelease,
      staleTime: 0,
      refetchOnWindowFocus: 'always',
    },
  );

  useEffect(() => {
    // refetch on search or filter change
    // enable transaction refetch when once we migrate to react query
    // refetchTransactionCount();
    refetchConversationCount();
  }, [refetchConversationCount, search, filter]);

  const { control, watch, setValue } = useForm<MessageForm>({
    defaultValues: {
      messageSelection: [],
    },
    mode: 'onChange',
  });
  const { fields, update } = useFieldArray({
    control,
    name: 'messageSelection',
  });

  const [messageSelection, selectAll] = watch([
    'messageSelection',
    'selectAll',
  ]);

  const isConversationActive = location.pathname.startsWith('/conversations/');

  const [tabs, setTabs] = useState<TabsEnum>(
    isConversationActive ? TabsEnum.General : TabsEnum.Deals,
  );

  const isAllOrNoneSelection = useCallback(
    (type?: SELECTION_TYPE_ENUM): boolean =>
      [SELECTION_TYPE_ENUM.ALL, SELECTION_TYPE_ENUM.NONE].includes(
        type || selectionType,
      ),
    [selectionType],
  );

  const markSelectedMessagesReadUnread = useCallback(
    async (read: boolean) => {
      const targetIds = selectedMessages?.map((msg) => msg.target?.id!);
      const action = read
        ? markMultipleBrokerQueueMessageRead
        : markMultipleBrokerQueueMessageUnread;
      await dispatch(
        action(
          keymakerCurrentUser?.id!,
          targetIds!,
          isMortgageUserRole
            ? RezenObjectTypeEnum.Borrower
            : RezenObjectTypeEnum.Transaction,
        ),
      );
      setValue('selectAll', false);
    },
    [
      dispatch,
      isMortgageUserRole,
      keymakerCurrentUser?.id,
      selectedMessages,
      setValue,
    ],
  );

  const markAllMessagesReadUnread = useCallback(
    async (read: boolean) => {
      const action = read
        ? markBrokerQueueMessageAsRead
        : markBrokerQueueMessageAsUnread;
      await dispatch(action(keymakerCurrentUser?.id!));
      setValue('selectAll', false);
    },
    [dispatch, keymakerCurrentUser?.id, setValue],
  );

  const markMessages = useCallback(
    (read: boolean) => {
      if (isAllOrNoneSelection()) {
        markAllMessagesReadUnread(read);
      } else {
        markSelectedMessagesReadUnread(read);
      }
    },
    [
      isAllOrNoneSelection,
      markAllMessagesReadUnread,
      markSelectedMessagesReadUnread,
    ],
  );

  const getSelectedMessages = () =>
    messageSelection
      .filter((mess) => mess.selected && mess.selected)
      .map(({ message }) => message);

  const getSelectionType = (
    selectedMsgs: MessageDto[],
  ): SELECTION_TYPE_ENUM => {
    if (!selectedMsgs.length) {
      return SELECTION_TYPE_ENUM.NONE;
    }
    if (selectedMsgs.length === messages.length) {
      return SELECTION_TYPE_ENUM.ALL;
    }
    if (selectedMsgs.every((msg) => msg.read)) {
      return SELECTION_TYPE_ENUM.READ;
    }
    if (selectedMsgs.every((msg) => !msg.read)) {
      return SELECTION_TYPE_ENUM.UNREAD;
    }
    return SELECTION_TYPE_ENUM.MIXED;
  };

  const handleEnvelopeClick = useCallback(() => {
    markMessages(selectionType !== SELECTION_TYPE_ENUM.READ);
  }, [markMessages, selectionType]);

  const toggleSelect = useCallback(
    (messageIndex: number, message: MessageDto, selected: boolean) => {
      update(messageIndex, { message, selected });
    },
    [update],
  );

  const toggleSelectAll = useCallback(() => {
    setValue(
      'messageSelection',
      messages.map((mess) => ({
        message: mess,
        selected: !selectAll,
      })),
    );
  }, [messages, selectAll, setValue]);

  const setSelectAllCheckboxIndeterminate = (isIndeterminate: boolean) => {
    const selectAllCheckbox = document.getElementById(
      'selectAll',
    ) as HTMLInputElement | null;
    if (selectAllCheckbox) {
      selectAllCheckbox.indeterminate = isIndeterminate;
    }
  };

  const inboxRedirect = useCallback(
    async (message: MessageDto) => {
      if (!isMortgageUserRole) {
        history.replace(
          getBrokerQueuePathName(message?.target?.id!, message?.target?.type!),
        );
        return;
      }

      const response = await dispatch(
        fetchBorrowerDetailsForInbox(message.target?.id!),
      );

      if (response) {
        history.replace(
          getMortgageQueuePathName(
            response?.data?.borrower?.loanId,
            message?.target?.id,
          ),
        );
      }
    },
    [dispatch, history, isMortgageUserRole],
  );

  useEffect(
    () => {
      const selectedMsgs = getSelectedMessages();
      const type = getSelectionType(selectedMsgs);
      setSelectAllCheckboxIndeterminate(!isAllOrNoneSelection(type));
      setValue('selectAll', type !== SELECTION_TYPE_ENUM.NONE);
      setSelectionType(type);
      setSelectedMessages(selectedMsgs);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messageSelection],
  );

  useEffect(() => {
    setDisableActions(!messages.length || markingReadUnreadLoading);
  }, [messages, markingReadUnreadLoading]);

  useEffect(
    () => {
      setValue(
        'messageSelection',
        messages.map((mess) => ({
          message: mess,
          selected: false,
        })),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messages],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getBrokerQueueMessagesDebounced = useCallback(
    debounce(
      (
        refresh: boolean,
        userId: string,
        filter?: BrokerQueueFilterEnum,
        searchText?: string,
        pageStart?: string,
        pageSize?: number,
      ) => {
        refetchTransactionCount();
        dispatch(
          getBrokerQueueMessages(
            refresh,
            userId,
            filter,
            searchText,
            isMortgageUserRole
              ? [RezenObjectTypeEnum.Borrower]
              : [RezenObjectTypeEnum.Transaction],
            pageStart,
            pageSize,
          ),
        );
      },
      500,
    ),
    [],
  );

  useEffect(() => {
    if (keymakerCurrentUser?.id) {
      getBrokerQueueMessagesDebounced(
        true,
        keymakerCurrentUser?.id!,
        filter,
        search,
      );
    } else {
      deleteAuthCookie();
      dispatch(resetApp());
      MenuService.clear();
      SessionStorageService.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filter]);

  useEffect(() => {
    if (!isConversationActive && messages.length && redirectRef.current) {
      redirectRef.current = false;
      inboxRedirect(messages[0]);
    }
  }, [messages, inboxRedirect, isConversationActive]);

  const inboxTabs = useMemo(() => {
    return [
      {
        value: TabsEnum.Deals,
        label: `${TabsEnum.Deals} (${
          transactionCountRes?.unreadMessageCount ?? 0
        })`,
      },
      {
        value: TabsEnum.General,
        label: `${TabsEnum.General} (${
          conversationCountRes?.unreadMessageCount ?? 0
        })`,
      },
    ];
  }, [
    conversationCountRes?.unreadMessageCount,
    transactionCountRes?.unreadMessageCount,
  ]);

  return (
    <div
      className={cn(
        'relative border-r border-gray-200 shadow-lg text-primary-dark max-h-screen w-full lg:w-56 z-50',
        isRoarFullRelease && 'flex-shrink-0 lg:w-full lg:max-w-[350px]',
      )}
    >
      <div className='w-full min-w-max flex items-center border-b border-gray-200 bg-gray-100 py-2 px-4'>
        <FontAwesomeIcon
          icon={faBars}
          className='text-primary-dark mr-5 lg:hidden'
          onClick={() => setCollapsed(!collapsed)}
        />
        <Link to='/'>
          <img src={RealLogoImg} className='w-18' alt='Real Logo' />
        </Link>
        <div className='w-1 h-6 border-l border-grey-500 mx-5' />
        <p className='font-primary-medium text-2xl mr-4 lg:whitespace-nowrap'>
          Inbox
        </p>
      </div>
      <div
        className={cn('fixed inset-0 lg:static z-10', {
          'hidden lg:block': collapsed,
        })}
      >
        <div
          className='absolute inset-0 bg-black bg-opacity-30 lg:hidden'
          role='button'
          onClick={() => setCollapsed(true)}
        />
        <div className='fixed w-3/4 md:w-1/2 lg:w-full inset-y-0 lg:static bg-primary-light z-20 animate-slide-in-left lg:animate-none'>
          <FeatureFlagEnabledOnly
            flag={FeatureFlagTypeEnum.ROAR_FULL_RELEASE_BOLT}
          >
            <div className='flex items-stretch space-x-2 p-3'>
              <GeminiSearchBar
                onChange={setSearch}
                value={search}
                placeholder='Search'
              />
              <BrokerQueueFilterV2 filter={filter} onFilterChange={setFilter} />
            </div>
          </FeatureFlagEnabledOnly>
          <FeatureFlagEnabledOnly
            flag={FeatureFlagTypeEnum.ROAR_FULL_RELEASE_BOLT}
          >
            <div className='mb-3 px-2'>
              <SegmentedControl
                data={inboxTabs}
                value={tabs}
                onChange={(tab) => setTabs(tab as TabsEnum)}
                fullWidth
              />
            </div>
            {tabs === TabsEnum.Deals && (
              <div className='h-full'>
                <div className='px-3 pb-0'>
                  <div className='flex items-center justify-between border-b border-grey-200 pb-3'>
                    <div className='flex-grow flex items-center pl-2'>
                      <ZenCheckbox
                        variant='square'
                        ringVariant='none'
                        borderVariant='dark'
                        value={selectAll}
                        onChange={toggleSelectAll}
                        name='selectAll'
                        disabled={disableActions}
                      />
                    </div>
                    <div className='mr-3 flex items-center justify-center'>
                      <Hover
                        hoverComponent={
                          <p className='p-1 text-sm text-grey-500 font-zen-body'>
                            {getEnvelopeTooltipText(selectionType)}
                          </p>
                        }
                        config={{ placement: 'top' }}
                      >
                        <button
                          className='appearance-none h-fit'
                          onClick={handleEnvelopeClick}
                          aria-label='smart-envelope'
                          disabled={disableActions}
                        >
                          <FontAwesomeIcon
                            icon={getEnvelopeIcon(selectionType)}
                            className={cn(
                              'text-base',
                              disableActions ? 'text-gray-200' : 'text-dark',
                            )}
                          />
                        </button>
                      </Hover>
                    </div>
                    <BrokerQueueTransactionOptions
                      onMark={markMessages}
                      allOrNoneSelected={isAllOrNoneSelection()}
                      disabled={disableActions}
                    />
                  </div>
                </div>
                <div
                  className='h-[calc(100vh-121px)] md:h-[calc(100vh-170px)] scrollbar overflow-y-auto'
                  id='scrollableDiv'
                >
                  <ResourceContainer
                    loading={isLoading}
                    EmptyComponent={<BrokerQueueMenuEmpty />}
                    isEmpty={!messages?.length}
                    resourceName='message'
                  >
                    <InfiniteScroll
                      hasMore={!!nextPage}
                      loader={<DefaultLoader />}
                      dataLength={messages?.length || 0}
                      scrollableTarget='scrollableDiv'
                      next={() =>
                        getBrokerQueueMessagesDebounced(
                          false,
                          keymakerCurrentUser?.id!,
                          filter,
                          search,
                          nextPage,
                        )
                      }
                    >
                      {fields?.map(({ message: msg, selected }, ind) => (
                        <BrokerQueueTransaction
                          key={msg.messageId}
                          message={msg}
                          onClick={async () => {
                            redirectRef.current = false;
                            await inboxRedirect(msg);

                            if (markingReadUnreadLoading) {
                              return;
                            }
                            setCollapsed(true);

                            if (!msg.read) {
                              dispatch(
                                markBrokerQueueMessageAsRead(
                                  keymakerCurrentUser?.id!,
                                  msg.target?.type!,
                                  msg.target?.id!,
                                ),
                              );
                            }
                          }}
                          selected={selected}
                          onChange={() => toggleSelect(ind, msg, !selected)}
                        />
                      ))}
                    </InfiniteScroll>
                  </ResourceContainer>
                </div>
              </div>
            )}
            {tabs === TabsEnum.General && (
              <ConversationTab search={search} filter={filter} />
            )}
          </FeatureFlagEnabledOnly>
        </div>
      </div>
    </div>
  );
};

export default BrokerQueueMenu;
