import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneArrowUpRight } from '@fortawesome/pro-light-svg-icons';
import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { useEffect, useState } from 'react';
import CallModalForm from '../../Roar/CallModalForm';
import {
  CreateConversation,
  CreateVoiceCallRequestContainerTypeEnum,
  CreateVoiceCallRequestTargetTypeEnum,
  MemberIdTypeEnum,
} from '../../../openapi/yada';
import { Modal } from '../../commons/Modal';
import { cn } from '../../../utils/classUtils';
import { useCreateConversation } from '../../../query/roar/useRoar';
import { NeoLeoSearchCallItem } from '../Hooks/useNeoLeoKBarActions';

type NeoLeoRoarModalProps = {
  opened: boolean;
  close(): void;
  data: NeoLeoSearchCallItem;
};

export const NeoLeoRoarModal = ({
  data,
  opened,
  close,
}: NeoLeoRoarModalProps) => {
  const [containerId, setContainerId] = useState<string>();
  const { mutateAsync: createConversation } = useCreateConversation();

  useEffect(() => {
    if (opened && !data.container_id) {
      const item: CreateConversation = {
        members: [
          {
            type: MemberIdTypeEnum.User,
            value: data.agent_id,
          },
          {
            type: MemberIdTypeEnum.Group,
            value: data.office_group_id,
          },
        ],
      };
      createConversation(item).then(({ id }) => setContainerId(id));
    }
    if (opened && data.container_id) {
      setContainerId(data.container_id);
    }
    if (!opened) {
      setContainerId(undefined);
    }
  }, [createConversation, data, opened]);

  return (
    <Modal
      size='585px'
      opened={opened}
      onClose={close}
      aria-label='agent-call-modal'
    >
      <Modal.Header
        color='white'
        className='absolute w-full bg-transparent z-10'
      />
      <Modal.Content className={cn('pt-8 px-8')}>
        <div className='w-full space-y-1 flex items-center flex-col'>
          <div
            className={cn(
              'relative',
              'bg-[#DBE9FF] w-10 h-10 rounded-full flex items-center justify-center',
            )}
          >
            <FontAwesomeIcon
              icon={faPhoneArrowUpRight}
              className='p-1 border absolute -right-1 -top-1 bg-white text-rezen-blue-600 rounded-full'
              fontSize={9}
            />
            <FontAwesomeIcon
              icon={faUsers}
              size='sm'
              className='text-rezen-blue-600'
            />
          </div>
          <div className='text-grey-600 text-sm font-normal'>Broker Team</div>
        </div>
        {opened && containerId && (
          <CallModalForm
            voiceCallPayload={{
              containerId: data.container_id ?? containerId,
              containerType: data.container_type as CreateVoiceCallRequestContainerTypeEnum,
              targetId: data.office_group_id,
              targetType: CreateVoiceCallRequestTargetTypeEnum.Group,
            }}
            phoneNumber=''
          />
        )}
      </Modal.Content>
    </Modal>
  );
};
