import { faSquareExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ZenDefaultErrorProps {
  message: string;
}

const ZenDefaultError: React.FC<ZenDefaultErrorProps> = ({ message }) => {
  return (
    <div className='py-4 px-6 grid grid-flow-col items-center bg-zen-danger-light rounded-lg'>
      <FontAwesomeIcon
        icon={faSquareExclamation}
        className='text-error col-span-1 text-xl'
      />
      <p className='font-zen-body font-medium text-error pl-4 col-span-11'>
        {message}
      </p>
    </div>
  );
};

export default ZenDefaultError;
