import { Redirect } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WorkvivoControllerApi } from '../../openapi/yenta';
import { AppDispatch, RootState } from '../../types';
import useQueryParams from '../../hooks/useQueryParams';
import DefaultLoader from '../DefaultLoader';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import ErrorService from '../../services/ErrorService';

interface Query {
  action: string;
  redirectUrl: string;
}

const WorkVivoSsoRedirect: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    auth: { userDetail },
  } = useSelector((state: RootState) => state);

  let { action, redirectUrl } = useQueryParams<Query>();
  if (!action) {
    action = 'login';
  }

  if (!redirectUrl) {
    redirectUrl = '/';
  }

  useEffect(() => {
    const redirectToWorkVivo = async () => {
      try {
        const {
          data: { jwt },
        } = await new WorkvivoControllerApi(
          getYentaConfiguration(),
        ).generateJwt(userDetail?.id!);

        if (jwt) {
          window.location.assign(
            `https://onereal.workvivo.us/proxy/redirect/sso/${jwt}`,
          );
        }
      } catch (error) {
        ErrorService.notify('Error generating workvivo jwt:', error);
      }
    };

    if (userDetail) {
      redirectToWorkVivo();
    }
  }, [dispatch, redirectUrl, userDetail]);

  if (!userDetail) {
    let url = '/login';
    const query = {
      redirectTo:
        '/workvivo/sso?redirectUrl=' +
        encodeURIComponent(redirectUrl) +
        '&action=' +
        action,
    };
    const params = new URLSearchParams(query);
    url += '?' + params.toString();

    return <Redirect to={url} />;
  }

  return <DefaultLoader />;
};

export default WorkVivoSsoRedirect;
