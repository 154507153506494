import { NeoLeoSearchResult } from '../../components/NeoLeo/Hooks/useNeoLeoKBarActions';
import { DEFAULT_CHAT_PROMPTS } from '../../constants/LeoConstants';
import {
  ChatControllerApi,
  PromptLibraryRequest,
  SearchApi,
} from '../../openapi/leo';
import { ContextualPrompt } from '../../utils/LeoUtils';
import { getLeoConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { queryKeys } from '../base/queryKeys';
import { QueryOptions, useSimpleQuery } from '../base/useSimpleQuery';

export const useLeoTrigger = (
  promptReq: PromptLibraryRequest,
  options?: QueryOptions<ContextualPrompt[]>,
) => {
  return useSimpleQuery({
    queryKey: queryKeys.leoPrompts.list(promptReq).queryKey,
    queryFn: async () => {
      const { data } = await new ChatControllerApi(
        await getLeoConfiguration(),
      ).getPromptSuggestionsChatPromptLibraryPost(promptReq);
      return data.items?.length ? data.items : DEFAULT_CHAT_PROMPTS;
    },
    options: {
      logErrorMessage: '[LEO] - Failed to fetch leo prompts.',
      toastErrorMessage: 'Failed to fetch leo prompts.',
      ...options,
    },
  });
};

export const useSearch = (
  source: string | undefined,
  page: number,
  limit: number,
) => {
  return useSimpleQuery<NeoLeoSearchResult[]>({
    queryKey: queryKeys.leo.search(source, limit, page).queryKey,
    queryFn: async () => {
      const response = await new SearchApi(
        getLeoConfiguration(),
      ).searchSearchGet(undefined, source, page, limit);
      return response?.data.results ?? [];
    },
    options: {
      logErrorMessage: '[LEO] - Error fetching search results',
      toastErrorMessage: 'Error fetching search results',
    },
  });
};
