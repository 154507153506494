import Fuse from 'fuse.js';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSearch } from '../../../query/leo/useLeo';

const LIMIT = 100;
const PAGE = 1;
const ACTION_ITEMS_LIMIT = 5;

export enum NeoLeoSearchCategoryEnum {
  ExternalLinks = 'EXTERNAL_LINKS',
  InternalPages = 'INTERNAL_PAGES',
  PromptSuggestions = 'PROMPT_SUGGESTIONS',
  Deposits = 'DEPOSITS',
  Roar = 'ROAR',
}

export interface NeoLeoSearchResult {
  category: NeoLeoSearchCategoryEnum;
  category_title: string;
  items: NeoLeoSearchItem[];
}

export interface NeoLeoSearchItem {
  title?: string;
  description?: string;
  url?: string;
  prompt?: string;
  action_type?: string;
}

export interface NeoLeoSearchCallItem extends NeoLeoSearchItem {
  container_id?: string | null;
  container_type: 'CONVERSATION' | 'TRANSACTION';
  agent_id: string;
  office_group_id: string;
  target_type: string;
  office_group_name: string;
}

const useNeoLeoKBarActions = (askLeoText?: string): NeoLeoSearchResult[] => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [filteredActions, setFilteredActions] = useState<NeoLeoSearchResult[]>(
    [],
  );

  const source = useMemo(() => {
    if (!id) return;

    const isTransactionDetailRoute = location.pathname.startsWith(
      `/transactions/${id}`,
    );

    if (isTransactionDetailRoute) {
      return `transactions/${id}`;
    }

    return undefined;
  }, [id, location.pathname]);

  const { data: actions } = useSearch(source, PAGE, LIMIT);

  const getFilteredActions = useCallback(
    (searchTerm?: string) => {
      if (!actions?.length) return;

      const fuseOptions = {
        keys: ['title'], // Searching based on 'title' field
        threshold: 0.3, // Adjust this value for fuzziness (lower is stricter)
        distance: 100, // Adjust this value for fuzziness (higher is stricter)
      };

      const newFilteredActions = actions
        .map((category) => {
          const fuse = new Fuse(category.items, fuseOptions);

          const filteredItems = fuse
            .search(searchTerm || '')
            .map((result) => result.item);

          if (filteredItems.length > 0) {
            return {
              ...category,
              items: filteredItems.slice(0, ACTION_ITEMS_LIMIT),
            };
          }

          return null;
        })
        .filter(Boolean) as NeoLeoSearchResult[];

      if (!isEqual(newFilteredActions, filteredActions)) {
        setFilteredActions(newFilteredActions);
      }
    },
    [actions, filteredActions],
  );

  useEffect(() => {
    if (askLeoText) {
      getFilteredActions(askLeoText);
    }
  }, [askLeoText, getFilteredActions]);

  return filteredActions;
};

export default useNeoLeoKBarActions;
