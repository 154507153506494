import ZenSideBarItem from '../ZenSideBarItem';
import WorkVivoLogo from '../../assets/img/workvivo.svg';

interface WorkVivoSidebarMenuItemProps {
  isCollapsed: boolean;
}

const WorkVivoSidebarMenuItem: React.FC<WorkVivoSidebarMenuItemProps> = ({
  isCollapsed,
}) => {
  return (
    <ZenSideBarItem
      icon={<img src={WorkVivoLogo} className='h-7' alt='Logo' />}
      label='Workvivo'
      target='_blank'
      linkTo='/workvivo/sso'
      collapsed={isCollapsed}
    />
  );
};

export default WorkVivoSidebarMenuItem;
