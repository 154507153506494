import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useDidUpdateEffect from '../../../../hooks/useDidUpdateEffect';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import {
  PriceAndDateInfoRequest,
  PriceAndDateInfoRequestDealTypeEnum,
  PriceAndDateInfoRequestPropertyTypeEnum,
  PriceAndDateInfoRequestRepresentationTypeEnum,
  TransactionResponseTransactionTypeEnum,
} from '../../../../openapi/arrakis';
import {
  AddressRequestCountryEnum,
  MoneyValueCurrencyEnum,
} from '../../../../openapi/yenta';
import {
  getTransactionBuilderFeatures,
  savePriceDateInfos,
} from '../../../../slices/TransactionBuilderSlice';
import {
  AppDispatch,
  FeatureFlagTypeEnum,
  ISelectOption,
  RootState,
} from '../../../../types';
import {
  isAmountValid,
  numberWithCommas,
} from '../../../../utils/CurrencyUtils';
import {
  getLeaseTransactionTypeOptions,
  getSaleTransactionTypeOptions,
  getTransactionDealTypes,
  isAlbertaTransaction,
} from '../../../../utils/TransactionBuilderHelper';
import {
  FilteredPriceAndDateInfoRequestPropertyTypeEnum,
  getEnumOptions,
  getTransactionCountry,
  getTransactionPriceLabel,
  isLeaseOptionVisible,
  isSaleTransaction,
} from '../../../../utils/TransactionHelper';
import { isListingCommissionRequiredForRole } from '../../../../utils/TransactionUtils';
import {
  MONEY_VALIDATIONS,
  PERCENT_VALIDATION,
  getRangeMoneyValueValidationRules,
} from '../../../../utils/Validations';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenControlledCurrencyInput from '../../Input/ZenControlledCurrencyInput';
import ZenControlledDatePickerInput from '../../Input/ZenControlledDatePickerInput';
import ZenControlledFormattedMoneyInput from '../../Input/ZenControlledFormattedMoneyInput';
import ZenControlledPercentageInput from '../../Input/ZenControlledPercentageInput';
import ZenControlledRadioInput from '../../Input/ZenControlledRadioInput';
import ZenControlledToggleInput from '../../Input/ZenControlledToggleInput';
import ZenConfirmationModal from '../../Modal/ZenConfirmationModal';
import ZenButton from '../../ZenButton';
import ZenControlledSelectInput from '../../Input/ZenControlledSelectInput';
import {
  CreateTransactionFormState,
  CreateTransactionStepName,
  Match,
} from './ZenCreateTransactionSteps';
import withCreateTransactionProgress from './ZenwithCreateTransactionProgress';

export const ROLES_REQUIRE_CREATE_LISTING = [
  PriceAndDateInfoRequestRepresentationTypeEnum.Dual,
  PriceAndDateInfoRequestRepresentationTypeEnum.Seller,
  PriceAndDateInfoRequestRepresentationTypeEnum.Landlord,
];

const ZenTransactionTypeAndPriceStep: StepByStepComponent<
  CreateTransactionFormState,
  CreateTransactionStepName
> = ({
  form: {
    control,
    watch,
    trigger,
    setValue,
    formState: { errors, isSubmitting },
  },
  onPrevious,
  onNext,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { transactionBuilderId } = useParams<Match>();
  const {
    auth: { userDetail },
    transactionBuilder: { transactionBuilder },
  } = useSelector((state: RootState) => state);
  const [loading, setLoading] = useState<boolean>(false);
  const [
    isSalesCommissionDollar,
    isListingCommissionDollar,
    transactionType,
    closingDate,
    acceptanceDate,
    firmDate,
    listingCommission,
    commission,
    price,
    transactionOwnerRepresent,
    address,
    location,
    propertyType,
  ] = watch([
    'commission.isDollar',
    'listingCommission.isDollar',
    'transactionType',
    'closingDate',
    'acceptanceDate',
    'firmDate',
    'listingCommission',
    'commission',
    'price',
    'transactionOwnerRepresent',
    'address',
    'location',
    'propertyType',
  ]);
  const isCanadaTransaction =
    getTransactionCountry(address, location) ===
    AddressRequestCountryEnum.Canada;

  const getAmountOrPercentage = (percent: any, amt: any, isDollar: boolean) => {
    const percentage = watch(percent) || 0;
    const price = watch('price.amount') || 0;
    const amount = watch(amt) || 0;
    if (!isDollar)
      return numberWithCommas(((percentage * price) / 100).toFixed(2));
    return numberWithCommas(Math.abs(amount).toFixed(2));
  };

  const isListingCommissionRequired = isListingCommissionRequiredForRole(
    // @ts-ignore: Unreachable code error
    transactionOwnerRepresent,
  );

  const [showListingModal, setShowListingModal] = useState<boolean>(false);
  const history = useHistory();

  const createListingBeforeTransaction =
    !transactionBuilder?.builtFromTransactionId &&
    ROLES_REQUIRE_CREATE_LISTING.includes(transactionOwnerRepresent);

  const handleTypeAndPriceStep = async () => {
    if (createListingBeforeTransaction) {
      setShowListingModal(true);
    } else {
      const isValid = await trigger();
      if (isValid) {
        const priceDateRequest: PriceAndDateInfoRequest = {
          dealType: (transactionType as unknown) as PriceAndDateInfoRequestDealTypeEnum,
          closingDate: (closingDate as unknown) as string,
          acceptanceDate: (acceptanceDate as unknown) as string,
          listingCommission: {
            commissionAmount: isListingCommissionDollar
              ? {
                  amount: +listingCommission.money?.amount! || 0,
                  currency: price.currency,
                }
              : undefined,
            commissionPercent: !isListingCommissionDollar
              ? listingCommission.percent?.value!
              : undefined,
            percentEnabled: !listingCommission.isDollar,
          },
          saleCommission: {
            commissionAmount: isSalesCommissionDollar
              ? {
                  amount: +commission.money?.amount! || 0,
                  currency: price.currency,
                }
              : undefined,
            commissionPercent: !isSalesCommissionDollar
              ? commission.percent?.value!
              : undefined,
            percentEnabled: !commission.isDollar,
          },
          salePrice: {
            amount: +price?.amount! || 0,
            currency: price?.currency,
          },
          representationType: transactionOwnerRepresent,
          ...(isCanadaTransaction && {
            firmDate: (firmDate as unknown) as string,
          }),
          propertyType: propertyType?.value as PriceAndDateInfoRequestPropertyTypeEnum,
        };
        setLoading(true);
        const res = await dispatch(
          savePriceDateInfos(transactionBuilderId!, priceDateRequest),
        );
        await dispatch(getTransactionBuilderFeatures(transactionBuilderId));
        setLoading(false);
        if (res) {
          setValue('zeroCommissionDeal', !!res.zeroCommissionDeal);
          onNext();
        }
      }
    }
  };

  const isChecklistDynamicsPropertyTypesEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.CHECKLIST_DYNAMICS_PROPERTY_TYPES,
  );

  const representationType: ISelectOption[] = isSaleTransaction(transactionType)
    ? getSaleTransactionTypeOptions(transactionBuilder?.builtFromTransactionId!)
    : getLeaseTransactionTypeOptions(
        transactionBuilder?.builtFromTransactionId!,
      );

  const {
    listingCommission: listingError,
    commission: saleCommissionError,
  } = errors;

  useEffect(() => {
    setValue('price', {
      amount: transactionBuilder?.salePrice?.amount!,
      currency: isCanadaTransaction
        ? MoneyValueCurrencyEnum.Cad
        : MoneyValueCurrencyEnum.Usd,
    });
  }, [setValue, transactionBuilder?.salePrice?.amount, isCanadaTransaction]);

  const isLeaseAllowed = isLeaseOptionVisible(
    userDetail?.offices!,
    transactionBuilder?.address?.state!,
  );

  useEffect(() => {
    if (!isLeaseAllowed) {
      setValue('transactionType', TransactionResponseTransactionTypeEnum.Sale);
    }
  }, [setValue, isLeaseAllowed]);

  useEffect(() => {
    if (isAlbertaTransaction(transactionBuilder?.address?.state)) {
      setValue('transactionType', TransactionResponseTransactionTypeEnum.Sale);
    }
  }, [setValue, transactionBuilder?.address?.state]);

  // Reset representation type when transaction type changes
  useDidUpdateEffect(() => {
    setValue(
      'transactionOwnerRepresent',
      '' as PriceAndDateInfoRequestRepresentationTypeEnum,
    );
  }, [transactionType]);

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <div>
          <p className='text-xl font-zen-title font-medium text-zen-dark-9 mb-4'>
            Transaction Type, Price & Commission
          </p>
          <div className='space-y-4 mb-5'>
            <div className='flex flex-row items-start'>
              <div className='flex-1'>
                <ZenControlledRadioInput<
                  CreateTransactionFormState,
                  'transactionType'
                >
                  name='transactionType'
                  control={control}
                  label='Deal Type'
                  shouldUnregister={false}
                  options={getTransactionDealTypes(
                    transactionBuilder,
                    isLeaseAllowed,
                  )}
                  rules={{
                    required: 'Please select a deal type',
                  }}
                  inlineOptions
                  isRequired
                />
              </div>
              <div className='flex-0'>
                <ZenControlledFormattedMoneyInput<
                  CreateTransactionFormState,
                  'price'
                >
                  control={control}
                  label={getTransactionPriceLabel(transactionType)}
                  name='price'
                  shouldUnregister={false}
                  placeholder='E.g. 1,744,000'
                  rules={{
                    ...getRangeMoneyValueValidationRules({ min: 1 }),
                  }}
                  isRequired
                  currencyReadOnly
                />
              </div>
            </div>
            {isChecklistDynamicsPropertyTypesEnabled && (
              <div className='mt-5'>
                <ZenControlledSelectInput<
                  CreateTransactionFormState,
                  'propertyType'
                >
                  control={control}
                  customClassName='w-full'
                  label='Property Type'
                  name='propertyType'
                  options={getEnumOptions(
                    FilteredPriceAndDateInfoRequestPropertyTypeEnum,
                  )}
                  shouldUnregister={false}
                  rules={{
                    required: 'Please select property type',
                  }}
                  isRequired
                />
              </div>
            )}
            <div className='mt-5'>
              <ZenControlledRadioInput<
                CreateTransactionFormState,
                'transactionOwnerRepresent'
              >
                control={control}
                label='Representation type'
                name='transactionOwnerRepresent'
                inlineOptions
                options={transactionType ? representationType : []}
                shouldUnregister={false}
                rules={{
                  required: 'Please select representation type',
                }}
                isRequired
              />
            </div>
            <div className='md:w-1/2 mt-5'>
              <p className='mb-1'>
                <span className='font-zen-body font-semibold text-base text-zen-dark-9'>
                  Listing Commission
                </span>
                {isListingCommissionRequired && (
                  <span className='text-zen-danger'>*</span>
                )}
              </p>
              <div className='flex flex-row items-center space-x-4'>
                <div>
                  <ZenControlledToggleInput<
                    CreateTransactionFormState,
                    'listingCommission.isDollar'
                  >
                    label=''
                    name='listingCommission.isDollar'
                    control={control}
                    shouldUnregister={false}
                    defaultValue={false}
                    rightIcon='$'
                    leftIcon='%'
                    hideErrorMessage
                  />
                </div>
                <div className='flex-grow'>
                  {isListingCommissionDollar ? (
                    <ZenControlledCurrencyInput<
                      CreateTransactionFormState,
                      'listingCommission.money.amount'
                    >
                      control={control}
                      name='listingCommission.money.amount'
                      shouldUnregister={false}
                      defaultValue={0}
                      placeholder='Amount'
                      startAdornment={
                        <div className='font-zen-body text-base font-medium text-primary-blue h-full w-8 flex items-center justify-center'>
                          $
                        </div>
                      }
                      rules={{
                        required:
                          isListingCommissionRequired &&
                          isListingCommissionDollar
                            ? 'Listing commission is required'
                            : undefined,
                        ...MONEY_VALIDATIONS,
                        validate: (val) => {
                          if (
                            isListingCommissionDollar &&
                            !!val &&
                            !isAmountValid(val, price?.amount)
                          ) {
                            return 'Please enter valid amount';
                          }
                          return undefined;
                        },
                      }}
                      hideErrorMessage
                    />
                  ) : (
                    <ZenControlledPercentageInput<
                      CreateTransactionFormState,
                      'listingCommission.percent.value'
                    >
                      control={control}
                      name='listingCommission.percent.value'
                      shouldUnregister={false}
                      placeholder='Percentage'
                      startAdornment={
                        <div className='font-zen-body text-base font-medium text-primary-blue h-full w-8 flex items-center justify-center'>
                          %
                        </div>
                      }
                      rules={{
                        required:
                          isListingCommissionRequired &&
                          !isListingCommissionDollar
                            ? 'Listing Commission is Required'
                            : undefined,
                        pattern: !isListingCommissionDollar
                          ? { ...PERCENT_VALIDATION?.pattern! }
                          : undefined,
                      }}
                      hideErrorMessage
                    />
                  )}
                </div>
                <div className='mt-1 flex ml-4 flex-shrink'>
                  <p className='font-zen-body font-semibold text-base text-zen-dark-9'>
                    $
                    <span className='ml-2'>
                      {getAmountOrPercentage(
                        'listingCommission.percent.value',
                        'listingCommission.money.amount',
                        isListingCommissionDollar!,
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <p className='font-zen-body text-zen-danger text-sm pt-1'>
                {!isListingCommissionDollar
                  ? listingError?.percent?.value?.message
                  : listingError?.money?.amount?.message}
              </p>
            </div>
            <div className='md:w-1/2 mt-5'>
              <p className='mb-1'>
                <span className='font-zen-body font-semibold text-base text-zen-dark-9'>
                  Sale Commission
                </span>
                <span className='text-zen-danger'>*</span>
              </p>
              <div className='flex flex-row items-center space-x-4'>
                <div>
                  <ZenControlledToggleInput<
                    CreateTransactionFormState,
                    'commission.isDollar'
                  >
                    label=''
                    name='commission.isDollar'
                    control={control}
                    shouldUnregister={false}
                    defaultValue={false}
                    rightIcon='$'
                    leftIcon='%'
                    hideErrorMessage
                  />
                </div>
                <div className='flex-grow'>
                  {isSalesCommissionDollar ? (
                    <ZenControlledCurrencyInput<
                      CreateTransactionFormState,
                      'commission.money.amount'
                    >
                      control={control}
                      name='commission.money.amount'
                      shouldUnregister={false}
                      defaultValue={0}
                      placeholder='Amount'
                      startAdornment={
                        <div className='font-zen-body text-base font-medium text-primary-blue h-full w-8 flex items-center justify-center'>
                          $
                        </div>
                      }
                      rules={{
                        required: isSalesCommissionDollar
                          ? 'Sale commission is required'
                          : undefined,
                        ...MONEY_VALIDATIONS,
                        validate: (val) => {
                          if (
                            isSalesCommissionDollar &&
                            !!val &&
                            !isAmountValid(val, price?.amount)
                          ) {
                            return 'Please enter valid amount';
                          }
                          return undefined;
                        },
                      }}
                      hideErrorMessage
                    />
                  ) : (
                    <ZenControlledPercentageInput<
                      CreateTransactionFormState,
                      'commission.percent.value'
                    >
                      control={control}
                      name='commission.percent.value'
                      shouldUnregister={false}
                      placeholder='Percentage'
                      startAdornment={
                        <div className='font-zen-body text-base font-medium text-primary-blue h-full w-8 flex items-center justify-center'>
                          %
                        </div>
                      }
                      rules={{
                        required: !isSalesCommissionDollar
                          ? 'Sale commission is required'
                          : undefined,
                        pattern: !isSalesCommissionDollar
                          ? { ...PERCENT_VALIDATION?.pattern! }
                          : undefined,
                      }}
                      hideErrorMessage
                    />
                  )}
                </div>
                <div className='mt-1 flex ml-4'>
                  <p className='font-zen-body font-semibold text-base text-zen-dark-9'>
                    $
                    <span className='ml-2'>
                      {getAmountOrPercentage(
                        'commission.percent.value',
                        'commission.money.amount',
                        isSalesCommissionDollar!,
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <p className='font-zen-body text-zen-danger text-sm pt-1'>
                {!isSalesCommissionDollar
                  ? saleCommissionError?.percent?.value?.message
                  : saleCommissionError?.money?.amount?.message}
              </p>
            </div>
          </div>
        </div>
        <div className='my-12'>
          <p className='text-xl font-zen-title font-medium text-zen-dark-9 mb-4'>
            Key Dates
          </p>
          <div className='flex flex-row space-x-4'>
            <ZenControlledDatePickerInput<
              CreateTransactionFormState,
              'acceptanceDate'
            >
              control={control}
              label='Acceptance Date'
              name='acceptanceDate'
              shouldUnregister={false}
              placeholder='MM/DD/YYYY'
              datePickerConfig={{
                maxDate: DateTime.local().toJSDate(),
              }}
              icon={
                <FontAwesomeIcon
                  icon={faCalendar}
                  title='calendar'
                  className='text-primary-blue pt-1 text-xl'
                />
              }
              rules={{
                required: 'Acceptance Date is Required',
              }}
              isRequired
            />
            <ZenControlledDatePickerInput<
              CreateTransactionFormState,
              'closingDate'
            >
              control={control}
              label='Closing Date (estimated)'
              name='closingDate'
              shouldUnregister={false}
              placeholder='MM/DD/YYYY'
              icon={
                <FontAwesomeIcon
                  icon={faCalendar}
                  title='calendar'
                  className='text-primary-blue pt-1 text-xl'
                />
              }
              rules={{
                required: 'Closing Date is Required',
              }}
              isRequired
            />
          </div>
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-2xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-light-outline'
              label='Previous'
              onClick={onPrevious}
            />
            <ZenButton
              isFullWidth
              isSubmitting={loading}
              isDisabled={loading}
              label='Next'
              onClick={handleTypeAndPriceStep}
            />
          </div>
        </div>
      </div>

      <ZenConfirmationModal
        isOpen={showListingModal}
        onClose={() => setShowListingModal(false)}
        confirmButtonText='Go to My Listings'
        isSubmitting={isSubmitting}
        onConfirm={() => history.push('/listings')}
        size='large'
        variant='primary'
        title='You must create a listing first'
        subtitle=' If you are representing the Seller / Landlord, you must create a listing first and mark that listing as in contract.'
      />
    </div>
  );
};

export default withCreateTransactionProgress(ZenTransactionTypeAndPriceStep);
