export const REPAYMENT_ADDRESS = [
  {
    region: 'Ontario, Canada',
    address: '130 King Street West Suite 1900 Toronto Ontario M5X 1E3',
  },
  {
    region: 'British Columbia, Canada',
    address: '666 Burrard St. Suite 500 Vancouver BC V6C3P6',
  },
  {
    region: 'Manitoba, Canada',
    address: '330 St. Mary Avenue Suite 300 Winnipeg Manitoba R3C3Z5',
  },
  {
    region: 'Alberta, Canada',
    address: '700-1816 Crowchild Trail NW Calgary Alberta T2M 3Y7',
  },
];
