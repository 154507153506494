import { faDollarSign, faPlus } from '@fortawesome/pro-regular-svg-icons';
import {
  faCircleCheck,
  faCircleXmark,
  faSquareInfo,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import {
  MoneyValue,
  MoneyValueCurrencyEnum,
  RealLendingAccountLiteDtoTypeEnum,
} from '../../../openapi/wallet';
import {
  CASH_ADVANCE_ERROR_MESSAGE,
  useCreateCashAdvance,
  useWallet,
} from '../../../query/wallet/useWallet';
import { RootState } from '../../../types';
import { isCanadianUser } from '../../../utils/AgentHelper';
import { cn } from '../../../utils/classUtils';
import { formatMoneyValue } from '../../../utils/CurrencyUtils';
import { getApiErrorMessage } from '../../../utils/ErrorUtils';
import { MONEY_AMOUNT_REGEX } from '../../../utils/StringUtils';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenButton from '../../Zen/ZenButton';

interface FormData {
  amount: number;
}

interface WalletRequestFundsModalProps {
  isOpen: boolean;
  onClose(): void;
  availableCredit?: MoneyValue;
  onRefresh?: () => void;
}

const WalletRequestFundsModal: React.FC<WalletRequestFundsModalProps> = ({
  isOpen,
  onClose,
  availableCredit,
  onRefresh,
}) => {
  const accountCountry = useSelector(
    (state: RootState) => state.auth.userDetail?.accountCountry,
  );
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>();

  const { data: wallet } = useWallet();

  const {
    mutate: requestFund,
    isLoading,
    isError,
    error,
    reset,
  } = useCreateCashAdvance();

  const { control, handleSubmit, setValue, watch, trigger } = useForm<FormData>(
    {
      defaultValues: {
        amount: 0,
      },
    },
  );

  const { errors } = useFormState({ control });
  const currentAmount = watch('amount');

  const lendingAccount = useMemo(() => {
    return wallet?.realLendingAccounts?.find(
      (acc) => acc.type === RealLendingAccountLiteDtoTypeEnum.RealLending,
    );
  }, [wallet?.realLendingAccounts]);

  const accountId = lendingAccount?.id;
  const creditLeft = availableCredit ?? lendingAccount?.offeredAmount;

  const handleConfirmTransaction = async () => {
    if (accountId) {
      requestFund(
        {
          lendingAccountId: accountId,
          amount: {
            amount: currentAmount,
            currency: isCanadianUser(accountCountry!)
              ? MoneyValueCurrencyEnum.Cad
              : MoneyValueCurrencyEnum.Usd,
          },
        },
        {
          onSuccess: () => {
            setShowSuccessMessage(true);
            onRefresh && onRefresh();
          },
        },
      );
    }
  };

  const handleAddToAmount = (amount: number) => {
    setValue('amount', Number(currentAmount || 0) + amount, {
      shouldValidate: true,
    });
    reset();
  };

  const SuccessModal = () => (
    <div className='flex flex-col items-center px-6 pt-6 pb-10 gap-2.5'>
      <FontAwesomeIcon
        icon={faCircleCheck}
        className='text-6xl text-green-600'
        title='offer-accepted'
      />
      <p className='font-inter font-medium text-2xl text-center pt-3'>
        Transaction Successful
      </p>
      <p className='w-3/4 font-inter font-light text-sm text-regent-600 text-center pt-1'>
        The funds have been successfully credited to your default debit account
      </p>
    </div>
  );

  if (!isOpen) {
    return null;
  }

  return (
    <div className='fixed inset-0 z-50 flex justify-center items-center overflow-y-auto'>
      <div
        className='absolute inset-0 z-0 bg-primary-dark bg-opacity-40'
        role='button'
        onClick={onClose}
      />
      <div className='relative flex flex-col w-[90%] max-w-lg bg-white rounded-lg overflow-y-auto max-h-[90vh]'>
        <div>
          <div className='px-6 py-4 w-full flex justify-between items-center bg-rezen-blue-100'>
            <p className='font-inter font-medium text-base text-primary-dark'>
              Request Funds
            </p>

            <FontAwesomeIcon
              icon={faCircleXmark}
              className='text-xl text-regent-400 cursor-pointer'
              onClick={onClose}
            />
          </div>
          {showSuccessMessage ? (
            <SuccessModal />
          ) : (
            <form onSubmit={handleSubmit(handleConfirmTransaction)}>
              <div className='px-6 pt-6 flex flex-col items-start self-stretch'>
                <p className='mb-6 font-inter font-light text-base text-primary-dark'>
                  You have <b>{formatMoneyValue(creditLeft, 2)}</b> in your
                  credit account.
                </p>

                <p className='mb-4 font-inter font-light text-base text-primary-dark'>
                  Please enter the amount you would like to transfer to your
                  default debit account.
                </p>

                <div className='flex flex-col items-start self-stretch gap-3'>
                  <ZenControlledTextInput<FormData, 'amount'>
                    control={control}
                    label='Amount'
                    name='amount'
                    type='number'
                    placeholder='Enter amount'
                    labelClassName='font-inter font-light text-base !text-primary-dark'
                    startAdornment={
                      <div className='h-full flex items-center justify-center pl-3'>
                        <FontAwesomeIcon
                          icon={faDollarSign}
                          className={`font-light text-sm ${cn(
                            errors.amount
                              ? 'text-red-600'
                              : 'text-rezen-blue-600',
                          )}`}
                        />
                      </div>
                    }
                    onChangeSpy={(val) => {
                      if (val) {
                        trigger('amount');
                      }
                      reset();
                    }}
                    rules={{
                      required: true,
                      validate: {
                        positive: (value) =>
                          value > 0 || 'Amount should be greater than 0',
                        maxAmount: (value) =>
                          value <= (creditLeft?.amount || 0) ||
                          `Maximum amount allowed is ${formatMoneyValue(
                            creditLeft,
                            2,
                          )}`,
                        validAmount: (value) =>
                          MONEY_AMOUNT_REGEX.test(String(value)) ||
                          'Please enter a valid amount',
                      },
                    }}
                  />

                  <div className='flex items-start gap-2'>
                    <ZenButton
                      label='$100'
                      variant='primary-outline-selected'
                      className='rounded-full px-2.5 py-1 border-regent-300 bg-white hover:bg-grey-200 text-grey-600 font-inter font-medium text-sm '
                      onClick={() => handleAddToAmount(100)}
                      LeftIconComponent={
                        <FontAwesomeIcon
                          icon={faPlus}
                          className='text-grey-600 text-xs'
                        />
                      }
                    />
                    <ZenButton
                      label='$200'
                      variant='primary-outline-selected'
                      className='rounded-full px-2.5 py-1 border-regent-300 bg-white hover:bg-grey-200 text-grey-600 font-inter font-medium text-sm'
                      onClick={() => handleAddToAmount(200)}
                      LeftIconComponent={
                        <FontAwesomeIcon
                          icon={faPlus}
                          className='text-grey-600 text-xs'
                        />
                      }
                    />
                    <ZenButton
                      label='$300'
                      variant='primary-outline-selected'
                      className='rounded-full px-2.5 py-1 border-regent-300 bg-white hover:bg-grey-200 text-grey-600 font-inter font-medium text-sm'
                      onClick={() => handleAddToAmount(300)}
                      LeftIconComponent={
                        <FontAwesomeIcon
                          icon={faPlus}
                          className='text-grey-600 text-xs'
                        />
                      }
                    />
                    <ZenButton
                      label='Maximum'
                      variant='primary-outline-selected'
                      className='rounded-full px-2.5 py-1 border-regent-300 bg-white hover:bg-grey-200 text-grey-600 font-inter font-medium text-sm'
                      onClick={() => handleAddToAmount(creditLeft?.amount || 0)}
                      LeftIconComponent={
                        <FontAwesomeIcon
                          icon={faPlus}
                          className='text-grey-600 text-xs'
                        />
                      }
                    />
                  </div>
                </div>

                {/* Error Alert */}
                {isError && error && (
                  <div className='mt-6 mb-3 py-2 px-5 w-full flex items-start bg-red-100 rounded-lg'>
                    <FontAwesomeIcon
                      icon={faSquareInfo}
                      className='text-lg text-red-600 mt-1'
                    />

                    <p className='ml-2.5 text-base font-inter font-normal text-red-600'>
                      {error?.response.status === 400
                        ? getApiErrorMessage(error)
                        : CASH_ADVANCE_ERROR_MESSAGE}
                    </p>
                  </div>
                )}
              </div>

              <div className='flex mt-3 p-6 justify-between items-center self-stretch border-t border-regent-300 bg-white'>
                <ZenButton
                  label='Confirm Transaction'
                  variant='primary'
                  className='w-full h-11 font-inter bg-rezen-blue-600 border-0 font-medium text-base'
                  type='submit'
                  isDisabled={Number(currentAmount) <= 0 || isLoading}
                  isSubmitting={isLoading}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default WalletRequestFundsModal;
