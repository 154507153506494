import { AxiosRequestConfig } from 'axios';
import { range, uniqBy, values } from 'lodash';
import {
  InitialSort,
  TableQueryParamState,
  TableResourceFilter,
} from '../components/Zen/Containers/ZenResourceIndexContainer';
import { ZenModalVariantType } from '../components/Zen/Modal/ZenSimpleConfirmationModal';
import {
  AttachedFeeRequestFeeTypeEnum,
  ParticipantResponse,
  ParticipantValue,
  ParticipantValueRoleEnum,
  PaymentParticipantValue,
  PaymentParticipantValueRoleEnum,
  PriceAndDateInfoRequestRepresentationTypeEnum,
  TransactionBuilderControllerApi,
  TransactionBuilderResponse,
  TransactionCommentResponse,
  TransactionControllerApi,
  TransactionLifecycleStateValue,
  TransactionLifecycleStateValueStateEnum,
  TransactionLiteResponse,
  TransactionResponse,
} from '../openapi/arrakis';
import { MentionBlockMentionTypeEnum } from '../openapi/yada';
import {
  OfficeResponse,
  OfficeResponseAdditionalFeesAndRebatesEnum,
} from '../openapi/yenta';
import {
  ApiError,
  EnumMap,
  IPaginateReq,
  LifecycleGroup,
  LifecycleGroupEnum,
  LifeCycleState,
  PaginatedResultsByIdWithFiltersResponse,
  PartialEnumMap,
  SearchParam,
  SearchTransactionSortByType,
  SearchTransactionSortDirectionType,
  SearchTransactionStateOrProvincesType,
  SearchTransactionStatusType,
  SearchTransactionTransactionTypes,
  TransactionsMainTabType,
  TransactionsSubTabType,
  TransactionType,
} from '../types';
import { getAllParticipants } from './AgentHelper';
import { safePartialEnumMapGet } from './EnumHelper';
import { getArrakisConfiguration } from './OpenapiConfigurationUtils';
import { getParticipantName } from './ParticipantHelper';
import { getFormattedFilterDates } from './TableUtils';
import { participantRoleDisplayName } from './TransactionHelper';

export const getMentionParticipants = (
  transaction: TransactionResponse,
  office: OfficeResponse,
  searchTerm: string,
) => {
  const allParticipants = getAllParticipants(transaction!)
    .filter((p) => !!p.yentaId)
    .filter((p) =>
      `${p?.firstName} ${p?.lastName}`
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()),
    )
    .map((p) => ({
      id: p.yentaId!,
      value: getParticipantName(p),
      emailAddress: p.emailAddress!,
    }));

  if (office) {
    if (office.managingBroker) {
      allParticipants.push({
        id: office.managingBroker?.id!,
        value: office.managingBroker?.fullName!,
        emailAddress: office.managingBroker?.emailAddress!,
      });
    }

    if (office.designatedBroker) {
      allParticipants.push({
        id: office.designatedBroker?.id!,
        value: office.designatedBroker?.fullName!,
        emailAddress: office.designatedBroker?.emailAddress!,
      });
    }
  }

  return uniqBy(
    allParticipants.filter((p) => !!p.id),
    'id',
  );
};

export const checkPersonalDeal = (transaction: TransactionResponse) => {
  const participant = transaction.paymentParticipants?.find(
    (paymentParticipant) =>
      paymentParticipant.yentaId === transaction?.transactionOwner?.id,
  );
  return participant?.personalDeal;
};

export const isTransactionDetailRouteActive = (): boolean => {
  const inboxPathStarts = [
    '/transactions/',
    '/listings/',
    '/mortgages',
    '/conversations/',
  ];

  return inboxPathStarts.some((path) =>
    window.location.pathname.startsWith(path),
  );
};

export const isListingCommissionRequiredForRole = (
  transactionOwnerRepresent: PriceAndDateInfoRequestRepresentationTypeEnum,
): boolean => {
  const representationValidationMap: EnumMap<
    PriceAndDateInfoRequestRepresentationTypeEnum,
    boolean
  > = {
    [PriceAndDateInfoRequestRepresentationTypeEnum.Seller]: true,
    [PriceAndDateInfoRequestRepresentationTypeEnum.Landlord]: true,
    [PriceAndDateInfoRequestRepresentationTypeEnum.Dual]: true,
    [PriceAndDateInfoRequestRepresentationTypeEnum.Buyer]: false,
    [PriceAndDateInfoRequestRepresentationTypeEnum.Tenant]: false,
  };
  return representationValidationMap[transactionOwnerRepresent];
};

export const isListingCommissionRequiredForTransaction = (
  transaction: TransactionResponse,
): boolean | undefined => {
  return !!(
    transaction.listingSideRepresentation || transaction.dualRepresentation
  );
};

export const isListingNotActionable = (
  transaction: TransactionResponse | TransactionLiteResponse,
): boolean => {
  const state = transaction.lifecycleState?.state;
  return (
    state === TransactionLifecycleStateValueStateEnum.ListingInContract ||
    state === TransactionLifecycleStateValueStateEnum.ListingClosed
  );
};

export const getTransactionVariant = (
  transaction: TransactionLifecycleStateValue,
): ZenModalVariantType | undefined => {
  const transactionStateMap: PartialEnumMap<
    TransactionLifecycleStateValueStateEnum,
    ZenModalVariantType
  > = {
    [TransactionLifecycleStateValueStateEnum.Terminated]: 'danger',
  };

  return safePartialEnumMapGet(
    transactionStateMap,
    transaction?.state!,
    'info',
  );
};

export const isPersonalDealTransaction = (transaction: TransactionResponse) => {
  if (!!transaction?.paymentParticipants?.length) {
    return transaction?.paymentParticipants?.some(
      (payment) => !!payment?.personalDeal,
    );
  }
  return false;
};

export const getPageTitleForTransaction = (
  transaction?: TransactionResponse,
) => {
  return transaction?.listing ? 'Listing' : 'Transaction';
};

// Function to filter mentioned participants based on search term
export const filterMentionParticipants = (
  searchTerm: string,
  mentionParticipants?: TransactionCommentResponse,
) => {
  const mentionableUsers = (mentionParticipants?.mentionableUsers || []).map(
    (user) => {
      return {
        id: user?.yentaId,
        value: user?.displayName,
        mentionType: MentionBlockMentionTypeEnum.User,
      };
    },
  );

  const mentionableGroups = (mentionParticipants?.mentionableGroups || []).map(
    (group) => {
      return {
        id: group?.groupId,
        value: group?.groupName,
        mentionType: MentionBlockMentionTypeEnum.Group,
      };
    },
  );

  const allMentionParticipants = [...mentionableGroups, ...mentionableUsers];

  if (searchTerm !== '') {
    return allMentionParticipants?.filter((participant) =>
      participant?.value?.toLowerCase().includes(searchTerm),
    );
  }

  return allMentionParticipants;
};

export const isParticipantAdmin = (
  participant: ParticipantValue | PaymentParticipantValue | null,
) => {
  if (!participant) {
    return false;
  }

  const isAdminRole = [
    ParticipantValueRoleEnum.Admin,
    PaymentParticipantValueRoleEnum.Admin,
  ].includes(participant.role!);

  return isAdminRole;
};

export const isConditionalDatesPresent = (
  transaction?: TransactionResponse,
) => {
  return (
    !!transaction?.financingConditionsExpirationDate ||
    !!transaction?.propertyInspectionExpirationDate ||
    !!transaction?.condoDocumentsExpirationDate ||
    !!transaction?.otherConditionsExpirationDate ||
    !!transaction?.saleOfBuyersPropertyExpirationDate
  );
};

export const isFeeTypeEnabled = (
  officeDetail: OfficeResponse,
  feeType: OfficeResponseAdditionalFeesAndRebatesEnum,
) => {
  return (officeDetail?.additionalFeesAndRebates || [])?.includes(feeType);
};

export const getAttachedFeeTypes = (officeDetail?: OfficeResponse) => {
  let feeTypes: AttachedFeeRequestFeeTypeEnum[] = [];

  if (
    isFeeTypeEnabled(
      officeDetail!,
      OfficeResponseAdditionalFeesAndRebatesEnum.AdditionalCommission,
    )
  ) {
    feeTypes.push(AttachedFeeRequestFeeTypeEnum.AdditionalCommission);
  }

  if (
    isFeeTypeEnabled(
      officeDetail!,
      OfficeResponseAdditionalFeesAndRebatesEnum.ReimbursementFee,
    )
  ) {
    feeTypes.push(AttachedFeeRequestFeeTypeEnum.Reimbursement);
  }

  if (
    isFeeTypeEnabled(
      officeDetail!,
      OfficeResponseAdditionalFeesAndRebatesEnum.Rebate,
    )
  ) {
    feeTypes.push(AttachedFeeRequestFeeTypeEnum.Rebate);
  }

  if (
    isFeeTypeEnabled(
      officeDetail!,
      OfficeResponseAdditionalFeesAndRebatesEnum.MlsFee,
    )
  ) {
    feeTypes.push(AttachedFeeRequestFeeTypeEnum.Mls);
  }

  if (
    isFeeTypeEnabled(
      officeDetail!,
      OfficeResponseAdditionalFeesAndRebatesEnum.TransactionCoordinatorFee,
    )
  ) {
    feeTypes.push(AttachedFeeRequestFeeTypeEnum.TransactionCoordinator);
  }

  if (
    isFeeTypeEnabled(
      officeDetail!,
      OfficeResponseAdditionalFeesAndRebatesEnum.OneRealImpactFund,
    )
  ) {
    feeTypes.push(AttachedFeeRequestFeeTypeEnum.OneRealImpactFund);
  }

  return feeTypes;
};

export const TransactionSortDirectionTypeEnum: EnumMap<
  string,
  SearchTransactionSortDirectionType
> = {
  asc: 'ASC',
  desc: 'DESC',
};

export const TransactionSortByTypeEnum: EnumMap<
  string,
  SearchTransactionSortByType
> = {
  code: ['TRANSACTION_CODE'],
  transactionType: ['TYPE'],

  lifecycleState: ['STATUS'],
  skySlopeEscrowClosingDate: ['ESTIMATED_CLOSING_DATE'],
  rezenClosedAt: ['ACTUAL_CLOSING_DATE'],
  id: ['ID'],
  price: ['PRICE'],
  listingDate: ['LISTING_DATE'],
  listingExpirationDate: ['LISTING_EXPIRATION_DATE'],
};

const AgentTransactionSortByTypeEnum: EnumMap<
  string,
  'ESCROW_CLOSING_DATE' | 'ACTUAL_CLOSING_DATE' | 'PRICE' | 'EXPIRATION_DATE'
> = {
  skySlopeEscrowClosingDate: 'ESCROW_CLOSING_DATE',
  rezenClosedAt: 'ACTUAL_CLOSING_DATE',
  price: 'PRICE',
  listingExpirationDate: 'EXPIRATION_DATE',
};

export const getSearchableTransactions = async (
  req: IPaginateReq<TransactionResponse>,
  isListing: boolean = false,
  officeIds?: (string | undefined)[] | undefined,
  axiosOptions?: AxiosRequestConfig,
) => {
  const sortKey = Object.keys(req.sortBy || {})[0];
  const sortType = Object.values(req.sortBy || {})[0];

  const [
    estimatedClosingDate,
    estimatedClosingDateOnOrAfter,
    estimatedClosingDateOnOrBefore,
  ] = getFormattedFilterDates(
    (req.filter?.skySlopeEscrowClosingDate! as unknown) as SearchParam[],
  );

  const [
    actualClosingDate,
    actualClosingDateOnOrAfter,
    actualClosingDateOnOrBefore,
  ] = getFormattedFilterDates(
    (req.filter?.rezenClosedAt! as unknown) as SearchParam[],
  );

  const [
    listingDate,
    listingDateOnOrAfter,
    listingDateOnOrBefore,
  ] = getFormattedFilterDates(
    (req.filter?.listingDate! as unknown) as SearchParam[],
  );

  const [
    listingExpirationDate,
    listingExpirationDateOnOrAfter,
    listingExpirationDateOnOrBefore,
  ] = getFormattedFilterDates(
    (req.filter?.listingExpirationDate! as unknown) as SearchParam[],
  );

  const { data } = await new TransactionControllerApi(
    getArrakisConfiguration(),
  ).getTransactions(
    isListing,
    req.page,
    req.pageSize,
    TransactionSortDirectionTypeEnum[sortType!],
    TransactionSortByTypeEnum[sortKey!],
    req.search,
    req.filter?.code as string,
    //@ts-ignore
    req.filter?.street as string,
    //@ts-ignore
    req.filter?.city as string,
    //@ts-ignore
    req?.filter?.state?.values as SearchTransactionStateOrProvincesType,
    //@ts-ignore
    req.filter?.zip as string,
    ((req.filter?.compliantAt as SearchParam)?.value as unknown) as boolean,
    undefined,
    undefined,
    undefined,
    undefined,
    estimatedClosingDate,
    estimatedClosingDateOnOrAfter,
    estimatedClosingDateOnOrBefore,
    actualClosingDate,
    actualClosingDateOnOrAfter,
    actualClosingDateOnOrBefore,
    listingDate,
    listingDateOnOrAfter,
    listingDateOnOrBefore,
    listingExpirationDate,
    listingExpirationDateOnOrAfter,
    listingExpirationDateOnOrBefore,
    // @ts-ignore
    (req.filter?.transactionType as SearchParam)
      ?.values! as SearchTransactionTransactionTypes,
    //@ts-ignore
    (req.filter?.lifecycleState as SearchParam)
      ?.values! as SearchTransactionStatusType,
    undefined,
    officeIds as string[],
    axiosOptions,
  );

  return {
    data: data?.results || [],
    total: data?.totalCount || 0,
    hasNext: data?.hasNext,
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
  };
};

/**
 * Returns a record of paginated results.
 *
 * @param {number} pageNumber - The page number, starting from 0.
 * @param {number} pageSize - The size of each page.
 * @param {T[]} data - The data to paginate, an array of objects with an id property.
 * @returns {Record<number, string>} - The paginated results in the format {1: 'id1', 2: 'id2', ...}.
 */
export const getPaginatedResults = <T extends { id?: string }>(
  pageNumber: number,
  pageSize: number,
  data: T[],
): Record<number, string> => {
  const paginatedResults: Record<number, string> = {};

  const startNumber = pageNumber * pageSize + 1;
  const endNumber = (pageNumber + 1) * pageSize;

  const indexNumbers = range(startNumber, endNumber + 1);

  indexNumbers.forEach((indexNumber, index) => {
    const dataItem = data[index];
    if (!!dataItem && !!dataItem.id) {
      paginatedResults[indexNumber] = dataItem.id;
    }
  });

  return paginatedResults;
};

export const getTransactionListPageUrlQueryParams = (
  result?: PaginatedResultsByIdWithFiltersResponse<TransactionResponse>,
) => {
  const filters = Object.entries(result?.filters?.filter || {}).map(
    ([key, value]) => ({
      id: key,
      value: value,
    }),
  );

  const sortBy = Object.entries(result?.filters?.sortBy || {}).map(
    ([key, value]) => ({
      id: key,
      desc: value === 'desc' ? 'true' : 'false',
    }),
  );

  return {
    filter: filters,
    sortBy: sortBy,
    page: result?.filters?.page,
    pageSize: result?.filters?.pageSize,
    search: result?.filters?.search,
  };
};

export const getTransactionStatusByLifecycleGroupEnum = (
  lifecycleGroup: LifecycleGroup,
): LifeCycleState[] => {
  const closedStates = [
    'PAYMENT_ACCEPTED',
    'PAYMENT_SCHEDULED',
    'SETTLED',
    'CLOSED',
    'WAITING_ON_PAYMENT',
    'LISTING_CLOSED',
  ] as LifeCycleState[];
  const openStates = values(TransactionLifecycleStateValueStateEnum).filter(
    (state) => !closedStates.includes(state) && state !== 'TERMINATED',
  );

  const mapValues: EnumMap<LifecycleGroup, LifeCycleState[]> = {
    OPEN: openStates,
    CLOSED: closedStates,
    TERMINATED: [TransactionLifecycleStateValueStateEnum.Terminated],
  };

  return mapValues[lifecycleGroup];
};

export const transactionTypesByPathnameEnumMap: EnumMap<
  TransactionsMainTabType,
  TransactionType[] | undefined
> = {
  all: undefined,
  sale: ['SALE'],
  lease: ['LEASE'],
  referrals: ['REFERRAL', 'INTERNAL_REFERRAL'],
};

export const transactionLifecycleGroupEnumMap: Partial<
  EnumMap<TransactionsSubTabType, LifecycleGroup>
> = {
  active: 'OPEN',
  closed: 'CLOSED',
  terminated: 'TERMINATED',
};

export const getNonAgentTransactionsAPIParams = (
  query: TableQueryParamState<TransactionResponse>,
  type: TransactionsMainTabType,
  lifecycle: TransactionsSubTabType,
  officeIds?: string[],
  isListing: boolean = false,
): Parameters<TransactionControllerApi['getTransactions']> => {
  const pageSize = !!query.pageSize ? parseInt(query.pageSize) : 20;
  const pageNumber = !!query.pageIndex ? parseInt(query.pageIndex) : 0;
  const sortKey =
    ((query.sortBy?.[0]?.desc as unknown) as string) === 'true'
      ? 'desc'
      : 'asc';
  const sortType = query.sortBy?.[0]?.id;

  const getParameterValue = (paramValue: keyof TransactionResponse) => {
    return (query.filter || []).find((filter) => filter.id === paramValue);
  };

  const [
    estimatedClosingDate,
    estimatedClosingDateOnOrAfter,
    estimatedClosingDateOnOrBefore,
  ] = getFormattedFilterDates(
    getParameterValue('skySlopeEscrowClosingDate')?.value,
  );

  const [
    actualClosingDate,
    actualClosingDateOnOrAfter,
    actualClosingDateOnOrBefore,
  ] = getFormattedFilterDates(getParameterValue('rezenClosedAt')?.value);

  const [
    listingDate,
    listingDateOnOrAfter,
    listingDateOnOrBefore,
  ] = getFormattedFilterDates(getParameterValue('listingDate')?.value);

  const [
    listingExpirationDate,
    listingExpirationDateOnOrAfter,
    listingExpirationDateOnOrBefore,
  ] = getFormattedFilterDates(
    getParameterValue('listingExpirationDate')?.value,
  );

  const queryLifecycleStateFilter = query?.filter?.find(
    (f) => f?.id === 'lifecycleState',
  );

  const lifecycleStateFilterValue = !!queryLifecycleStateFilter
    ? queryLifecycleStateFilter.value?.values
    : getTransactionStatusByLifecycleGroupEnum(
        transactionLifecycleGroupEnumMap[lifecycle]!,
      );

  return [
    isListing, //isListing
    pageNumber, //pageNumber
    pageSize, //pageSize
    TransactionSortDirectionTypeEnum[sortKey], //sortDirection
    TransactionSortByTypeEnum[sortType], //sortBy
    query?.search, //search
    getParameterValue('code')?.value, //code
    getParameterValue('street' as keyof TransactionResponse)?.value, //street
    getParameterValue('city' as keyof TransactionResponse)?.value, //city
    getParameterValue('state' as keyof TransactionResponse)?.value?.values, //states
    getParameterValue('zip' as keyof TransactionResponse)?.value, //zip code
    getParameterValue('compliantAt' as keyof TransactionResponse)?.value?.value, // Mark as Compliant
    undefined, //id
    undefined, //price
    undefined, //priceGreaterThanOrEqualTo
    undefined, //priceLessThanOrEqualTo
    estimatedClosingDate, //estimatedClosingDate
    estimatedClosingDateOnOrAfter, //estimatedClosingDateOnOrAfter
    estimatedClosingDateOnOrBefore, //estimatedClosingDateOnOrBefore
    actualClosingDate,
    actualClosingDateOnOrAfter,
    actualClosingDateOnOrBefore,
    listingDate,
    listingDateOnOrAfter,
    listingDateOnOrBefore,
    listingExpirationDate,
    listingExpirationDateOnOrAfter,
    listingExpirationDateOnOrBefore,
    transactionTypesByPathnameEnumMap[type]!, //transactionType
    lifecycleStateFilterValue, //lifecycleState
    undefined, //currency
    officeIds as string[], //officeIds
  ];
};

export const getTransactionListByAgentIdParams = (
  agentId: string,
  lifecycle: TransactionsSubTabType,
  type: TransactionsMainTabType,
  query: TableQueryParamState<TransactionLiteResponse>,
): Parameters<
  TransactionControllerApi['getTransactionsByStateGroupPaginated']
> => {
  const pageSize = !!query.pageSize ? parseInt(query.pageSize) : 10;
  const pageNumber = !!query.pageIndex ? parseInt(query.pageIndex) : 0;
  const sortKey =
    ((query.sortBy?.[0]?.desc as unknown) as string) === 'true'
      ? 'desc'
      : 'asc';
  const sortType = query.sortBy?.[0]?.id;

  return [
    agentId,
    transactionLifecycleGroupEnumMap[lifecycle]!, //lifecycleGroup
    pageNumber, //pageNumber
    pageSize, //pageSize
    query?.search, //search
    AgentTransactionSortByTypeEnum[sortType], //sortBy
    TransactionSortDirectionTypeEnum[sortKey], //sortDirection
    transactionTypesByPathnameEnumMap[type]!, //transactionTypes
  ];
};

export const getDraftTransactionListByAgentIdParams = (
  agentId: string,
  type: 'TRANSACTION' | 'LISTING',
  transactionType: TransactionsMainTabType,
  query: TableQueryParamState<TransactionBuilderResponse>,
): Parameters<
  TransactionBuilderControllerApi['getTransactionBuildersPaginated']
> => [
  query.pageSize ? parseInt(query.pageSize) : 10, //pageSize
  query.pageIndex ? parseInt(query.pageIndex) : 0, //pageNumber
  agentId, //agentId
  type, //type
  transactionTypesByPathnameEnumMap[transactionType], //transactionTypes
];

export const getPrefixTextForParticipantUserPill = (
  isInternalReferralFeatureEnabled: boolean,
  isInternalReferralOriginatingTransaction: boolean,
  participant: PaymentParticipantValue | ParticipantValue,
) => {
  if (
    isInternalReferralFeatureEnabled &&
    isInternalReferralOriginatingTransaction &&
    !!participant?.role &&
    participantRoleDisplayName(participant?.role) === 'Referral'
  ) {
    return 'Real Broker';
  }

  if (participant.externalParticipant) {
    return participant.paidViaBusinessEntity?.name || '';
  }

  return '';
};

export const getConditionalDateErrors = (e: any) => {
  const errorDetails: ApiError =
    e.response?.data?.['com.real.commons.apierror.ApiError'];

  const subErrors = errorDetails?.subErrors || [];

  const dateErrorPresent = subErrors?.some(
    (subError) => subError?.message === 'must be before closingDateEstimated',
  );

  if (dateErrorPresent) {
    const conditionalDateErrors = subErrors?.map((subError) => ({
      field: subError?.field,
      errorMessage: subError?.message?.replace(
        'closingDateEstimated',
        'Estimated closing date',
      ),
    }));

    return conditionalDateErrors;
  }

  return [];
};

export const isLifeCycleAndTabMatch = (
  lifecycle: LifecycleGroupEnum,
  tab: TransactionsSubTabType,
): boolean => {
  return (
    (lifecycle === LifecycleGroupEnum.OPEN && tab === 'active') ||
    (lifecycle === LifecycleGroupEnum.CLOSED && tab === 'closed') ||
    (lifecycle === LifecycleGroupEnum.TERMINATED && tab === 'terminated')
  );
};

export const getBuyerOrSellers = (
  participants?: ParticipantResponse[],
  type: 'BUYER' | 'SELLER' = 'BUYER',
) => {
  const sellersOrBuyers = (participants || [])?.filter(
    (p) => p?.participantRole === type,
  );

  return !!sellersOrBuyers?.length
    ? sellersOrBuyers?.map((p) =>
        !!p?.firstName || !!p?.lastName
          ? `${p?.firstName} ${p?.lastName}`
          : 'N/A',
      )
    : 'N/A';
};

export const convertQueryFilters = <D extends object>(
  query: TableQueryParamState<D>,
  pageSize: number = 20,
): IPaginateReq<D> => {
  const filter: TableResourceFilter<D> = {};
  const sortBy: InitialSort<D> = {};

  (query.filter || []).forEach((f) => {
    filter[f.id as keyof TableResourceFilter<D>] = f.value;
  });

  (query.sortBy || []).forEach((sort) => {
    sortBy[sort.id as keyof InitialSort<D>] =
      ((sort.desc as unknown) as string) === 'true' ? 'desc' : 'asc';
  });

  return {
    ...query,
    page: !!query?.pageIndex ? parseInt(query?.pageIndex) : 0,
    pageSize,
    filter,
    sortBy,
  };
};

export const createTransactionCommissionPayerStepHeader = (
  isCandianUser: boolean,
) => {
  return isCandianUser
    ? 'To whom should the commission documents and/or invoice be sent?'
    : 'Where should we send the commission document (e.g. Title/Escrow)?';
};

export const cdPayerCardHeader = (
  isInternalReferralTransaction: boolean,
  isCandianTransaction: boolean,
) => {
  if (isInternalReferralTransaction) {
    return 'Client Referred to Real Agent (Internal Referral)';
  }

  if (isCandianTransaction) {
    return 'Commission Payer';
  }

  return 'Commission Payer (Title / Escrow / Closing)';
};
