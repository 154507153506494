import { PDFDocument } from 'pdf-lib';
import Resizer from 'react-image-file-resizer';
import JSZip from 'jszip';
import saveAs from 'file-saver';
import heic2any from 'heic2any';
import {
  ALLOWED_AUDIO_FILE_TYPES,
  ALLOWED_VIDEO_FILE_TYPES,
} from '../constants/AnnouncementConstants';
import {
  ALLOWED_USER_AVATAR_FILE_TYPES,
  IMAGE_RESIZING_ATTRIBUTES,
} from '../constants/FilesConstants';
import { FileApi, FileResponse } from '../openapi/dropbox';
import ErrorService from '../services/ErrorService';
import { getFileNameFromUrl } from './StringUtils';
import { getDropboxConfiguration } from './OpenapiConfigurationUtils';
import { performFetch } from './FetchUtil';

export interface FileMetadata {
  filename: string;
  fileUrl: string;
}

export const getUploadedFileUrl = async (pdfFile: File[]) => {
  try {
    const newPDFFile = await PDFDocument.create();

    const allPDFBuffers = await Promise.all(
      pdfFile.map((pdf) => pdf.arrayBuffer()),
    );

    const allLoadedPDFs = await Promise.all(
      allPDFBuffers.map((pdf) =>
        PDFDocument.load(pdf, {
          throwOnInvalidObject: true,
          ignoreEncryption: false,
        }),
      ),
    );

    const allNewPages = await Promise.all(
      allLoadedPDFs.map((pdfBuffer) =>
        newPDFFile.copyPages(pdfBuffer, pdfBuffer.getPageIndices()),
      ),
    );

    allNewPages.forEach((pages) =>
      pages.forEach((page) => newPDFFile.addPage(page)),
    );

    const pdfBytes = await newPDFFile.save();

    return URL.createObjectURL(
      new Blob([pdfBytes], { type: 'application/pdf' }),
    );
  } catch (e) {
    return undefined;
  }
};

export const getPDFFileURLContainingSelectedPages = async (
  link: string,
  pageIndexes: number[],
) => {
  try {
    const newPDFFile = await PDFDocument.create();
    const data = await performFetch(link);
    const uploadedPDFBuffer = await data.arrayBuffer();

    const uploadedPDF = await PDFDocument.load(uploadedPDFBuffer, {
      throwOnInvalidObject: true,
      ignoreEncryption: false,
    });

    const newPages = await newPDFFile.copyPages(uploadedPDF, pageIndexes);
    newPages.map((newPage) => newPDFFile.addPage(newPage));

    return newPDFFile.save();
  } catch (e) {
    return undefined;
  }
};

export const validDocs = (files: File[] = [], maxDocSize: number) => {
  const validFiles = files?.filter((file) => file?.size <= maxDocSize);

  return validFiles;
};

export const isDocSizeInvalid = (
  files: File[] = [],
  maxDocSize: number,
): boolean => {
  return !!files?.length && files?.some((file) => file?.size > maxDocSize);
};

export const bytesToSize = (bytes: number, toFixed = 0) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) {
    return 'N/A';
  }

  const index = Math.min(
    Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes?.length - 1,
  );

  if (index === 0) {
    return `${bytes} ${sizes[index]}`;
  }

  return `${(bytes / 1024 ** index)?.toFixed(toFixed)} ${sizes[index]}`;
};

export const getExtensionFromFileName = (fileName: string) => {
  const regularExp: RegExp = /(?:\.([^.]+))?$/;

  switch (regularExp!.exec(fileName)![1]) {
    case 'pdf':
      return 'pdf';

    case 'jpg':
    case 'jpeg':
    case 'svg':
    case 'png':
      return 'image';

    case 'txt':
      return 'txt';

    default:
      return 'other';
  }
};

export const validFormatDocs = (
  files: File[] = [],
  formatAllowed: string[],
) => {
  for (let i = 0; i < files.length; i++) {
    let selectedFile = files[i];
    let sFileName = selectedFile.name;
    if (sFileName.length > 0) {
      let blnValid = false;
      for (let j = 0; j < formatAllowed.length; j++) {
        let sExtension = formatAllowed[j];
        if (sFileName.toLowerCase().includes(sExtension.toLowerCase())) {
          blnValid = true;
          break;
        }
      }
      if (!blnValid) {
        return false;
      }
    }
  }
  return true;
};

export const resizeFile = (uploadedFile: File) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      uploadedFile,
      IMAGE_RESIZING_ATTRIBUTES.width,
      IMAGE_RESIZING_ATTRIBUTES.height,
      IMAGE_RESIZING_ATTRIBUTES.format,
      IMAGE_RESIZING_ATTRIBUTES.quality,
      0,
      (imageUri) => {
        resolve(imageUri);
      },
      IMAGE_RESIZING_ATTRIBUTES.outputType,
    );
  });

export const getCaseInsensitiveFileTypesRegex = (
  fileTypes: string[],
): RegExp => {
  return new RegExp(fileTypes.join('|'), 'i');
};

export const isAudioFile = (fileName: string) => {
  const caseInsensitiveFileTypes = getCaseInsensitiveFileTypesRegex(
    ALLOWED_AUDIO_FILE_TYPES,
  );
  return caseInsensitiveFileTypes.test(fileName);
};

export const isVideoFile = (fileName: string) => {
  const caseInsensitiveFileTypes = getCaseInsensitiveFileTypesRegex(
    ALLOWED_VIDEO_FILE_TYPES,
  );
  return caseInsensitiveFileTypes.test(fileName);
};

export const isValidUserAvatarFile = (fileName: string) => {
  const caseInsensitiveFileTypes = getCaseInsensitiveFileTypesRegex(
    ALLOWED_USER_AVATAR_FILE_TYPES,
  );
  return caseInsensitiveFileTypes.test(fileName);
};

export const getFileBlob = async (
  fileUrl: string,
  headers?: RequestInit,
): Promise<Blob | undefined> => {
  try {
    const fetchedFile = await performFetch(fileUrl, headers);
    const fileBlob = await fetchedFile.blob();
    return fileBlob;
  } catch (e) {
    return undefined;
  }
};

export const getFileFromUrl = async (fileUrl: string): Promise<File> => {
  const fetchedFileData = await getFileBlob(fileUrl);
  const fileName = getFileNameFromUrl(fileUrl)!;
  return new File([fetchedFileData!], fileName);
};

export const getFileInformation = (filename: string) => {
  const dotIndex = filename?.lastIndexOf('.');
  const basename =
    dotIndex !== -1 ? filename?.substring(0, dotIndex) : filename;
  const extension = dotIndex !== -1 ? filename?.substring(dotIndex + 1) : '';

  return { basename, extension };
};

export const saveFileAsZipFromFileMetadata = async (
  filesData: FileMetadata[],
  zipFileName: string,
) => {
  const zip = new JSZip();
  const folder = zip.folder('files');

  const fileCount: { [fileName: string]: number } = {};

  for (const { filename, fileUrl } of filesData) {
    const { basename, extension } = getFileInformation(filename);

    if (fileCount[filename] === undefined) fileCount[filename] = 0;

    fileCount[filename] += 1;

    const count = fileCount[filename];

    const downloadName =
      count > 1 ? `${basename} (${count}).${extension}` : filename;

    const fileContent = await getFileFromUrl(fileUrl);

    folder!.file(downloadName, fileContent);
  }

  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, `${zipFileName}.zip`);
  });
};

const dropboxFileByVersionURL = async (
  fileId: string,
  versionId: string,
): Promise<FileMetadata | undefined> => {
  try {
    if (versionId) {
      const { data: fileUrl } = await new FileApi(
        getDropboxConfiguration(),
      ).getFileVersionUrl(fileId!, versionId!);

      const originalFile = getFileNameFromUrl(fileUrl);
      return { filename: originalFile!, fileUrl };
    } else {
      const { data: fileUrl } = await new FileApi(
        getDropboxConfiguration(),
      ).getFileUrl(fileId!);

      const originalFile = getFileNameFromUrl(fileUrl);
      return { filename: originalFile!, fileUrl };
    }
  } catch (e) {
    ErrorService.notifyIgnoreHandled('Unable to download a file', e, {
      file: {
        fileId,
        versionId,
      },
    });
    return undefined;
  }
};

export const downloadSelectedFiles = async (
  files: FileResponse[],
  zipFileName: string,
) => {
  const filesData = await Promise.all(
    files?.map(async (fileReference) => {
      const fileData = await dropboxFileByVersionURL(
        fileReference?.id!,
        fileReference?.currentVersion?.id!,
      );

      return fileData;
    }),
  );

  const filteredFilesData = filesData.filter(
    (fileData) => fileData !== undefined,
  ) as FileMetadata[];

  if (filteredFilesData.length) {
    await saveFileAsZipFromFileMetadata(filteredFilesData, zipFileName);
  }
};

export const downloadFile = async (
  imageSrc: string,
  fileName: string = 'fileName',
  headers?: RequestInit,
) => {
  const imageBlob = await getFileBlob(imageSrc, headers);
  const imageURL = URL.createObjectURL(imageBlob!);

  const link = document.createElement('a');
  link.href = imageURL;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const copyImageToClipboard = async (imgUrl: string) => {
  try {
    let img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = async () => {
      let canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      let ctx = canvas.getContext('2d');
      ctx!.drawImage(img, 0, 0);

      // Convert the image to PNG format
      let pngDataUrl = canvas.toDataURL('image/png');
      const imgblob = await getFileBlob(pngDataUrl);
      await navigator.clipboard.write([
        new ClipboardItem({
          [imgblob!.type]: imgblob!,
        }),
      ]);
    };
    img.src = imgUrl;
  } catch (error) {
    console.error('Error copying image to clipboard', error);
  }
};

export async function isHeicByContent(blob: Blob) {
  const buffer = await blob.slice(0, 24).arrayBuffer();
  const uint8Array = new Uint8Array(buffer);
  const ftyp = String.fromCharCode(...uint8Array.slice(8, 12));

  const heicSignatures = ['heic', 'heix', 'hevc', 'hevx', 'mif1', 'msf1'];
  return heicSignatures.includes(ftyp);
}
export const getFileUrl = async (fileUrl: string) => {
  const response = await performFetch(fileUrl);
  const heicBlob = await response.blob();

  const isHeicFile = await isHeicByContent(heicBlob);

  // Convert HEIC to JPEG if it is a HEIC file (check by content)
  if (isHeicFile) {
    const jpegBuffer = await heic2any({ blob: heicBlob });

    const val = jpegBuffer instanceof Blob ? jpegBuffer : jpegBuffer[0];
    const jpegUrl = URL.createObjectURL(val);

    return jpegUrl;
  }

  return fileUrl;
};
