import { cn } from '../../../utils/classUtils';
import useKBarCardFocus from '../../Kbar/useKBarCardFocus';

export interface ZenTransactionSummaryCardProps {
  title?: string;
  bottomAction?: string | React.ReactElement;
  onBottomActionClick?(): void;
  LeftComponent?: React.ReactElement;
  RightComponent?: React.ReactElement;
  BottomComponent?: React.ReactElement;
  hideBottomAction?: boolean;
  variant?: 'border' | 'no-border';
  resize?: boolean;
  testId?: string;
  focusId?: string;
  titleAdjacentComponent?: React.ReactElement;
  titleIconPlacement?: 'left' | 'right';
}

const ZenTransactionSummaryCard: React.FC<ZenTransactionSummaryCardProps> = ({
  bottomAction,
  BottomComponent,
  onBottomActionClick,
  title,
  LeftComponent,
  RightComponent,
  hideBottomAction = false,
  children,
  variant = 'border',
  resize = false,
  testId = 'zen-card',
  focusId,
  titleAdjacentComponent,
  titleIconPlacement = 'right',
}) => {
  const { cardRef } = useKBarCardFocus(focusId);

  return (
    <div
      ref={cardRef}
      data-testid={testId}
      className={cn(
        'break-inside-avoid',
        variant === 'border' ? 'border border-gray-200' : '',
        'rounded-lg',
        !resize ? 'min-h-full' : 'h-auto w-full',
      )}
    >
      {title && (
        <div className='flex justify-between items-center py-3 px-4 bg-[#EEF1F5] rounded-t-lg '>
          <p className='flex items-center'>
            {!!LeftComponent && <span className='mr-1'>{LeftComponent}</span>}
            <span
              className={cn(
                'flex font-medium  items-center text-zen-body !text-xs',
                titleIconPlacement === 'right'
                  ? 'flex-row'
                  : 'flex-row-reverse',
              )}
            >
              {title}
              {titleAdjacentComponent && titleAdjacentComponent}
            </span>
          </p>
          {RightComponent}
        </div>
      )}
      <div>{children}</div>
      {BottomComponent
        ? BottomComponent
        : !hideBottomAction &&
          bottomAction && (
            <button
              onClick={onBottomActionClick}
              className='text-primary-blue font-primary-regular focus:outline-none hover:opacity-70 px-4 py-2 w-full'
            >
              {bottomAction}
            </button>
          )}
    </div>
  );
};

export default ZenTransactionSummaryCard;
