import {
  faArrowDownFromArc,
  faArrowUpFromArc,
} from '@fortawesome/pro-light-svg-icons';
import {
  faCircleInfo,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, isNumber } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';
import {
  InternalAccountDtoTypeEnum,
  InternalLedgerEntryApi,
  InternalLedgerEntryDto,
  InternalLedgerEntryDtoStatusEnum,
  InternalLedgerEntryDtoTypeEnum,
} from '../../../openapi/wallet';
import { useFetchInternalAccounts } from '../../../query/wallet/useWallet';
import { SearchParam } from '../../../types';
import { formatMoneyValue } from '../../../utils/CurrencyUtils';
import { displayDate } from '../../../utils/DateUtils';
import { getWalletConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { getFormattedFilterDates, IFilter } from '../../../utils/TableUtils';
import DateColumnFilter from '../../table/Filters/DateColumnFilter';
import WalletCreditPaymentSourceColumnFilter from '../../table/Filters/WalletCreditPaymentSourceColumnFilter';
import WalletLastStatementToggleColumnFilter from '../../table/Filters/WalletLastStatementToggleColumnFilter';
import ZenResourceIndexContainer from '../../Zen/Containers/ZenResourceIndexContainer';
import ZenPageLayout from '../../Zen/ZenPageLayout';
import ZenResourceContainer from '../../Zen/ZenResourceContainer';
import WalletMyCreditDetailsCard from '../WalletDebitDashboard/WalletMyCreditDetailsCard';
import { useRefresh } from '../../../hooks/useRefresh';
import { cn } from '../../../utils/classUtils';
import { CREDIT_TERMS } from '../../../utils/WalletUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';

export const columns: Array<Column<InternalLedgerEntryDto>> = [
  {
    Header: 'Description',
    accessor: 'description',
    Cell: ({ value, row }) => {
      const transactedAt = row?.original?.transactedAt;
      const amount = row.original?.amount?.amount;
      const isDebit = isNumber(amount) && amount < 0;

      return (
        <div className='flex items-center'>
          <div className='h-9 w-9 rounded-full flex flex-row items-center justify-center border border-regent-400'>
            <FontAwesomeIcon
              icon={isDebit ? faArrowDownFromArc : faArrowUpFromArc}
              className='text-primary-dark text-base'
            />
          </div>

          <div className='ml-3'>
            <p className='font-inter text-sm text-primary-dark font-normal'>
              {value ?? 'N/A'}
            </p>
            <div className='flex items-center'>
              {transactedAt && (
                <p className='font-inter text-sm text-regent-600 font-light'>
                  <span>
                    {DateTime.fromMillis(transactedAt).toFormat('MMM dd')}
                  </span>
                </p>
              )}
              {row.original.status ===
                InternalLedgerEntryDtoStatusEnum.Pending && (
                <>
                  <div className='text-regent-600 text-sm font-medium mx-2'>
                    {'\u2022'}
                  </div>
                  <div className='text-yellow-600 text-sm font-medium'>
                    {capitalizeEnum(row.original.status ?? '')}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      );
    },
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ value }) => {
      return (
        <p className='font-inter text-sm font-medium'>
          {formatMoneyValue(value, 2) || 'N/A'}
        </p>
      );
    },
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Date',
    accessor: 'transactedAt',
    Cell: () => null,
    Filter: DateColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: () => null,
    Filter: WalletCreditPaymentSourceColumnFilter,
    disableSortBy: true,
  },
];

interface Params {
  creditAccountId: string;
}

interface WalletMyCreditRouteProps {}

const WalletMyCreditRoute: React.FC<WalletMyCreditRouteProps> = () => {
  const { creditAccountId } = useParams<Params>();
  const { key, refresh } = useRefresh();

  const {
    data: lendingAccounts,
    isLoading,
    errorCode,
  } = useFetchInternalAccounts();

  const account = useMemo(() => {
    return lendingAccounts?.internalAccounts?.find(
      (acc) => acc.type === InternalAccountDtoTypeEnum.RealLending,
    );
  }, [lendingAccounts?.internalAccounts]);

  const lastStatementId = account?.lastStatement?.id;

  const lastStatement = account?.lastStatement;

  const showDueDateNote =
    !isEmpty(lastStatement) &&
    lastStatement?.dueDate &&
    account?.currentAmountDue?.amount;

  const isPastDueDate = useMemo(() => {
    if (lastStatement?.dueDate) {
      return DateTime.fromISO(lastStatement.dueDate) < DateTime.now();
    }
    return false;
  }, [lastStatement?.dueDate]);

  const updatedColumns: Array<Column<InternalLedgerEntryDto>> = useMemo(
    () => [
      ...columns,
      ...(!!lastStatementId
        ? [
            {
              Header: 'Last Statement',
              accessor: 'id' as const,
              Cell: () => null,
              disableSortBy: true,
              Filter: WalletLastStatementToggleColumnFilter,
            },
          ]
        : []),
    ],
    [lastStatementId],
  );

  return (
    <ZenPageLayout
      path={[
        { title: 'Home', url: '/' },
        { title: 'Real Wallet', url: '/wallet' },
        { title: 'Credit', url: `/wallet/dashboard/credit/${creditAccountId}` },
      ]}
      textClassNames='font-inter'
    >
      <div className='py-4.5 px-10 h-full bg-regent-100'>
        {/* Header */}
        <p className='mb-6 font-medium text-lg text-primary-dark font-poppins'>
          My Credit
        </p>

        {/* Due Date Note */}

        <div className='grid grid-cols-6 gap-7'>
          {/* Left Column */}
          <div className='col-span-6 md:col-span-4'>
            {!!showDueDateNote && (
              <div
                className={cn(
                  'mb-6 flex items-center px-6 py-3 rounded-xl w-fit',
                  isPastDueDate ? 'bg-error' : 'bg-yellow-100',
                )}
              >
                <FontAwesomeIcon
                  icon={isPastDueDate ? faTriangleExclamation : faCircleInfo}
                  className={cn(
                    'text-lg mr-3',
                    isPastDueDate ? 'text-white' : 'text-primary-dark',
                  )}
                  title='info-icon'
                />
                <p
                  className={cn(
                    'font-inter font-medium text-base',
                    isPastDueDate ? 'text-white' : 'text-primary-dark',
                  )}
                >
                  <span className='mr-1' data-testId='due-date-notice'>
                    Warning, last payment {isPastDueDate ? 'was' : 'is'} due on{' '}
                    {displayDate(lastStatement?.dueDate!)}. Please ensure you
                    clear your dues to avoid Late Fees and other charges based
                    on our
                  </span>
                  <span
                    className={cn(
                      'cursor-pointer',
                      isPastDueDate
                        ? 'text-white font-bold underline'
                        : 'text-rezen-blue-600',
                    )}
                    onClick={() => {
                      window.open(CREDIT_TERMS);
                    }}
                  >
                    Credit Terms
                  </span>
                  .
                </p>
              </div>
            )}

            <ZenResourceContainer
              loading={isLoading}
              isEmpty={isEmpty(lendingAccounts) || !account?.id}
              resourceName='credit activity '
              errorCode={errorCode}
            >
              <div className='px-6 pt-6 rounded-xl shadow-pop-over bg-white'>
                <ZenResourceIndexContainer<InternalLedgerEntryDto>
                  key={key}
                  header='Credit Activity'
                  resourceName='activity'
                  columns={updatedColumns}
                  fetchData={async (req, cancelToken) => {
                    const lastStatementFIlter = req.filter?.id as IFilter;

                    const [
                      ,
                      dateOnOrBefore,
                      dateOnOrAfter,
                    ] = getFormattedFilterDates(
                      (req.filter?.transactedAt || []) as SearchParam[],
                    );

                    const response = await new InternalLedgerEntryApi(
                      getWalletConfiguration(),
                    ).searchMyInternalLedgerEntries(
                      req.page,
                      req.pageSize,
                      account?.id ?? '',
                      lastStatementFIlter?.value ? lastStatementId : undefined,
                      undefined,
                      undefined,
                      dateOnOrAfter,
                      dateOnOrBefore,
                      (req.filter?.type as SearchParam)
                        ?.value as InternalLedgerEntryDtoTypeEnum,
                      { cancelToken },
                    );

                    return {
                      data: response?.data?.ledgerEntries ?? [],
                      total: response?.data?.totalCount ?? 0,
                    };
                  }}
                  hiddenColumns={['transactedAt', 'type', 'id']}
                  stickyHeader
                  standalone={false}
                />
              </div>
            </ZenResourceContainer>
          </div>

          {/* Right Column */}
          <div className='col-span-6 md:col-span-2'>
            <WalletMyCreditDetailsCard onRefresh={refresh} />
          </div>
        </div>
      </div>
    </ZenPageLayout>
  );
};

export default WalletMyCreditRoute;
