import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { REPAYMENT_ADDRESS } from '../../../constants/WalletConstants';

interface ModalRepaymentModalProps {
  isOpen: boolean;
  onClose(): void;
}

const ModalRepaymentModal: React.FC<ModalRepaymentModalProps> = ({
  isOpen,
  onClose,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className='fixed inset-0 z-50 flex justify-center items-center overflow-y-auto'>
      <div
        className='absolute inset-0 z-0 bg-primary-dark bg-opacity-40'
        role='button'
        onClick={onClose}
      />
      <div className='relative flex flex-col w-[90%] max-w-xl bg-white rounded-lg overflow-y-auto max-h-[90vh]'>
        <div className='px-6 py-4 w-full flex justify-between items-center bg-rezen-blue-100'>
          <p className='font-inter font-medium text-base text-primary-dark'>
            Repayment
          </p>

          <FontAwesomeIcon
            icon={faCircleXmark}
            className='text-xl text-regent-400 cursor-pointer'
            onClick={onClose}
          />
        </div>

        <div className='bg-white p-6'>
          <p className='mb-1.5 font-inter text-base font-medium text-primary-dark'>
            Repayment by Physical Check
          </p>

          <p className='mb-6 font-inter text-base font-light text-primary-dark'>
            Please mail your check to the physical address.
          </p>

          <p className='mb-1.5 font-inter text-sm font-light text-regent-600'>
            Check made out to
          </p>

          <p className='mb-4 pb-3 font-inter text-base font-normal border-b border-regent-300'>
            REAL BROKER LTD.
          </p>

          {REPAYMENT_ADDRESS.map((location) => (
            <div key={location.region} className='mb-4'>
              <p className='mb-1.5 font-inter text-sm font-light text-regent-600'>
                {location.region}
              </p>

              <p className='font-inter text-base font-normal text-primary-dark'>
                {location.address}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ModalRepaymentModal;
